import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import "./signup.scss";
import classNames from "classnames";
import * as Yup from "yup";
import { useFormik } from "formik";
import { handlePostRequest } from "../../services/PostTemplate";
import { toast } from "react-toastify";

function Signup() {
    const [role, setRole] = useState();
    const [next, setNext] = useState(0);
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState("");
    const dispatch = useDispatch();

    let history = useHistory();

    const validationSchema = Yup.object().shape({
        email: Yup.string().required("This field is required."),
        password: Yup.string().required("This field is required."),
        firstName: Yup.string().required("This field is required."),
        lastName: Yup.string().required("This field is required."),
        phone: Yup.string().required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: "",
            password: "",
            firstName: "",
            lastName: "",
            phone: "",
            TRN: "",
            mailingAddress: "",
            deliveryAddress: "",
            businessName: "",
            TCC: "",
            businessType: "",
            dateJoined: "",
            role: "",
        },
        onSubmit: async (data) => {
            data.role = role;
            data.dateJoined = Date.now();
            const res = await dispatch(handlePostRequest(data, "/singup", true, true));
            if (res !== "error") {
                localStorage.setItem("email", data.email);
                toast.success(res?.data?.message);
                history.push("/login");
            }
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleSignup = () => {
        console.log(role);
        if (role === "client" || role === "courier") {
            setNext(1);
        } else {
            formik.handleSubmit();
        }
    };

    return (
        <div className="login_body">
            <div className="login_container">
                <div align="center" style={{ marginTop: "0%", marginBottom: "0%" }}>
                    <img src="https://res.cloudinary.com/dwxqg9so3/image/upload/v1692496436/WhatsApp_Image_2023-08-18_at_6.45.10_AM-removebg-preview_r6dz6x.png" style={{ objectFit: "contain", height: "150px", width: "150px" }}></img>
                </div>
                <div>
                    <p className="login_header">Hi! Welcome, please sign up below</p>
                    <form action="#" className="form_div" onSubmit={formik.handleSubmit}>
                        {next === 0 ? (
                            <div className="p-mt-4">
                                <input id="firstName" className={classNames({ "p-invalid": isFormFieldValid("firstName") }, "login_input")} name="firstName" value={formik.values.firstName} placeholder="Enter first Name " onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("firstName")}
                                <input id="lastName" className={classNames({ "p-invalid": isFormFieldValid("lastName") }, "login_input")} name="lastName" value={formik.values.lastName} placeholder="Enter last Name " onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("lastName")}
                                <input id="email" className={classNames({ "p-invalid": isFormFieldValid("email") }, "login_input")} name="email" value={formik.values.email} placeholder="Enter email" onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("email")}
                                <input className={classNames({ "p-invalid": isFormFieldValid("password") }, "login_input")} name="password" placeholder="Enter Password" value={formik.values.password} onChange={formik.handleChange} type="password" />
                                {getFormErrorMessage("password")}
                                <input className={classNames({ "p-invalid": isFormFieldValid("phone") }, "login_input")} name="phone" placeholder="Enter phone" value={formik.values.phone} onChange={formik.handleChange} type="phone" />
                                {getFormErrorMessage("phone")}
                                <select style={{ width: "100%" }} className="singp_select" value={role} onChange={(e) => setRole(e.target.value)}>
                                    <option selected disabled>
                                        Select Role
                                    </option>
                                    <option value="client">Client</option>
                                    <option value="courier">Courier</option>
                                </select>
                                <div className="p-mt-2">
                                    <Button className="login_button" label="Signup" icon={loadingIcon || ""} iconPos="right" type="button" disabled={loading} onClick={handleSignup} />
                                </div>
                            </div>
                        ) : role === "client" ? (
                            <div className="p-mt-4">
                                <input id="TRN" className={classNames({ "p-invalid": isFormFieldValid("TRN") }, "login_input")} name="TRN" value={formik.values.TRN} placeholder="Enter TRN " onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("TRN")}
                                <input id="mailingAddress" className={classNames({ "p-invalid": isFormFieldValid("mailingAddress") }, "login_input")} name="mailingAddress" value={formik.values.mailingAddress} placeholder="Enter Mailing Address" onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("mailingAddress")}
                                <input id="deliveryAddress" className={classNames({ "p-invalid": isFormFieldValid("deliveryAddress") }, "login_input")} name="deliveryAddress" value={formik.values.deliveryAddress} placeholder="Enter deliveryAddress" onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("deliveryAddress")}

                                <div className="p-mt-2">
                                    <Button className="back_button" label="Back" icon={loadingIcon || ""} style={{ marginRight: "20px" }} iconPos="right" type="button" disabled={loading} onClick={() => setNext(0)} />
                                    <Button className="login_button" style={{ marginRight: "10px" }} label="Signup" icon={loadingIcon || ""} iconPos="right" type="submit" disabled={loading} />
                                </div>
                            </div>
                        ) : (
                            <div className="p-mt-4">
                                <input id="TRN" className={classNames({ "p-invalid": isFormFieldValid("TRN") }, "login_input")} name="TRN" value={formik.values.TRN} placeholder="Enter TRN " onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("TRN")}
                                <input id="mailingAddress" className={classNames({ "p-invalid": isFormFieldValid("mailingAddress") }, "login_input")} name="mailingAddress" value={formik.values.mailingAddress} placeholder="Enter Mailing Address" onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("mailingAddress")}
                                <input id="deliveryAddress" className={classNames({ "p-invalid": isFormFieldValid("deliveryAddress") }, "login_input")} name="deliveryAddress" value={formik.values.deliveryAddress} placeholder="Enter deliveryAddress" onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("deliveryAddress")}
                                <input className={classNames({ "p-invalid": isFormFieldValid("TCC") }, "login_input")} name="TCC" placeholder="Enter TCC" value={formik.values.TCC} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("password")}
                                <input className={classNames({ "p-invalid": isFormFieldValid("businessName") }, "login_input")} name="businessName" placeholder="Enter businessName" value={formik.values.businessName} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("phone")}
                                <input className={classNames({ "p-invalid": isFormFieldValid("businessType") }, "login_input")} name="businessType" placeholder="Enter businessType" value={formik.values.businessType} onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("phone")}

                                <div className="p-mt-2">
                                    <Button className="back_button" label="Back" icon={loadingIcon || ""} style={{ marginRight: "20px" }} iconPos="right" type="button" disabled={loading} onClick={() => setNext(0)} />
                                    <Button className="login_button" style={{ marginRight: "10px" }} label="Signup" icon={loadingIcon || ""} iconPos="right" type="submit" disabled={loading} />
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Signup;
