import React, { useEffect } from "react";
import "./advance.scss";
import { useHistory } from "react-router-dom";
import Axios from "axios";

function Advance() {
    const history=useHistory();

    // const getData=async()=>{
    //     const res=Axios.get("https://zazio-g2flmvrpra-uc.a.run.app/api/courier/packages",{
    //         headers:{
    //             "app-key":"U2FsdGVkX19azpgJ85hJxYShZCvpigAp48iytavmMha+4fJLjhhxsr3Ve/Qh3Hqx"
    //         }
    //     });
    //     console.log(res);
    // }

    

    // useEffect(()=>{
    //    getData();
    // },[])
    return (
        <div className="Advance_main">
            <h1>Advance Features</h1>
            <div className="advance_inner">
                <div className="feature_block" onClick={()=>history.push("/logs")}>
                    <p>Logs</p>
                </div>
                <div className="feature_block" onClick={()=>history.push("/PendingPackages")}>
                    <p>Archived packages</p>
                </div>
                <div className="feature_block" onClick={()=>history.push("/DeliveryInfos")}>
                    <p>Delivery Information</p>
                </div>
                <div className="feature_block" onClick={()=>history.push("/statuses")}>
                    <p>Package Status</p>
                </div>
            </div>
        </div>
    );
}

export default Advance;
