import React, { useEffect, useRef } from "react";
import "./payment.css";
import { useState } from "react";
import { handleGetRequest } from "../../services/GetTemplate";
import { toast } from "react-toastify";
import Axios from "axios";
import { useDispatch } from "react-redux";
import { DEV } from "../../services/constants";
import { handlePutRequest } from "../../services/PutTemplate";
import { useParams, useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { PayPalScriptProvider, PayPalButtons, PayPalHostedFieldsProvider, PayPalHostedField, usePayPalHostedFields } from "@paypal/react-paypal-js";
import { handlePostRequest } from "../../services/PostTemplate";
import { loadScript } from "@paypal/paypal-js";
import AmazonPayButton from "../../components/AmazonPayButton";

function PaymentBulk() {
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [clientToken, setClientToken] = useState();
    const history = useHistory();
    // const elements = clover.elements();
    const [user, setUser] = useState();
    const [brands, setBrands] = useState();
    const [closed, setClosed] = useState(false);
    const [paidAmount, setPaidAmount] = useState(0);
    const [Amount, setAmount] = useState(0);

    const [ids, setIds] = useState();
    // const [orderId, setOrderId] = useState();
    const dispatch = useDispatch();
    const getClientToken = async (id) => {
        const res = await handleGetRequest("/GetPaymentToken");
        setClientToken(res?.data);
    };
    const getInvoice = async (id) => {
        const res = JSON.parse(localStorage.getItem("Zazioaggregated"));
        const pays = JSON.parse(localStorage.getItem("Zaziopaid"));
        setIds(pays);
        setBrands({
            ...res,
            invoiceAmount: res?.invoiceAmount?.toFixed(2),
            paidAmount: res?.paidAmount?.toFixed(2),
        });
        setAmount(res?.invoiceAmount);
        const reva = (res?.invoiceAmount).toFixed(2);
        setPaidAmount(parseFloat(reva));
        localStorage.setItem("chargeAmount", parseFloat(reva));
    };

    // const getOrderId = async () => {
    //     const res = await dispatch(handlePostRequest({ amount: 25 * 100 }, "/createOrder"));
    //     console.log(res?.data?.id);
    //     setOrderId(res?.data?.id);
    //     return res?.data?.id;
    // };
    useEffect(() => {
        const user = localStorage.getItem("PaymentUser");
        setUser(user);
        getInvoice();
        getClientToken();
        const expirationTime = localStorage.getItem("delayExpiration");
        if (expirationTime) {
            setClosed(true);
        }
    }, []);

    const checkDelayExpiration = () => {
        const expirationTime = localStorage.getItem("delayExpiration");
        if (expirationTime) {
            const currentTime = new Date().getTime();
            if (currentTime > parseInt(expirationTime)) {
                localStorage.removeItem("delayExpiration");
                setClosed(false);
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            checkDelayExpiration();
        }, 1000); // Check every second
        return () => clearInterval(interval);
    }, []);

    const CUSTOM_FIELD_STYLE = {
        border: "1px solid #cecece",
        height: "45px",
        borderRadius: "5px",
        marginTop: "5px",
    };
    const INVALID_COLOR = {
        color: "#dc3545",
    };
    const SubmitPayment = ({ customStyle }) => {
        const [paying, setPaying] = useState(false);
        const cardHolderName = useRef(null);
        const hostedField = usePayPalHostedFields();

        const handleClick = async () => {
            if (paidAmount === 0) {
                toast.error("Please enter valid amount to pay");
                setPaying(false);
            } else if (paidAmount > parseFloat(brands?.invoiceAmount)) {
                toast.error("Amount should be less than or equal to total amount");
                setPaying(false);
            } else {
                if (!hostedField?.cardFields) {
                    const childErrorMessage = "Unable to find any child components in the <PayPalHostedFieldsProvider />";
                    setPaying(false);

                    // action(ERROR)(childErrorMessage);
                    // throw new Error(childErrorMessage);
                }
                const isFormInvalid = Object.values(hostedField.cardFields.getState().fields).some((field) => !field.isValid) || !cardHolderName?.current?.value;

                if (isFormInvalid) {
                    toast.error("We appreciate your effort to make a payment. Please check your card information for errors, or try again in ten minutes.");
                    setPaying(false);
                }
                setPaying(true);
                hostedField.cardFields
                    .submit({
                        cardholderName: cardHolderName?.current?.value,
                    })
                    .then(async (data) => {
                        console.log(data?.orderId);
                        // Your logic to capture the transaction
                        const res = await dispatch(handlePostRequest({ orderId: data?.orderId }, "/captureOrder"));
                        if (res?.success) {
                            if(res?.data?.purchase_units?.[0]?.payments?.captures?.[0]?.status === "COMPLETED")
                            {
                                const dat = {
                                    invoiceNo: res?.data?.id,
                                    ids: ids,
                                    paymentMethod: "Card",
                                };
                                const resp = await handlePutRequest(dat, "/bulkPay");
                                if (resp?.success) {
                                    // toast.success("payment successful");
                                    const delayTime = new Date().getTime() + 1 * 60 * 1000; // 2 minutes in milliseconds
                                    localStorage.setItem("delayExpiration", delayTime);
                                    if (user === "user") {
                                        history.push(`/Userinvoices`);
                                    } else {
                                        history.push(`/invoices`);
                                    }
                                    localStorage.removeItem("PaymentUser");
                                    localStorage.removeItem("Zaziopaid");
                                    localStorage.removeItem("Zazioaggregated");
                                } else {
                                    setLoading(false);
                                    setDisabled(false);
                                }
                                toast.success("Success!!! Thank you for choosing Zazio. Have a wonderful rest of your day! #WeMoveFreightForward");
                                setPaying(false);
                            }
                            else{
                                toast.error("We appreciate your effort to make a payment. Please check your card information for errors, or try again in ten minutes.");
                                setPaying(false);
                            }
                           
                        } else {
                            toast.error("We appreciate your effort to make a payment. Please check your card information for errors, or try again in ten minutes.");
                            setPaying(false);
                        }
                    })
                    .catch((err) => {
                        // Here handle error
                        toast.error("We appreciate your effort to make a payment. Please check your card information for errors, or try again in ten minutes.");
                        setPaying(false);
                    });
            }
        };

        return (
            <>
                <div style={{ marginTop: "20px" }}>
                    <div style={{ display: "flex", flexDirection: "column", rowGap: "5px" }}>
                        <label title="This represents the full name as shown in the card">Card Holder Name</label>
                        <input id="card-holder" ref={cardHolderName} className="card-field" style={{ height: "45px", border: "1px solid #cecece", borderRadius: "5px" }} type="text" placeholder="Full name" />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        {paying ? (
                            <div>
                                <ClipLoader color="green" loading={paying} size={50} aria-label="Loading Spinner" data-testid="loader" />
                            </div>
                        ) : (
                            <button className={`btn${paying ? "" : " btn-primary"}`} style={{ padding: "10px 50px", cursor: "pointer", border: "none", borderRadius: "5px", backgroundColor: "green", color: "white" }} onClick={handleClick} disabled={closed === true ? true : false}>
                                {paying ? <div className="spinner tiny" /> : `${closed ? "Please wait 1 minute" : `Pay $${paidAmount}`}`}
                            </button>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const createOrder = async (data, actions) => {
        console.log(brands);
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        currency_code: "USD",
                        value: brands?.invoiceAmount,
                    },
                },
            ],
        });
    };

    const onApprove = async (data, actions) => {
        return actions.order
            .capture()
            .then(function (details) {
                console.log(details);
                const dat = {
                    invoiceNo: details?.id,
                    ids: ids,
                    paymentMethod: "Paypal",
                };
                handlePutRequest(dat, "/bulkPay").then((res) => {
                    if (res?.success) {
                        // toast.success("payment successful");
                        const delayTime = new Date().getTime() + 1 * 60 * 1000; // 2 minutes in milliseconds
                        localStorage.setItem("delayExpiration", delayTime);
                        if (user === "user") {
                            history.push(`/Userinvoices`);
                        } else {
                            history.push(`/invoices`);
                        }

                        localStorage.removeItem("PaymentUser");
                        localStorage.removeItem("Zaziopaid");
                        localStorage.removeItem("Zazioaggregated");
                    } else {
                        setLoading(false);
                        setDisabled(false);
                    }
                    toast.success("Success!!! Thank you for choosing Zazio. Have a wonderful rest of your day! #WeMoveFreightForward");
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error("We appreciate your effort to make a payment. Please check your card information for errors, or try again in ten minutes.");
            });
    };

    const handlePaymentSuccess = async (id, amount) => {
        const idss=JSON.parse(localStorage.getItem("Zaziopaid"));
        const buyer=localStorage.getItem("buyer");
        const dat = {
            invoiceNo: id,
            ids: idss,
            paymentOwner:buyer,
            paymentMethod: "AmazonPay",
        };
        const resp = await handlePutRequest(dat, "/bulkPay");
        if (resp?.success) {
            // toast.success("payment successful");
            const delayTime = new Date().getTime() + 1 * 60 * 1000; // 2 minutes in milliseconds
            localStorage.setItem("delayExpiration", delayTime);
            const user = localStorage.getItem("PaymentUser");
            if (user === "user") {
                history.push(`/Userinvoices`);
            } else {
                history.push(`/invoices`);
            }
            localStorage.removeItem("PaymentUser");
            localStorage.removeItem("Zaziopaid");
            localStorage.removeItem("Zazioaggregated");
            localStorage.removeItem("chargeAmount");
            localStorage.removeItem("sessionId");
        } else {
            setLoading(false);
            setDisabled(false);
        }
        toast.success("Success!!! Thank you for choosing Zazio. Have a wonderful rest of your day! #WeMoveFreightForward");
    }
    return (
        <>
            <div className="Page__Header">
                <div>
                    <h2>Pay invoice</h2>
                </div>
            </div>

            <div className="payment_top_main">
                <div className="payment_Summary">
                    <p className="payment_summary">Summary</p>
                    <div className="invoice_table">
                        <table className="inside_table">
                            <thead className="inside_head">
                                <tr>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Weight</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody className="inside_table_body">
                                {brands?.invoiceType !== "Monthly" &&
                                    brands?.packages?.map((item, index) => {
                                        let temp;
                                        let prev = brands?.packages[index - 1]?.product;
                                        if (brands?.customerType === "client") {
                                            if (item?.product === prev) {
                                                temp = "Additional Pounds";
                                            } else {
                                                temp = "1st Pound of Cargo";
                                            }
                                        } else {
                                            temp = "Total Pounds of Cargo";
                                        }
                                        return (
                                            <tr className="inside_row">
                                                <td>{temp}</td>
                                                <td>{item?.weight} lb</td>
                                                <td>${item?.price}</td>
                                                <td>${(item?.total).toFixed(2)}</td>
                                            </tr>
                                        );
                                    })}
                                {/* cost of goods */}
                                {brands?.invoiceType !== "Monthly" && brands?.itemsPrice !== 0 && (
                                    <tr className="inside_row">
                                        <td>Cost of Goods</td>
                                        <td></td>
                                        <td></td>
                                        <td>${brands?.itemsPrice}</td>
                                    </tr>
                                )}
                                {/* oversize package  */}
                                {brands?.invoiceType !== "Monthly" && brands?.oversizeFee !== 0 && (
                                    <tr className="inside_row">
                                        <td>Oversize Fee</td>
                                        <td></td>
                                        <td></td>
                                        <td>${brands?.oversizeFee}</td>
                                    </tr>
                                )}

                                {/* packaging Fee */}
                                {brands?.invoiceType !== "Monthly" && brands?.packagingFee !== 0 && (
                                    <tr className="inside_row">
                                        <td>Packaging</td>
                                        <td></td>
                                        <td></td>
                                        <td>${brands?.packagingFee}</td>
                                    </tr>
                                )}

                                {/* pickup fee */}
                                {brands?.invoiceType !== "Monthly" && brands?.pickupFee !== 0 && (
                                    <tr className="inside_row">
                                        <td>Pickup</td>
                                        <td></td>
                                        <td></td>
                                        <td>${brands?.pickupFee}</td>
                                    </tr>
                                )}

                                {/* delivery fee */}
                                {brands?.invoiceType !== "Monthly" && brands?.deliveryFee !== 0 && (
                                    <tr className="inside_row">
                                        <td>Delivery</td>
                                        <td></td>
                                        <td></td>
                                        <td>${brands?.deliveryFee}</td>
                                    </tr>
                                )}

                                {/* declaration fee */}
                                {brands?.invoiceType !== "Monthly" && brands?.declarationFee !== 0 && (
                                    <tr className="inside_row">
                                        <td>Declaration Fee</td>
                                        <td></td>
                                        <td></td>
                                        <td>${brands?.declarationFee}</td>
                                    </tr>
                                )}

                                {/* custom duties */}
                                {brands?.invoiceType !== "Monthly" && brands?.customDuties !== 0 && (
                                    <tr className="inside_row">
                                        <td>Custom Duties</td>
                                        <td></td>
                                        <td></td>
                                        <td>${brands?.customDuties}</td>
                                    </tr>
                                )}

                                {/* dangerous item fee */}
                                {brands?.dangerousItemFee !== 0 && brands?.invoiceType !== "Monthly" && (
                                    <tr className="inside_row">
                                        <td>Dangerous Item Fee</td>
                                        <td></td>
                                        <td></td>
                                        <td>$ {brands?.dangerousItemFee}</td>
                                    </tr>
                                )}

                                {/* loyality discount */}
                                {/* {handleLoyalityDiscount()} */}

                                {/* courier monthly */}
                                {brands?.invoiceType === "Monthly" && (
                                    <tr className="inside_row">
                                        <td>Monthly Fee</td>
                                        <td></td>
                                        <td></td>
                                        <td>${brands?.MonthlyFee}</td>
                                    </tr>
                                )}

                                {/* courier website usage */}
                                {brands?.invoiceType === "Monthly" && (
                                    <tr className="inside_row">
                                        <td>Website usage Fee</td>
                                        <td></td>
                                        <td></td>
                                        <td>${brands?.WebsiteFee}</td>
                                    </tr>
                                )}

                                {/* subTotal */}
                                <tr className="inside_row">
                                    <td>Sub-Total</td>
                                    <td></td>
                                    <td></td>
                                    <td>${brands?.customerType === "partner" || brands?.customerType === "admin" ? brands?.TotalAmount : brands?.invoiceAmount}</td>
                                </tr>
                                {brands?.customerType === "partner" ||
                                    (brands?.customerType === "admin" && (
                                        <tr className="inside_row">
                                            <td>{brands?.customerType === "partner" ? "Credit Discount" : "Admin Discount"}</td>
                                            <td></td>
                                            <td></td>
                                            <td>- ${brands?.discountedAmount}</td>
                                        </tr>
                                    ))}

                                {/* Tax */}
                                <tr className="inside_row">
                                    <td>Tax</td>
                                    <td></td>
                                    <td></td>
                                    <td>$0</td>
                                </tr>

                                {/* Total */}
                                {brands?.paymentStatus === "pending" ? (
                                    <tr className="inside_row">
                                        <td>Total</td>
                                        <td></td>
                                        <td></td>
                                        <td>${brands?.invoiceAmount}</td>
                                    </tr>
                                ) : null}
                                {brands?.paymentStatus === "partially paid" ? (
                                    <>
                                        <tr className="inside_row">
                                            <td>Paid Amount</td>
                                            <td></td>
                                            <td></td>
                                            <td>${brands?.paidAmount}</td>
                                        </tr>

                                        <tr className="inside_row">
                                            <td>Remaining Amount</td>
                                            <td></td>
                                            <td></td>
                                            <td>${brands?.invoiceAmount}</td>
                                        </tr>
                                    </>
                                ) : null}
                                {brands?.paymentStatus === "paid" ? (
                                    <tr className="inside_row">
                                        <td>Total</td>
                                        <td></td>
                                        <td></td>
                                        <td>${brands?.paidAmount}</td>
                                    </tr>
                                ) : null}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="payment_card_info">
                    {clientToken && (
                        <PayPalScriptProvider
                            options={{
                                clientId: "AWZZiuKkeE2dmpEwR9wgaSKe7kCxQo5IOahIvFmtHT4tPWwlk6-efWIGD94O7Vvejtl3KXLunwcJkZl-",
                                dataClientToken: clientToken,
                                components: "hosted-fields,buttons",
                                intent: "capture",
                                vault: false,
                                currency: "USD",
                                // deferLoading: true,
                            }}
                            onError={(err) => {
                                console.log(err);
                            }}
                        >
                            <PayPalHostedFieldsProvider
                                createOrder={async function () {
                                    const res = await dispatch(handlePostRequest({ amount: brands?.invoiceAmount }, "/createOrder"));
                                    return res?.data?.id;
                                }}
                                onError={(err) => {
                                    toast.error("We appreciate your effort to make a payment. Please check your card information or try again in ten minutes.");
                                    setLoading(false);
                                }}
                            >
                                <div style={{ paddingTop: "5px" }}>
                                    <label htmlFor="card-number">
                                        Card Number
                                        <span style={INVALID_COLOR}>*</span>
                                    </label>
                                </div>
                                <PayPalHostedField
                                    id="card-number"
                                    className="card__number"
                                    style={CUSTOM_FIELD_STYLE}
                                    hostedFieldType="number"
                                    options={{
                                        selector: "#card-number",
                                        placeholder: "4111 1111 1111 1111",
                                    }}
                                />
                                <div style={{ paddingTop: "20px" }}>
                                    <label htmlFor="cvv">
                                        CVV<span style={INVALID_COLOR}>*</span>
                                    </label>
                                </div>
                                <PayPalHostedField
                                    id="cvv"
                                    className="card-field"
                                    style={CUSTOM_FIELD_STYLE}
                                    hostedFieldType="cvv"
                                    options={{
                                        selector: "#cvv",
                                        placeholder: "123",
                                        maskInput: true,
                                    }}
                                />
                                <div style={{ paddingTop: "20px" }}>
                                    <label htmlFor="expiration-date">
                                        Expiration Date
                                        <span style={INVALID_COLOR}>*</span>
                                    </label>
                                </div>
                                <PayPalHostedField
                                    id="expiration-date"
                                    className="card-field"
                                    style={CUSTOM_FIELD_STYLE}
                                    hostedFieldType="expirationDate"
                                    options={{
                                        selector: "#expiration-date",
                                        placeholder: "MM/YYYY",
                                    }}
                                />
                                <SubmitPayment customStyle={{ border: "1px solid #606060", boxShadow: "2px 2px 10px 2px rgba(0,0,0,0.1)" }} />
                            </PayPalHostedFieldsProvider>
                            {/* <p>Card payments are offline for the moment.We are working on fixing the error. we are sorry for inconvenience.</p> */}
                            <div style={{ display: "flex", justifyContent: "center", padding: "30px 0px" }}>
                                <p style={{ fontSize: "18px" }}>------------Or Pay with-----------</p>
                            </div>
                            {brands && <PayPalButtons style={{ layout: "horizontal" }} createOrder={createOrder} onApprove={onApprove}></PayPalButtons>}
                        </PayPalScriptProvider>
                    )}
                    <AmazonPayButton handlePaymentSuccess={handlePaymentSuccess} amount={paidAmount}/>
                </div>
            </div>
        </>
    );
}

export default PaymentBulk;
