import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import moment from "moment/moment";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { handleGetRequest } from "../../services/GetTemplate";

const AddCustomerDialog = ({ onHideTyreBrandDialog, onsuccess }) => {
    const [role, setRole] = useState();
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedDelivery, setSelectedDelivery] = useState(null);
    const [DeliveryInfos, setDeliveryInfos] = useState([]);

    
    const getData=async()=>{
        const res = await handleGetRequest(`/getAllDeliveryInfo`);
        setDeliveryInfos(res?.data);
    }

    useEffect(()=>{
        getData();
    },[])

    const formik = useFormik({
        initialValues: {
            email: "",
            firstName: "",
            lastName: "",
            phone: "",
            TRN: "",
            mailingAddress: "",
            deliveryAddress: "",
            businessName: "",
            TCC: "",
            businessType: "",
            dateJoined: "",
            role: "",
            password: "",
            date: moment().format("DD-MM-YYYY"),
            verified: true,
            isPartner: false,
            deliveryInfo: "",
        },

        onSubmit: async (data) => {
            data.role = role;
            data.dateJoined = Date.now();
            if (!data?.firstName) {
                toast.error("Please enter first name");
            } else if (!data?.lastName) {
                toast.error("Please enter last name");
            } else if (!data?.email) {
                toast.error("Please enter email");
            } else if (!data?.password) {
                toast.error("Please enter password");
            } else if (!data?.phone) {
                toast.error("Please enter phone");
            } else if (!data?.role) {
                toast.error("Please select role");
            } else {
                if (data.role === "partner") {
                    data.role = "courier";
                    data.isPartner = true;
                }
                data.deliveryInfo = selectedDelivery;
                const result = await dispatch(handlePostRequest(data, "/singup", true, true));
                if (result !== "error") {
                    onsuccess();
                }
                console.log(data);
            }
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="firstName" className={classNames({ "p-error": isFormFieldValid("firstName") }, "Label__Text")}>
                                First Name
                            </label>
                            <InputText id="firstName" name="firstName" value={formik.values.firstName} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("firstName") }, "Input__Round")} />

                            {getFormErrorMessage("firstName")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="lastName" className={classNames({ "p-error": isFormFieldValid("lastName") }, "Label__Text")}>
                                Last Name
                            </label>
                            <InputText id="lastName" name="lastName" value={formik.values.lastName} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("lastName") }, "Input__Round")} />

                            {getFormErrorMessage("lastName")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="email" className={classNames({ "p-error": isFormFieldValid("email") }, "Label__Text")}>
                                Email Address
                            </label>
                            <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("email") }, "Input__Round")} />

                            {getFormErrorMessage("email")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="password" className={classNames({ "p-error": isFormFieldValid("password") }, "Label__Text")}>
                                Password
                            </label>
                            <InputText id="password" name="password" value={formik.values.password} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("password") }, "Input__Round")} />

                            {getFormErrorMessage("password")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="phone" className={classNames({ "p-error": isFormFieldValid("phone") }, "Label__Text")}>
                                Phone Number
                            </label>
                            <InputText id="phone" name="phone" value={formik.values.phone} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("phone") }, "Input__Round")} />

                            {getFormErrorMessage("phone")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ marginTop: "10px" }}>
                                <label htmlFor="role" className={classNames({ "p-error": isFormFieldValid("role") }, "Label__Text")}>
                                    User Type
                                </label>
                                <select style={{ width: "100%" }} className="singp_select" value={role} onChange={(e) => setRole(e.target.value)}>
                                    <option selected disabled>
                                        Select Role
                                    </option>
                                    <option value="client">Client</option>
                                    <option value="courier">Courier</option>
                                    <option value="partner">Partner</option>
                                    <option value="clerk">Clerk</option>
                                    <option value="admin">Admin</option>
                                    <option value="handler">Handler</option>
                                    <option value="airportStaff">Airport Staff</option>
                                    <option value="corporate">Corporate</option>
                                    <option value="reseller">Reseller</option>
                                </select>

                                {getFormErrorMessage("role")}
                            </div>
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ marginTop: "10px" }}>
                                <label htmlFor="phone" className={classNames({ "p-error": isFormFieldValid("phone") }, "Label__Text")}>
                                    Delivery Info
                                </label>
                                <select style={{ height: "40px", border: "1px solid #cecece", borderRadius: "7px" }} value={selectedDelivery} onChange={(e) => setSelectedDelivery(e.target.value)}>
                                    <option disabled selected>
                                        Please select delivery Information
                                    </option>
                                   {DeliveryInfos.map((item) => {
                                      return(
                                        <option value={item.name}>{item.name}</option>
                                      )
                                   })}
                                </select>
                                {getFormErrorMessage("phone")}
                            </div>
                        </div>
                    </div>

                    {role === "client" || role === "courier" || role === "corporate" || role === "reseller" ? (
                        <>
                            <div className="p-field col-12 md:col-12">
                                <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ marginTop: "5px" }}>
                                        <label htmlFor="mailingAddress" className={classNames({ "p-error": isFormFieldValid("mailingAddress") }, "Label__Text")}>
                                            MailingAddress
                                        </label>
                                        <InputText id="mailingAddress" name="mailingAddress" value={formik.values.mailingAddress} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("mailingAddress") }, "Input__Round")} />

                                        {getFormErrorMessage("mailingAddress")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-12">
                                <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ marginTop: "5px" }}>
                                        <label htmlFor="TRN" className={classNames({ "p-error": isFormFieldValid("TRN") }, "Label__Text")}>
                                            TRN
                                        </label>
                                        <InputText id="TRN" name="TRN" value={formik.values.TRN} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("TRN") }, "Input__Round")} />

                                        {getFormErrorMessage("TRN")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-12">
                                <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ marginTop: "5px" }}>
                                        <label htmlFor="deliveryAddress" className={classNames({ "p-error": isFormFieldValid("deliveryAddress") }, "Label__Text")}>
                                            Delivery Address
                                        </label>
                                        <InputText id="deliveryAddress" name="deliveryAddress" value={formik.values.deliveryAddress} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("deliveryAddress") }, "Input__Round")} />

                                        {getFormErrorMessage("deliveryAddress")}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                    {role === "courier" ? (
                        <>
                            <div className="p-field col-12 md:col-12">
                                <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ marginTop: "5px" }}>
                                        <label htmlFor="businessName" className={classNames({ "p-error": isFormFieldValid("businessName") }, "Label__Text")}>
                                            Business Name
                                        </label>
                                        <InputText id="businessName" name="businessName" value={formik.values.businessName} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("businessName") }, "Input__Round")} />

                                        {getFormErrorMessage("businessName")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-12">
                                <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ marginTop: "5px" }}>
                                        <label htmlFor="businessType" className={classNames({ "p-error": isFormFieldValid("businessType") }, "Label__Text")}>
                                            Business Type
                                        </label>
                                        <InputText id="businessType" name="businessType" value={formik.values.businessType} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("businessType") }, "Input__Round")} />

                                        {getFormErrorMessage("businessType")}
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-12">
                                <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ marginTop: "5px" }}>
                                        <label htmlFor="TCC" className={classNames({ "p-error": isFormFieldValid("TCC") }, "Label__Text")}>
                                            TCC
                                        </label>
                                        <InputText id="TCC" name="TCC" value={formik.values.TCC} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("TCC") }, "Input__Round")} />

                                        {getFormErrorMessage("TCC")}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
                <div className="Down__Btn">
                    <Button label="Create" className="Btn__Dark" />
                </div>
            </form>
        </>
    );
};

export default AddCustomerDialog;
