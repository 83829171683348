import React from 'react';
import './watermark.scss';

function WaterMark() {
  return (
    <div style={{width:"100%",height:'100%',position:"absolute",top:'0',left:"0",backgroundColor:"rgba(248, 246, 246, 0.5)",zIndex:"10",display:"flex",justifyContent:'center',alignItems:"center"}}>
        <h1 style={{fontSize:"70px",transform:"rotate(-45deg)",color:"gainsboro"}}>PAID IN FULL</h1>
    </div>
  )
}

export default WaterMark