// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAWh1gLjzWjsccsWJxyXc7SoTu1bWmR2dY",
    authDomain: "system-agency.firebaseapp.com",
    projectId: "system-agency",
    storageBucket: "system-agency.appspot.com",
    messagingSenderId: "473388855362",
    appId: "1:473388855362:web:a83f0ed7d5c42e410cfddb",
    measurementId: "G-Z4X2NF0MG6"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export default storage;
