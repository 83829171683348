import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { clientType, deliveryStatus, exportAirStatus, exportSeaStatus, importStatus, PackageTypes, status } from "../../assets/data/data";
import { useDispatch } from "react-redux";
import { handlePutRequest } from "../../services/PutTemplate";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";

function BulkFlightDialog({onHideChangeDialog,onsuccessChange, data }) {
    const [flightNumber, setFlightNumber] = useState(null);
    const [ids, setIds] = useState([]);

    const handleIds = () => {
        const temp = data?.map((item) => item.Waybill);
        setIds(temp);
    };

    useEffect(() => {
        handleIds();
    }, [data]);


    const handleSubmit=async(e)=>{
        e.preventDefault()
      const data={
        id:ids,
        flight:flightNumber
      }
      const dat=await handlePutRequest(data,"/bulkflight")
      if(dat?.success)
      {
        onsuccessChange()
        toast.success("Flight Number Updated Successfully")
      }
    }
    return (
        <>
            <form className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-12" style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginTop: "10px", display: "flex", flexDirection: "column" }}>
                        <label htmlFor="status" className="Label__Text">
                            New Flight Number
                        </label>
                        <InputText id="numberOfPieces" name="numberOfPieces" value={flightNumber} onChange={(e) => setFlightNumber(e.target.value)} className={classNames("Input__Round")} />
                    </div>
                </div>
                <div className="Down__Btn">
                    <Button label="Create" className="Btn__Dark" onClick={handleSubmit}/>
                </div>
            </form>
        </>
    );
}

export default BulkFlightDialog;
