import React, { useEffect, useRef } from "react";
import "./payment.css";

function PaymentModel() {
    const clover = new window.Clover("538a0fe96c68760103c727eda11bff8a");
    const elements = clover.elements();
    const styles = {
        "card-number input": {
            "background-color": "white",
        },
        "card-date input": {
            "background-color": "white",
        },
        "card-cvv input": {
            "background-color": "white",
        },
        "card-postal-code input": {
            "background-color": "white",
        },
    };
    const form = document.getElementById("payment-form");
    useEffect(() => {
        const cardNumber = elements.create("CARD_NUMBER", styles);
        const cardDate = elements.create("CARD_DATE", styles);
        const cardCvv = elements.create("CARD_CVV", styles);
        const cardPostalCode = elements.create("CARD_POSTAL_CODE", styles);

        cardNumber.mount("#card-number");
        cardDate.mount("#card-date");
        cardCvv.mount("#card-cvv");
        cardPostalCode.mount("#card-postal-code");
    }, []);

    if (form) {
        form.addEventListener("submit", function (event) {
            event.preventDefault();
            // Use the iframe's tokenization method with the user-entered card details
            clover.createToken().then(function (result) {
                if (result.errors) {
                } else {
                    console.log(`cloverTokenHandler ${JSON.stringify(result)}`);
                }
            });
        });
    }
    return (
        <>
            <p>Powered By Clover</p>
            <form action="/charge" method="post" id="payment-form">
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }} className="chupaa">
                    <div style={{ width: "100%", height: "100%" }}>
                        <div class="form-row_payment">
                            <div id="card-number" className="field_payment card-number"></div>
                            <div class="input-errors" id="card-number-errors" role="alert"></div>
                        </div>

                        <div class="form-row_payment">
                            <div id="card-date" className="field_payment card-date"></div>
                            <div class="input-errors" id="card-date-errors" role="alert"></div>
                        </div>

                        <div class="form-row_payment">
                            <div id="card-cvv" className="field_payment card-cvv"></div>
                            <div class="input-errors" id="card-cvv-errors" role="alert"></div>
                        </div>

                        <div class="form-row_payment">
                            <div id="card-postal-code" className="field_payment card-postal-code"></div>
                            <div class="input-errors" id="card-postal-code-errors" role="alert"></div>
                        </div>
                        <div class="button-container_payment">
                            <button className="button_payment">Submit Payment</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}

export default PaymentModel;
