import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import moment from "moment/moment";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { handleGetRequest } from "../../services/GetTemplate";

function AddFlightDialog({ onHideTyreBrandDialog, onsuccess }) {
    const dispatch = useDispatch();
    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            flightNumber: "",
        },

        onSubmit: async (data) => {
            const result = await dispatch(handlePostRequest(data, "/addFlight", true, true));
            if (result !== "error") {
                onsuccess();
            }
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="flightNumber" className={classNames({ "p-error": isFormFieldValid("flightNumber") }, "Label__Text")}>
                               Flight Number
                            </label>
                            <InputText id="flightNumber" name="flightNumber" value={formik.values.flightNumber} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("flightNumber") }, "Input__Round")} />

                            {getFormErrorMessage("flightNumber")}
                        </div>
                    </div>
                    
                </div>
                <div className="Down__Btn">
                    <Button label="Create" className="Btn__Dark" />
                </div>
            </form>
        </>
    );
}

export default AddFlightDialog;
