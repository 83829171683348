import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { handleGetRequest } from "../services/GetTemplate";
import { useHistory } from "react-router-dom";
import { CiTrophy } from "react-icons/ci";

const Dashboard = () => {
    const history = useHistory();
    const [stats, setStats] = useState({
        users: 0,
        couriers: 0,
        clients: 0,
        packages: 0,
        labels: 0,
        Top: 0,
        Second: 0,
        date: "",
    });
    const [dataset, setDataSet] = useState([]);

    const getData = async () => {
        // const users = await handleGetRequest("/allUsers");
        // const orders = await handleGetRequest("/userRole?role=client");
        // const tyre = await handleGetRequest("/userRole?role=courier");
        // const brands = await handleGetRequest("/allLabels");
        // const vehicles = await handleGetRequest("/allPackages");
        // const sts = await handleGetRequest("/userStats");
        const data = await handleGetRequest("/allStats");
        setDataSet(data?.data?.resul);
        setStats({
            users: data?.data?.users,
            packages: data?.data?.packages,
            clients: data?.data?.clients,
            couriers: data?.data?.couriers,
            labels: data?.data?.labels,
            Top: data?.data?.Top,
            Second: data?.data?.Second,
            date: data?.data?.date,
            // login: login?.data,
        });
        setBasicData({
            labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            datasets: [
                {
                    label: "packages",
                    data: data?.data?.resul,
                    fill: false,
                    borderColor: "#42A5F5",
                    tension: 0.4,
                },
            ],
        });
    };

    useEffect(() => {
        getData();
    }, []);

    console.log(dataset);
    const [basicData, setBasicData] = useState({
        labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        datasets: [
            {
                label: "packages",
                data: dataset,
                fill: false,
                borderColor: "#42A5F5",
                tension: 0.4,
            },
        ],
    });

    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        let multiAxisOptions = {
            stacked: false,
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        return {
            basicOptions,
            multiAxisOptions,
        };
    };

    var currentDate = new Date();

    // Get the current year
    var currentYear = currentDate.getFullYear();

    // Get the current month (0-indexed, i.e., January is 0, February is 1, etc.)
    var currentMonth = currentDate.getMonth() + 1; // Adding 1 to get the month in the range of 1-12

    // Get the current date (day of the month)
    var currentDay = currentDate.getDate();

    const { basicOptions, multiAxisOptions } = getLightTheme();
    return (
        <>
            <div className="grid_view">
                <div className="card1" onClick={() => history.push("/customers")} style={{ cursor: "pointer" }}>
                    <i className="pi pi-user home_icon" />
                    <p>users</p>
                    <p>{stats?.users ?? 0}</p>
                </div>
                <div className="card2" onClick={() => history.push("/customers?type=courier")} style={{ cursor: "pointer" }}>
                    <i className="pi pi-history home_icon" />
                    <p>Couriers</p>
                    <p>{stats?.couriers ?? 0}</p>
                </div>
                <div className="card3" onClick={() => history.push("/customers?type=client")} style={{ cursor: "pointer" }}>
                    <i className="pi pi-circle home_icon" />
                    <p>Clients</p>
                    <p>{stats?.clients ?? 0}</p>
                </div>
                <div className="card4" onClick={() => history.push("/packages")} style={{ cursor: "pointer" }}>
                    <i className="pi pi-circle home_icon" />
                    <p>Packages</p>
                    <p>{stats?.packages ?? 0}</p>
                </div>
                <div className="card5" onClick={() => history.push("/labels")} style={{ cursor: "pointer" }}>
                    <i className="pi pi-car home_icon" />
                    <p>Labels</p>
                    <p>{stats?.labels ?? 0}</p>
                </div>
                <div className="card5"  style={{ cursor: "pointer" }}>
                    <i className="home_icon" >
                    <CiTrophy />
                    </i>
                    <p>{stats?.Top?.[0]}</p>
                    <p>{stats?.Top?.[1] ?? 0} Lbs</p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <p style={{fontSize:"18px",color:"green"}}>{currentDay + "-" + currentMonth + "-" + currentYear}</p>
                    </div>
                </div>
                <div className="card5"  style={{ cursor: "pointer" }}>
                    <i className="home_icon" >
                    <CiTrophy />
                    </i>
                    <p style={{fontSize:"14px"}}>{stats?.Second?.[0]}</p>
                    <p>{stats?.Second?.[1] ?? 0} Lbs</p>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <p style={{fontSize:"18px",color:"green"}}>{currentDay + "-" + currentMonth + "-" + currentYear}</p>
                    </div>
                </div>
            </div>
            <div className="graphs_section">
                <div className="left_">
                    <h5>Monthly packages added</h5>
                    <Chart type="line" data={basicData} options={basicOptions} />
                </div>
            </div>
        </>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);
