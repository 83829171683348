import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { clientType, deliveryStatus, exportAirStatus, exportSeaStatus, importStatus, PackageTypes, status, statusArray } from "../../assets/data/data";
import { useDispatch } from "react-redux";
import { handlePutRequest } from "../../services/PutTemplate";
import { toast } from "react-toastify";
import { handleGetRequest } from "../../services/GetTemplate";

function ChangeStatusDialog({ onHideChangeDialog, onsuccessChange, data, id }) {
    console.log(data)
    const [selectedImportStatus, setSelectedImportStatus] = useState();
    const [selectedPackType, setSelectedPAckType] = useState();
    const [selectedExportSeaStatus, setSelectedExportSeaStatus] = useState();
    const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState();
    const [selectedExportAirStatus, setSelectedExportAirStatus] = useState();
    const [selectedShipment, setSlectedShipment] = useState();
    const [shipmentNumber, setShipmentNumber] = useState();
    const [status, setStatus] = useState([]);
    const [role, setrole] = useState("");
    const [packageStatus, setPackageStatus] = useState([]);

    const dispatch = useDispatch();

    const getData=async()=>{
        const resa = await handleGetRequest(`/getAllPackageStatus`);
        setPackageStatus(resa?.data);
    }


    useEffect(() => {
        const role = localStorage.getItem("role");
        setrole(role);
        getData();
    }, []);


    const handledeliveryStatus = async (e) => {
        setSelectedImportStatus(e);
        const dat = {
            status: e,
            package: data,
            ids: id,
        };
         onHideChangeDialog();
        const res = await handlePutRequest(dat, "/packageStatusChangeBulk");
       
        if (res?.success) {
            toast.success("Package edited");
            onsuccessChange();
        }
    };

   

    return (
        <>
            <form className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-12" style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginTop: "10px", display: "flex", flexDirection: "column" }}>
                        <label htmlFor="status" className="Label__Text">
                            Package Status
                        </label>
                        <select disabled={role === "client" ? true : false} style={{ marginTop: "10px", height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} value={selectedDeliveryStatus} onChange={(e) => handledeliveryStatus(e.target.value)}>
                            <option disabled selected>
                                Please select a status
                            </option>
                            {packageStatus?.map((client) => {
                                return <option value={client?.name}>{client?.name}</option>;
                            })}
                        </select>
                    </div>
                </div>
                <div className="Down__Btn">
                    <Button label="Create" className="Btn__Dark" />
                </div>
            </form>
        </>
    );
}

export default ChangeStatusDialog;
