import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import moment from "moment/moment";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

function AddPackageStatusDialog({ onHideTyreBrandDialog, onsuccess }) {
    const [role, setRole] = useState();
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedDelivery, setSelectedDelivery] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: "",
        },

        onSubmit: async (data) => {
            if (!data?.name) {
                toast.error("Please enter title");
            } else {
                const result = await dispatch(handlePostRequest(data, "/addPackageStatus", true, true));
                if (result !== "error") {
                    onsuccess();
                }
                console.log(data);
            }
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="name" className={classNames({ "p-error": isFormFieldValid("name") }, "Label__Text")}>
                                Name
                            </label>
                            <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__Round")} />

                            {getFormErrorMessage("name")}
                        </div>
                    </div>
                </div>
                <div className="Down__Btn">
                    <Button label="Create" className="Btn__Dark" />
                </div>
            </form>
        </>
    );
}

export default AddPackageStatusDialog;
