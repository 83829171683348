import React, { useEffect, useMemo, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import Axios from "axios";
import { DEV } from "../../services/constants";
import "./deliveries.scss";
import { Tooltip } from "react-tippy";
import { toast } from "react-toastify";
import { handlePutRequest } from "../../services/PutTemplate";
import SofStop from "../../modals/SofStop";
import { handlePostRequest } from "../../services/PostTemplate";
import { Dialog } from "primereact/dialog";
import { useDebounce } from "../../custom hooks/Debounce";

function BillingReports() {
    const signref = useRef();
    const [selectedRow, setselectedRow] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [brands, setBrands] = useState([]);
    const [skip, setSkip] = useState(0);
    const dispatch = useDispatch();
    const [showSoftStop, setShowSoftStop] = useState(false);
    const [loading, setLoading] = useState(false);

    const breadItems = [{ label: "Home", url: "/" }, { label: "Invoices" }];

    const home = { icon: "pi pi-home", url: "/" };
    const history = useHistory();
    const { status } = useParams();

    const getBrands = async () => {
        setLoading(true);
        if (status === "pending") {
            const result = await Axios.get(DEV + "/searchInvoice", {
                params: {
                    reviewStatus: "pending",
                    paymentStatus: "pending",
                },
            });
            setBrands(result?.data?.data);
        } else if (status === "unpaid") {
            const result = await Axios.get(DEV + "/searchInvoice", {
                params: {
                    reviewStatus: "reviewed",
                    paymentStatus: "pending",
                },
            });
            setBrands(result?.data?.data);
        } else if (status === "paid") {
            const result = await Axios.get(DEV + "/searchPaidInvoice", {
                params: {
                    reviewStatus: "reviewed",
                    paymentStatus: "paid",
                },
            });
            setBrands(result?.data?.data);
        }
        setLoading(false);
    };
    useMemo(() => {
        getBrands();
    }, [skip, status]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-ellipsis-v" className="p-button-rounded mr-2 Elipse_Icon" onClick={(e) => handleActionButton(e, rowData)} aria-controls="popup_menu" aria-haspopup />
            </div>
        );
    };
    const handleActionButton = (e, rowdata) => {
        e.preventDefault();
        history.push(`/invoice?id=${rowdata._id}`);
    };

    const [values, setValues] = useState({
        invoiceNo: "",
        clientName: "",
        customerType: "",
        weight: "",
        invoiceAmount: "",
        shipmentNumber: "",
        reviewStatus: "",
        paymentStatus: "",
        date: "",
    });

    const [searchedTerm, setSearchedTerm] = useState({
        name: "",
        value: "",
    });
    const debouncedValue= useDebounce(searchedTerm, 500);

    const handleApplyFilter = async (value, names) => {
        const temp = values;
        temp[names] = value;
        setValues({ ...values, [names]: value });
        if (status === "paid") {
            const result = await Axios.get(DEV + "/searchPaidInvoice", {
                params: {
                    ...temp,
                    reviewStatus: "reviewed",
                    paymentStatus: "paid",
                },
            });
            setBrands(result?.data?.data);
        } else if (status === "unpaid") {
            const result = await Axios.get(DEV + "/searchInvoice", {
                params: {
                    ...temp,
                    reviewStatus: "reviewed",
                    paymentStatus: "pending",
                },
            });
            setBrands(result?.data?.data);
        } else if (status === "pending") {
            const result = await Axios.get(DEV + "/searchInvoice", {
                params: {
                    ...temp,
                    reviewStatus: "pending",
                    paymentStatus: "pending",
                },
            });
            setBrands(result?.data?.data);
        }
        setLoading(false);
    };

    useEffect(() => {
        handleApplyFilter(debouncedValue?.value, debouncedValue?.name);
    }, [debouncedValue]);

    const handleFilter = (name) => {
        return <input style={{ width: "100%", height: "37px", borderRadius: "5px", border: "none" }}  onChange={(e) => {
            setSearchedTerm({
                name: name,
                value: e.target.value,
            })
            setLoading(true);
            setBrands([]);
        }}></input>;
    };

    const handleMarkedReviewed = async () => {
        if (selectedRow.length === 0) {
            toast.error("Please select atleast one invoice to mark as reviewed");
        } else {
            const ids = selectedRow.map((item) => item._id);
            const data = {
                id: ids,
                reviewStatus: "reviewed",
            };
            const res = await handlePutRequest(data, "/changeReviewStatusBulk");
            if (res?.success) {
                toast.success("Invoice marked as reviewed successfully");
                window.location.reload();
            }
        }
    };

    const handleBulkInvoices = async () => {
        if (selectedRow.length === 0) {
            toast.error("Please select atleast one invoice to download");
        } else {
            const ids = selectedRow;
            localStorage.setItem("bulkInvoices", JSON.stringify(ids));
            history.push("/bulkInvoices");
        }
    };

    const onHideSoftStop = () => {
        setShowSoftStop(false);
    };

    const onSuccessSoftStop = async () => {
        setShowSoftStop(false);
        if (selectedRow?.length > 0) {
            const selectedIds = selectedRow.map((val) => {
                return val?._id;
            });
            const data = {
                id: selectedIds,
            };
            const res = await dispatch(handlePostRequest(data, "/deleteInvoices", true, true));
            if (res?.success) {
                getBrands();
                toast.success("Invoice deleted");
                // window.location.reload();
            }
        } else {
            toast.error("Please select atleast one invoice to delete");
        }
    };

    const handledDelete = async () => {
        setShowSoftStop(true);
    };

    const TotalAmountTemplate = (rowData) => {
        return <>{rowData?.invoiceAmount?.toFixed(2)}</>;
    };

    function capitalizeFirstWord(str) {
        // Convert the string to lowercase to handle mixed-case input
        str = str.toLowerCase();

        // Split the string into words
        let words = str.split(" ");

        // Capitalize the first word if there are any words
        if (words.length > 0) {
            words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
        }

        // Join the words back into a string
        return words.join(" ");
    }

    const handleReturnButtons = () => {
        if (status === "pending") {
            return (
                <div style={{ display: "flex", columnGap: "20px" }}>
                    <button style={{ border: "none", backgroundColor: "blue", borderRadius: "6px", color: "white", padding: "15px 20px", cursor: "pointer" }} onClick={() => history.push("/invoice/unpaid")}>
                        Unpaid Invoices
                    </button>
                    <button style={{ border: "none", backgroundColor: "green", borderRadius: "6px", color: "white", padding: "15px 20px", cursor: "pointer" }}  onClick={() => history.push("/invoice/paid")}>Paid Invoices</button>
                </div>
            );
        } else if (status === "unpaid") {
            return (
                <div style={{ display: "flex", columnGap: "20px" }}>
                    <button style={{ border: "none", backgroundColor: "red", borderRadius: "6px", color: "white", padding: "15px 20px", cursor: "pointer" }}  onClick={() => history.push("/invoice/pending")}>Pending Invoices</button>
                    <button style={{ border: "none", backgroundColor: "green", borderRadius: "6px", color: "white", padding: "15px 20px", cursor: "pointer" }}  onClick={() => history.push("/invoice/paid")}>Paid Invoices</button>
                </div>
            );
        } else if (status === "paid") {
            return (
                <div style={{ display: "flex", columnGap: "20px" }}>
                    <button style={{ border: "none", backgroundColor: "red", borderRadius: "6px", color: "white", padding: "15px 20px", cursor: "pointer" }}  onClick={() => history.push("/invoice/pending")}>Pending Invoices</button>
                    <button style={{ border: "none", backgroundColor: "blue", borderRadius: "6px", color: "white", padding: "15px 20px", cursor: "pointer" }}  onClick={() => history.push("/invoice/unpaid")}>Unpaid Invoices</button>
                </div>
            );
        }
    };


    const handleResetInvoice = async () => {
       const data= selectedRow.map((item) => {
              return {
                _id: item._id,
                paidAmount: item?.paidAmount,
              };
         });
        const res = await handlePutRequest(data, "/resetInvoice");
        if (res?.success) {
            toast.success("Invoice reset successfully");
            getBrands();
        }
    }

    return (
        <>
            <Dialog visible={showSoftStop} style={{ width: "650px" }} onHide={() => setShowSoftStop(false)}>
                <SofStop onHideSoftStop={onHideSoftStop} onSuccessSoftStop={onSuccessSoftStop} type="Invoice"></SofStop>
            </Dialog>
            <div className="Page__Header">
                <div>
                    <h2>{capitalizeFirstWord(status)} Invoices</h2>
                </div>
                <div className="Top__Btn">
                    {status==="paid" && <Tooltip
                        // options
                        title="Reset selected invoices"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button
                            icon="pi pi-refresh"
                            iconPos="right"
                            onClick={() => {
                                if (selectedRow.length > 0) {
                                    handleResetInvoice();
                                } else {
                                    toast.error("Please select atleast one invoice to reset");
                                }
                            }}
                            className="Btn__Blue"
                        />
                    </Tooltip>}
                    <Tooltip
                        // options
                        title="Generate Invoice Report"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button
                            icon="pi pi-plus"
                            iconPos="right"
                            onClick={() => {
                                if (status === "pending") {
                                    history.push("/generateInvoice");
                                } else if (status === "unpaid") {
                                    history.push("/report/unpaid");
                                } else if (status === "paid") {
                                    history.push("/report/paid");
                                }
                            }}
                            className="Btn__Blue"
                        />
                    </Tooltip>
                    <Tooltip
                        // options
                        title="Mark invoices as reviewed"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-search-plus" iconPos="right" onClick={handleMarkedReviewed} className="Btn__Blue" />
                    </Tooltip>
                    <Tooltip
                        // options
                        title="Download selected Invoices"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi pi-file-pdf" iconPos="right" onClick={handleBulkInvoices} className="Btn__Blue" />
                    </Tooltip>
                    <Tooltip
                        // options
                        title="Delete selected Invoices"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-trash" iconPos="right" onClick={handledDelete} className="Btn__DarkDelete" />
                    </Tooltip>
                </div>
            </div>
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>{handleReturnButtons()}</div>
            <div className="card">
                <DataTable
                    filterDisplay="row"
                    paginator
                    rows={100}
                    rowsPerPageOptions={[100, 500, 1000]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                    emptyMessage="No List found."
                    responsiveLayout="scroll"
                    value={brands}
                    selection={selectedRow}
                    onSelectionChange={(e) => setselectedRow(e.value)}
                    loadingIcon="pi pi-spinner"
                    loading={loading}
                >
                    <Column selectionMode="multiple" style={{ width: "3em" }} />
                    <Column header="Action" body={actionBodyTemplate} />
                    <Column filter header="Invoice ID" field="invoiceId"  sortable/>
                    {status === "paid" && <Column filter field="invoiceNo" header="Payment ID" filterElement={() => handleFilter("invoiceNo")} />}
                    <Column filter field="clientName" header="Client Name" filterElement={() => handleFilter("clientName")} sortable/>
                    <Column filter header="client Type" field="customerType" filterElement={() => handleFilter("customerType")} />
                    <Column filter header="Total Weight" field="weight" filterElement={() => handleFilter("weight")} />
                    <Column filter header="Remaining Amount" body={TotalAmountTemplate} filterElement={() => handleFilter("invoiceAmount")} />
                    <Column filter header="Paid Amount" field="paidAmount" filterElement={() => handleFilter("paidAmount")} />
                    <Column filter header="Contents" field="descriptions" filterElement={() => handleFilter("descriptions")} />
                    <Column filter header="Invoice Type" field="invoiceType" filterElement={() => handleFilter("invoiceType")} />
                    <Column filter header="Shipment Number" field="shipmentNumber" filterElement={() => handleFilter("shipmentNumber")} sortable />
                    {/* <Column filter header="Review Status" field="reviewStatus" filterElement={() => handleFilter("reviewStatus")} />
                    <Column filter header="Payment Status" field="paymentStatus" filterElement={() => handleFilter("paymentStatus")} /> */}
                    <Column filter header="Issue Date" field="date" filterElement={() => handleFilter("date")} sortable/>
                   
                    {status === "paid" && <Column filter  header="Payment Date" field="datePaid" sortable/>}
                </DataTable>
            </div>
        </>
    );
}

export default BillingReports;
