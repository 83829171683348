import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import "./reset.scss";
import classNames from "classnames";
import * as Yup from "yup";
import { useFormik } from "formik";
import { handlePostRequest } from "../services/PostTemplate";
import { ToastContainer, toast } from "react-toastify";

function Login() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState("");
    const dispatch = useDispatch();

    let history = useHistory();

    const validationSchema = Yup.object().shape({
        email: Yup.string().required("This field is required."),
        password: Yup.string().required("This field is required."),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: async (data) => {
            const res = await dispatch(handlePostRequest(data, "/resetpassword", true, true));
            const userData = res?.data;
            if (res !== "error") {
                toast.success("Password reset successfully.");
                history.push("/login");
            }
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <ToastContainer />
            <div className="login_body">
                <div className="login_container">
                    <div align="center" style={{ marginTop: "0%", marginBottom: "0%" }}>
                        <img src="https://res.cloudinary.com/dwxqg9so3/image/upload/v1692496436/WhatsApp_Image_2023-08-18_at_6.45.10_AM-removebg-preview_r6dz6x.png" style={{ objectFit: "contain", height: "150px", width: "150px" }}
                        ></img>
                    </div>
                    <div>
                        <p className="login_header">Hi! Welcome, please reset your password below</p>
                        <form action="#" className="form_div" onSubmit={formik.handleSubmit}>
                            
                            <div className="p-mt-4">
                                <input id="email" className={classNames({ "p-invalid": isFormFieldValid("email") }, "login_input")} name="email" value={formik.values.email} placeholder="Enter email" onChange={formik.handleChange} type="text" />
                                {getFormErrorMessage("email")}

                                <input className={classNames({ "p-invalid": isFormFieldValid("password") }, "login_input")} name="password" placeholder="Enter Password" value={formik.values.password} onChange={formik.handleChange} type="password" />
                                {getFormErrorMessage("password")}

                                <input className={classNames({ "p-invalid": isFormFieldValid("password") }, "login_input")} name="password" placeholder="Re-Enter Password" onChange={formik.handleChange} type="password" />

                                <div className="p-mt-2">
                                    <Button className="login_button" label="Reset Password" icon={loadingIcon || ""} iconPos="right" disabled={loading} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
