import React, { useEffect, useMemo, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { toast } from "react-toastify";
import Axios from "axios";
import { DEV } from "../../services/constants";
import AddCustomerDialog from "../customers/AddCustomerDialog";
import { Dialog } from "primereact/dialog";
import { useLocation } from "react-router-dom";
import SofStop from "../../modals/SofStop";
import { Tooltip } from "react-tippy";
import ClipLoader from "react-spinners/ClipLoader";
import "./customers.scss";
import AddFlightDialog from "./AddFlightDialog";
import moment from "moment";

function Flights() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get("type");
    const [selectedRow, setselectedRow] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [brands, setBrands] = useState([]);
    const [skip, setSkip] = useState(0);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const menu = useRef(null);
    const breadItems = [{ label: "Home", url: "/" }, { label: "Customers" }];

    const home = { icon: "pi pi-home", url: "/" };
    const history = useHistory();

    const handledClicked = () => {
        history.push("");
    };

    const getBrands = async () => {
        setLoading(true);
        const res = await handleGetRequest("/allFlights");
        setBrands(res?.data);
        setLoading(false);
        
    };
    useMemo(() => {
        getBrands();
    }, [skip]);

    console.log(brands);

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-ellipsis-v" className="p-button-rounded mr-2 Elipse_Icon" onClick={(e) => handleActionButton(e, rowData)} aria-controls="popup_menu" aria-haspopup />
            </div>
        );
    };
    const handleActionButton = (e, rowdata) => {
        e.preventDefault();
        history.push(`flight?id=${rowdata?._id}`);
    };

    const onHideTyreBrandsDialog = () => {
        setShowDialog(false);
    };
    const handledDelete = async () => {
        setShowSoftStop(true);
    };

    const handleskip = (num) => {
        setSkip(num);
    };
    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        phone: "",
        date: "",
    });

    const temporary = ["firstName", "lastName", "email", "phone", "role", "date"];

    const handleApplyFilter = async (value, names) => {
        setLoading(true);
        const temp = values;
        // temporary.forEach((item) => {
        //     if (item !== names) {
        //         temp[item] = "";
        //     }
        // });
        // setValues(temp);
        temp[names] = value;
        setValues({ ...values, [names]: value });
        const result = await Axios.get(DEV + "/searchUsers", {
            params: {
                ...temp,
            },
        });
        setBrands(result?.data?.data);
        setLoading(false);
    };

    const handleFilter = (name) => {
        return <input style={{ width: "100%", height: "37px", borderRadius: "5px", border: "none" }} value={values[name]} onChange={(e) => handleApplyFilter(e.target.value, name)}></input>;
    };

    const onsuccess = () => {
        onHideTyreBrandsDialog();
        toast.success("Flight added");
        window.location.reload();
    };

    const [showSoftStop, setShowSoftStop] = useState(false);

    const onHideSoftStop = () => {
        setShowSoftStop(false);
    };

    const onSuccessSoftStop = async () => {
        setShowSoftStop(false);
        if (selectedRow?.length > 0) {
            const selectedIds = selectedRow.map((val) => {
                return val?._id;
            });
            const data = {
                id: selectedIds,
            };
            const res = await dispatch(handlePostRequest(data, "/deleteFlight", true, true));
            if (res?.success) {
                getBrands();
                toast.success("Flight deleted");
                window.location.reload();
            }
        }
    };


    const handleSent=(rowData)=>{
        return <div>{rowData?.sentPackages?.length} packages</div>
    }
    const handleR=(rowData)=>{
        return <div>{rowData?.receivedPackages?.length} packages</div>
    }
    const handleL=(rowData)=>{
        return <div>{rowData?.leftPackages?.length} packages</div>
    }

    const dateTemplate = (rowdata) => {
        return (
            <div>
                <p>
                    {moment(rowdata?.createdAt).format("DD-MM-YYYY")} &nbsp; | &nbsp; {moment(rowdata?.createdOnTime).format("hh:mm a")}
                </p>
            </div>
        );
    };
    return (
        <>
            <Dialog visible={showDialog} header="Flights" style={{ width: "650px" }} onHide={() => setShowDialog(false)}>
                <AddFlightDialog onHideTyreBrandsDialog={onHideTyreBrandsDialog} onsuccess={onsuccess} />
            </Dialog>
            <Dialog visible={showSoftStop} style={{ width: "650px" }} onHide={() => setShowSoftStop(false)}>
                <SofStop onHideSoftStop={onHideSoftStop} onSuccessSoftStop={onSuccessSoftStop} type="flight"></SofStop>
            </Dialog>

            <div className="Page__Header">
                <div>
                    <h2>Flights</h2>
                </div>
                <div className="Top__Btn">
                    <Tooltip
                        // options
                        title="Add new flight"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-plus" iconPos="right" onClick={() => setShowDialog(true)} className="Btn__Blue" />
                    </Tooltip>
                    <Tooltip
                        // options
                        title="Delete selected flight"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-trash" iconPos="right" onClick={handledDelete} className="Btn__DarkDelete" style={{ width: "240px" }} />
                    </Tooltip>
                </div>
            </div>
            <div className="card">
                <DataTable
                    filterDisplay="row"
                    paginator
                    rows={100}
                    rowsPerPageOptions={[100, 500, 1000]}
                    className="datatable-responsive custom-datatable"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                    emptyMessage="No List found."
                    // responsiveLayout="scroll"
                    value={brands}
                    selection={selectedRow}
                    onSelectionChange={(e) => setselectedRow(e.value)}
                    sortMode="multiple"
                    loadingIcon="pi pi-spinner"
                    loading={loading}
                    loadingTemplate={<ClipLoader color={"#2C4870"} loading={loading} size={30} />}
                    loadingText="Loading..."
                    style={{ Height: "100px", overflowY: "auto" }}
                >
                    <Column selectionMode="multiple" style={{ width: "3em" }} />
                    <Column header="Action" body={actionBodyTemplate} />
                    <Column filter field="flightNumber" header="Flight number" sortable />
                    <Column body={handleSent} header="Packages sent"  />
                    <Column  body={handleR} header="Packages Received"  />
                    <Column  body={handleL} header="Packages left"/>
                    <Column header="Created On" body={dateTemplate}/>
                </DataTable>
            </div>
        </>
    );
}

export default Flights;
