import React, { useEffect, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { useHistory, useParams } from "react-router-dom";
import { handleGetRequest } from "../../services/GetTemplate";
import { handlePutRequest } from "../../services/PutTemplate";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import ResetPasswordDialog from "./ResetPasswordDialog";

function Customer() {
    const breadItems = [{ label: "Home" }, { label: "customer" }];
    const home = { icon: "pi pi-home", url: "/" };
    const [brandData, setBrandData] = useState();
    const [showDialog, setShowDialog] = useState(false);
    const [email, setEmail] = useState("");
    const history = useHistory();
    const { id } = useParams();
    const [selectedDelivery, setSelectedDelivery] = useState(null);
    const [role, setRole] = useState();
    const [DeliveryInfos, setDeliveryInfos] = useState([]);
    const [couriers, setCouriers] = useState([]);
    const [selecCourier, setSelectCourier] = useState(null);

    const getDetails = async () => {
        const res = await handleGetRequest(`/getuser/${id}`);
        const res2 = await handleGetRequest(`/getAllDeliveryInfo`);
        const couriers = await handleGetRequest(`/userRole?role=courier`);
        setCouriers(couriers?.data);
        setDeliveryInfos(res2?.data);
        console.log("yes");
        const keyData = res?.data;
        setEmail(res?.data?.email);
        setSelectCourier(res?.data?.courierBase);
        setBrandData(keyData);
        setRole(res?.data?.role);
        console.log(res?.data);
        Object.keys(keyData).forEach((key) => {
            if (formik.initialValues.hasOwnProperty(key)) {
                formik.setFieldValue(key, keyData[key]);
            }
        });
        setSelectedDelivery(keyData?.deliveryInfo);
    };
    useEffect(() => {
        getDetails();
    }, [id]);


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            firstName: brandData?.firstName ?? "",
            lastName: "",
            phone: "",
            TRN: brandData?.client?.TRN ? brandData?.client?.TRN : brandData?.courier?.TRN ? brandData?.courier?.TRN : "",
            mailingAddress: brandData?.client?.mailingAddress ? brandData?.client?.mailingAddress : brandData?.courier?.mailingAddress ? brandData?.courier?.mailingAddress : "",
            deliveryAddress: brandData?.client?.deliveryAddress ? brandData?.client?.deliveryAddress : brandData?.courier?.deliveryAddress ? brandData?.courier?.deliveryAddress : "",
            businessName: brandData?.courier?.businessName ?? "",
            TCC: brandData?.courier?.TCC ?? "",
            businessType: brandData?.courier?.businessType ?? "",
            dateJoined: brandData?.courier?.dateJoined ?? "",
            deliveryInfo: brandData?.deliveryInfo ?? "",
            role: "",
            _id: "",
            courierBase:"",
        },

        onSubmit: async (data) => {
            data._id = id;
            data.deliveryInfo = selectedDelivery;
            data.role = role;
            data.courierBase = selecCourier;
            const result = await handlePutRequest(data, "/Edituser");
            console.log(result);
            if (result?.success) {
                getDetails();
                toast.success("user Edited");
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleCancel = () => {
        history.push("/customers");
    };

    const onHideTyreBrandsDialog = () => {
        setShowDialog(false);
    };

    const onsuccess = () => {
        onHideTyreBrandsDialog();
        toast.success("Password reset successfully.");
    };

    console.log(brandData?.role);

    return (
        <>
            <Dialog visible={showDialog} header="Customers" style={{ width: "650px" }} onHide={() => setShowDialog(false)}>
                <ResetPasswordDialog onHideTyreBrandsDialog={onHideTyreBrandsDialog} onsuccess={onsuccess} email={email} />
            </Dialog>
            <div className="customer_header__">
                <div className="left___">
                    <h2>{brandData?.title}</h2>
                    <BreadCrumb model={breadItems} home={home} />
                </div>
                <div className="Top__Btn">
                    <Button label="Reset Password" icon="pi pi-plus" iconPos="right" onClick={() => setShowDialog(true)} className="Btn__DarkAdd" style={{ width: "240px" }} />
                </div>
            </div>
            <div className="customer_details_section">
                <div className="right_section">
                    <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                        <div className="form__">
                            <div className="form_left">
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="firstName" className={classNames({ "p-error": isFormFieldValid("firstName") }, "Label__Text")}>
                                        First Name
                                    </label>
                                    <InputText id="firstName" name="firstName" value={formik.values.firstName} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("firstName") }, "Input__Round")} />

                                    {getFormErrorMessage("firstName")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="lastName" className={classNames({ "p-error": isFormFieldValid("lastName") }, "Label__Text")}>
                                        Last Name
                                    </label>
                                    <InputText id="lastName" name="lastName" value={formik.values.lastName} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("lastName") }, "Input__Round")} />

                                    {getFormErrorMessage("lastName")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="phone" className={classNames({ "p-error": isFormFieldValid("phone") }, "Label__Text")}>
                                        Phone Number
                                    </label>
                                    <InputText id="phone" name="phone" value={formik.values.phone} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("phone") }, "Input__Round")} />

                                    {getFormErrorMessage("phone")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="phone" className={classNames({ "p-error": isFormFieldValid("phone") }, "Label__Text")}>
                                        Delivery Info
                                    </label>
                                    <select style={{ height: "40px", border: "1px solid #cecece", borderRadius: "7px" }} value={selectedDelivery} onChange={(e) => setSelectedDelivery(e.target.value)}>
                                        <option disabled selected>
                                            Please select delivery Information
                                        </option>
                                        {DeliveryInfos?.map((item) => {
                                            return <option value={item.name}>{item.name}</option>;
                                        })}
                                    </select>
                                    {getFormErrorMessage("phone")}
                                </div>
                                {brandData?.role === "client" || brandData?.role === "courier" || brandData?.role === "corporate" || brandData?.role === "reseller" ? (
                                    <>
                                        <div style={{ marginTop: "10px" }}>
                                            <label htmlFor="mailingAddress" className={classNames({ "p-error": isFormFieldValid("mailingAddress") }, "Label__Text")}>
                                                MailingAddress
                                            </label>
                                            <InputText id="mailingAddress" name="mailingAddress" value={formik.values.mailingAddress} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("mailingAddress") }, "Input__Round")} />

                                            {getFormErrorMessage("mailingAddress")}
                                        </div>
                                    </>
                                ) : null}
                                {brandData?.role === "courier" && (
                                    <>
                                        <div style={{ marginTop: "10px" }}>
                                            <label htmlFor="businessName" className={classNames({ "p-error": isFormFieldValid("businessName") }, "Label__Text")}>
                                                Business Name
                                            </label>
                                            <InputText id="businessName" name="businessName" value={formik.values.businessName} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("businessName") }, "Input__Round")} />

                                            {getFormErrorMessage("businessName")}
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                            <label htmlFor="businessType" className={classNames({ "p-error": isFormFieldValid("businessType") }, "Label__Text")}>
                                                Business Type
                                            </label>
                                            <InputText id="businessType" name="businessType" value={formik.values.businessType} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("businessType") }, "Input__Round")} />

                                            {getFormErrorMessage("businessType")}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="form_right">
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="email" className={classNames({ "p-error": isFormFieldValid("email") }, "Label__Text")}>
                                        Email Address
                                    </label>
                                    <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("email") }, "Input__Round")} />

                                    {getFormErrorMessage("email")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="role" className={classNames({ "p-error": isFormFieldValid("role") }, "Label__Text")}>
                                        User Type
                                    </label>
                                    <select style={{ width: "100%" }} className="singp_select" value={role} onChange={(e) => setRole(e.target.value)}>
                                        <option selected disabled>
                                            Select Role
                                        </option>
                                        <option value="client">Client</option>
                                        <option value="courier">Courier</option>
                                        <option value="partner">Partner</option>
                                        <option value="clerk">Clerk</option>
                                        <option value="admin">Admin</option>
                                        <option value="handler">Handler</option>
                                        <option value="airportStaff">Airport Staff</option>
                                        <option value="corporate">Corporate</option>
                                        <option value="reseller">Reseller</option>
                                    </select>

                                    {getFormErrorMessage("role")}
                                </div>
                                {brandData?.role === "client" || brandData?.role === "courier" || brandData?.role === "corporate" || brandData?.role === "reseller" ? (
                                    <>
                                        <div style={{ marginTop: "10px" }}>
                                            <label htmlFor="TRN" className={classNames({ "p-error": isFormFieldValid("TRN") }, "Label__Text")}>
                                                TRN
                                            </label>
                                            <InputText id="TRN" name="TRN" value={formik.values.TRN} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("TRN") }, "Input__Round")} />

                                            {getFormErrorMessage("TRN")}
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                            <label htmlFor="deliveryAddress" className={classNames({ "p-error": isFormFieldValid("deliveryAddress") }, "Label__Text")}>
                                                Delivery Address
                                            </label>
                                            <InputText id="deliveryAddress" name="deliveryAddress" value={formik.values.deliveryAddress} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("deliveryAddress") }, "Input__Round")} />

                                            {getFormErrorMessage("deliveryAddress")}
                                        </div>
                                    </>
                                ) : null}
                                {brandData?.role === "courier" && (
                                    <>
                                        <div style={{ marginTop: "10px" }}>
                                            <label htmlFor="TCC" className={classNames({ "p-error": isFormFieldValid("TCC") }, "Label__Text")}>
                                                TCC
                                            </label>
                                            <InputText id="TCC" name="TCC" value={formik.values.TCC} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("TCC") }, "Input__Round")} />

                                            {getFormErrorMessage("TCC")}
                                        </div>
                                    </>
                                )}
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="role" className={classNames({ "p-error": isFormFieldValid("role") }, "Label__Text")}>
                                        Base courier
                                    </label>
                                    <select style={{ width: "100%" }} className="singp_select" value={selecCourier} onChange={(e) => setSelectCourier(e.target.value)}>
                                        <option selected disabled>
                                            Select courier base
                                        </option>
                                        {couriers?.map((item) => {
                                            return <option value={item._id}>{item.courier?.businessName}</option>;
                                        })}
                                    </select>

                                    {getFormErrorMessage("role")}
                                </div>
                            </div>
                        </div>

                        <div className="Down__Btn">
                            <Button label="Cancel" className="Btn__Transparent" onClick={handleCancel} />
                            <Button label="Update" className="Btn__Dark" type="submit" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Customer;
