import React from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import { handlePutRequest } from "../../services/PutTemplate";
import { toast } from "react-toastify";
import { Button } from "primereact/button";

function UploadImageDialog({onHideUpload,onSuccessUpload,pack}) {
    const [image, setImage] = useState(null);

    const uploadImage = async (file) => {
        let name = file.name;
        let filename = name.replace(/\.[^/.]+$/, "") + "-" + Math.floor(Math.random() * 10000000 + 1);
        const storageRef = ref(storage, filename);
        await uploadBytes(storageRef, file).then((snapshot) => {});
        await getDownloadURL(storageRef).then(async (url) => {
            setImage(url);
            const data = {
                Waybill: pack?.Waybill,
                invoiceImage: url,
            };
            const result = await handlePutRequest(data, "/uploadInvoice");
            if (result?.success) {
               toast.success("Invoice uploaded successfully");
            }
        });
    };
    
    const handleClose=()=>{
        onSuccessUpload()
    }
    return (
        <div>
            <div style={{ marginTop: "10px" }}>
                <label htmlFor="invoiceImage" className={classNames("Label__Text")}>
                    Upload invoice Image
                </label>
                <InputText id="invoiceImage" type="file" name="invoiceImage" style={{ marginTop: "10px" }} onChange={(e) => uploadImage(e.target.files[0])} className={classNames("Input__Round")} />
            </div>
            {image && (
                <div>
                    <img src={image} style={{ width: "30%", objectFit: "cover", marginTop: "20px", height: "50%" }}></img>
                </div>
            )}
            {image && <div style={{display:"flex",justifyContent:"flex-end"}}>
            <Button label="Upload" className="Btn__Dark" type="submit" onClick={handleClose}/>
            </div>}
        </div>
    );
}

export default UploadImageDialog;
