import React, { useEffect, useState } from "react";
import "./advance.scss";
import { useHistory } from "react-router-dom";

function InvoiceAdvance() {
    const history = useHistory();

    useEffect(() => {
        // Check if the page has already been refreshed in this session
        const isRefreshed = sessionStorage.getItem("isRefreshed");
        
        if (!isRefreshed) {
            // Set the flag in sessionStorage to indicate the page has been refreshed
            sessionStorage.setItem("isRefreshed", "true");
            // Refresh the page
            window.location.reload();
        }
        else if(isRefreshed)
            {
                sessionStorage.removeItem("isRefreshed");
            }
    }, []);
    return (
        <div className="Advance_main">
            <h1>Invoices</h1>
            <div className="advance_inner">
                <div className="feature_block" onClick={() => history.push("/invoice/pending")}>
                    <p>Pending Review</p>
                </div>
                <div className="feature_block" onClick={() => history.push("/invoice/unpaid")}>
                    <p>Unpaid Invoices</p>
                </div>
                <div className="feature_block" onClick={() => history.push("/invoice/paid")}>
                    <p>Paid Invoices</p>
                </div>
            </div>
        </div>
    );
}

export default InvoiceAdvance;
