import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import moment from "moment/moment";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { handleGetRequest } from "../../services/GetTemplate";

function AddCreditDialog({ onCreditSuccess }) {
    const [role, setRole] = useState();
    const dispatch = useDispatch();
    const history = useHistory();
    const [couriers, setCouriers] = useState([]);
    const [selectedCourier, setSelectedCourier] = useState();
    const [credit, setCredit] = useState();
    console.log(couriers);

    const getData = async () => {
        const res = await handleGetRequest("/getPartner");
        setCouriers(res?.data);
    };

    useEffect(() => {
        getData();
    }, []);

    const formik = useFormik({
        initialValues: {},

        onSubmit: async (data) => {
            const dat = {
                id: selectedCourier,
                credit: credit,
            };
            const result = await dispatch(handlePostRequest(dat, "/addCredit", true, true));
            if (result !== "error") {
                onCreditSuccess();
            }
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ marginTop: "10px" }}>
                                <label htmlFor="role" className={classNames({ "p-error": isFormFieldValid("role") }, "Label__Text")}>
                                    Partner
                                </label>
                                <select style={{ width: "100%" }} className="singp_select" value={selectedCourier} onChange={(e) => setSelectedCourier(e.target.value)}>
                                    <option selected disabled>
                                        Select Partner
                                    </option>
                                    {couriers.map((item) => (
                                        <option value={item._id}>{`${item.firstName} ${item?.lastName}      (${item?.credit})`}</option>
                                    ))}
                                </select>

                                {getFormErrorMessage("role")}
                            </div>
                        </div>

                        <div className="p-field">
                            <label htmlFor="firstName" className={classNames({ "p-error": isFormFieldValid("firstName") }, "Label__Text")}>
                                Credit
                            </label>
                            <InputText id="firstName" name="firstName" value={credit} onChange={(e) => setCredit(e.target.value)} type="number" className={classNames({ "p-invalid": isFormFieldValid("firstName") }, "Input__Round")} />

                            {getFormErrorMessage("firstName")}
                        </div>
                    </div>
                </div>
                <div className="Down__Btn">
                    <Button label="Add credit" className="Btn__Dark" />
                </div>
            </form>
        </>
    );
}

export default AddCreditDialog;
