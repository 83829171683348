import React, { useEffect, useMemo, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { handleGetRequest } from "../../services/GetTemplate";
import moment from "moment";
import Paginator from "../../components/Paginator";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { toast } from "react-toastify";
import Axios from "axios";
import { DEV } from "../../services/constants";
import AddCustomerDialog from "./AddCustomerDialog";
import { Dialog } from "primereact/dialog";
import { useLocation } from "react-router-dom";
import { JsonToExcel } from "react-json-to-excel";
import SofStop from "../../modals/SofStop";
import { Tooltip } from "react-tippy";
import AddCreditDialog from "./AddCreditDialog";

function Partner() {
    const location = useLocation();
    const [selectedRow, setselectedRow] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [brands, setBrands] = useState([]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const breadItems = [{ label: "Home", url: "/" }, { label: "Partners" }];

    const home = { icon: "pi pi-home", url: "/" };
    const history = useHistory();

    const getBrands = async () => {
        setLoading(true);
        const res = await handleGetRequest("/getPartner");
        setBrands(res?.data);
        setLoading(false);
    };
    useMemo(() => {
        getBrands();
    }, []);

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Tooltip
                    // options
                    title="Remove as partner"
                    position="bottom"
                    trigger="mouseenter"
                    arrow={true}
                >
                    <Button icon="pi pi-times" className="p-button-rounded mr-2 Elipse_Icon" onClick={(e) => handleActionButton(e, rowData)} aria-controls="popup_menu" aria-haspopup />
                </Tooltip>
            </div>
        );
    };
    const handleActionButton = async (e, rowdata) => {
        e.preventDefault();
        const data = {
            id: rowdata?._id,
            status: false,
        };
        const dat = await dispatch(handlePostRequest(data, "/makePartner", true, true));
        if (dat?.success) {
            toast.success("Partner removed");
            window.location.reload();
        }
    };

    const onHideTyreBrandsDialog = () => {
        setShowDialog(false);
    };

    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        date: "",
        credit: "",
    });

    const temporary = ["firstName", "lastName", "email", "phone", "date", "credit"];

    const handleApplyFilter = async (value, names) => {
        setLoading(true);
        const temp = values;
        // temporary.forEach((item) => {
        //     if (item !== names) {
        //         temp[item] = "";
        //     }
        // });
        // setValues(temp);
        temp[names] = value;
        setValues({ ...values, [names]: value });
        const result = await Axios.get(DEV + "/searchPartner", {
            params: {
                ...temp,
            },
        });
        setBrands(result?.data?.data);
        setLoading(false);
    };

    const handleFilter = (name) => {
        return <input style={{ width: "100%", height: "37px", borderRadius: "5px", border: "none" }} value={values[name]} onChange={(e) => handleApplyFilter(e.target.value, name)}></input>;
    };

    const onsuccess = () => {
        onHideTyreBrandsDialog();
        toast.success("Partner added");
        window.location.reload();
    };

    const BusinesNameTemplate = (rowData) => {
        return <div>{rowData?.courier?.businessName}</div>;
    };

    const [showCrtedit, setShowCredit] = useState(false);

    const onHideCreditDialog = () => {
        setShowCredit(false);
    };

    const onCreditSuccess = () => {
        onHideCreditDialog();
        toast.success("Credit added");
        window.location.reload();
    };

    return (
        <>
            <Dialog visible={showDialog} header="Add New Partner" style={{ width: "650px" }} onHide={() => setShowDialog(false)}>
                <AddCustomerDialog onHideTyreBrandsDialog={onHideTyreBrandsDialog} onsuccess={onsuccess} />
            </Dialog>
            <Dialog visible={showCrtedit} header="Add Credit" style={{ width: "650px" }} onHide={() => setShowCredit(false)}>
                <AddCreditDialog onHideTyreBrandsDialog={onHideTyreBrandsDialog} onCreditSuccess={onCreditSuccess} />
            </Dialog>

            <div className="Page__Header">
                <div>
                    <h2>Partners</h2>
                    <BreadCrumb model={breadItems} home={home} />
                </div>
                <div className="Top__Btn">
                    <Tooltip
                        // options
                        title="Add New partner"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-plus" iconPos="right" onClick={() => setShowDialog(true)} className="Btn__Blue" />
                    </Tooltip>
                    <Tooltip
                        // options
                        title="Add Credit"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-wallet" iconPos="right" onClick={() => setShowCredit(true)} className="Btn__Blue" />
                    </Tooltip>
                </div>
            </div>

            <div className="card">
                <DataTable
                    filterDisplay="row"
                    paginator
                    rows={100}
                    rowsPerPageOptions={[100, 500,1000]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                    emptyMessage="No List found."
                    responsiveLayout="scroll"
                    value={brands}
                    selection={selectedRow}
                    onSelectionChange={(e) => setselectedRow(e.value)}
                    sortMode="multiple"
                    loadingIcon="pi pi-spinner"
                    loading={loading}
                >
                    <Column selectionMode="multiple" style={{ width: "3em" }} />
                    <Column header="Action" body={actionBodyTemplate} />
                    <Column filter field="firstName" header="First Name" filterElement={() => handleFilter("firstName")} sortable />
                    <Column filter field="lastName" header="Last Name" filterElement={() => handleFilter("lastName")} sortable />
                    <Column filter field="email" header="Email" filterElement={() => handleFilter("email")} sortable />
                    <Column body={BusinesNameTemplate} header="Business Name" />
                    <Column filter field="phone" header="Phone Number" filterElement={() => handleFilter("phone")} sortable />
                    <Column filter field="credit" header="Remaining credit" filterElement={() => handleFilter("credit")} sortable />
                    <Column filter header="Created On" field="date" filterElement={() => handleFilter("date")} />
                    
                </DataTable>
            </div>
        </>
    );
}

export default Partner;
