import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Editor } from "primereact/editor";
import Barcode from "react-barcode";
import { handleGetRequest } from "../../services/GetTemplate";
import { clientType, PackageTypes, status } from "../../assets/data/data";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import moment from "moment/moment";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";

const AddPackageDialog = ({ onHideTyreBrandDialog, onsuccess }) => {
    const history = useHistory();
    const [clients, setClients] = useState([]);
    const [couriers, setCouriers] = useState([]);
    const [selectedCouriers, setSelectedCouriers] = useState();
    const [selectedPackType, setSelectedPAckType] = useState();
    const [selectedUnit, setSelectedUnit] = useState("lb");
    const [selectedShipment, setSlectedShipment] = useState();
    const [consigneeType, setConsigneeType] = useState();
    const [packages, setPackages] = useState([]);
    const [selectedInventory, setSelectedInventory] = useState();
    const [selectedConsignment, setSelectedConsignment] = useState();
    const [ZA, setZA] = useState();
    const [ZS, setZS] = useState();
    const [F, setF] = useState();
    const [S, setS] = useState();
    const [over, setOver] = useState();
    const dispatch = useDispatch();
    const [shipmentNumber, setShipmentNumber] = useState();
    const [selectedDelivery, setSelectedDelivery] = useState(null);
    const [totalClients, setTotalClients] = useState();
    const [images, setImages] = useState([]);
    const [DeliveryInfos, setDeliveryInfos] = useState([]);
   

    
    const getDatas=async()=>{
        const res = await handleGetRequest(`/getAllDeliveryInfo`);
        setDeliveryInfos(res?.data);
    }

    useEffect(()=>{
        getDatas();
    },[])


    function isAllowedNumberDifference(number1, number2) {
        console.log(number1, number2);
        const absoluteDifference = Math.abs(number1 - number2);
        return absoluteDifference <= 2;
    }

    console.log(S);

    const formik = useFormik({
        initialValues: {
            Waybill: "",
            oversizePackage: "",
            name: "",
            consigneeName: "",
            merchant: "",
            description: "",
            weight: "",
            flightNumber: "",
            courierName: "",
            shipmentMethod: "",
            packageType: "",
            clientType: "",
            unit: "",
            price: "",
            shipmentNumber: "",
            numberOfPieces: "",
            consigneeType: "",
            date: "",
            trackingNumber: "",
            billTo: "",
            approval: "approved",
            deliveryInfo: "",
            notes: "",
            notesImages: [],
        },

        onSubmit: async (data) => {
            data.oversizePackage = over === "true" ? true : false;
            data.consigneeName = data.consigneeName;
            data.number = data.weight;
            data.unit = selectedUnit;
            data.courierName = selectedCouriers;
            data.shipmentMethod = selectedShipment;
            data.packageType = selectedPackType;
            data.consigneeType = consigneeType;
            data.Waybill = ZA;
            data.name = selectedConsignment.value;
            data.billTo = data.billTo;
            data.trackingNumber = data.trackingNumber;
            data.deliveryInfo = selectedDelivery;
            data.notesImages = images;
            const currentDate = moment().format("DD-MM-YYYY");
            data.date = currentDate;
            if (selectedInventory === "ZA") {
                const numberAsString = shipmentNumber.substr(1);
                const numberAsInt = parseInt(numberAsString, 10); // Convert the string to a number with base 10
                const numberAsString1 = F.substr(1);
                const numberAsInt1 = parseInt(numberAsString1, 10); // Convert the string to a number with base 10
                const tmep = isAllowedNumberDifference(numberAsInt, numberAsInt1);

                if (tmep) {
                    data.flightNumber = shipmentNumber;

                    const result = await dispatch(handlePostRequest(data, "/addPackage", true, true));
                    if (result !== "error") {
                        onsuccess();
                    }
                } else {
                    toast.error(`Please enter a valid flight number.The last Flight number is ${F}`);
                }
            } else if (selectedInventory === "ZS") {
                const numberAsString = shipmentNumber.substr(1);
                const numberAsInt = parseInt(numberAsString, 10); // Convert the string to a number with base 10
                const numberAsString1 = S.substr(1);
                const numberAsInt1 = parseInt(numberAsString1, 10); // Convert the string to a number with base 10
                const tmep = isAllowedNumberDifference(numberAsInt, numberAsInt1);
                if (tmep) {
                    data.shipmentNumber = shipmentNumber;
                    const result = await dispatch(handlePostRequest(data, "/addPackage", true, true));
                    if (result !== "error") {
                        onsuccess();
                    }
                } else {
                    toast.error(`Please enter a valid shipment number.The last shipment number is ${S}`);
                }
            }
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getData = async () => {
        const res = await handleGetRequest("/clientAndAdmins");
        const result = await handleGetRequest("/userRole?role=courier");
        const pack = await handleGetRequest("/allPackages");

        let lastZA;
        let lastZS;
        let lastF;
        let lastS;
        const zaInventoryNumbers = pack?.data?.filter((item) => item.Waybill.startsWith("Z"));
        const zsInventoryNumbers = pack?.data?.filter((item) => item.Waybill.startsWith("ZS"));
        const fInventoryNumbers = pack?.data?.filter((item) => item.flightNumber.startsWith("F"));
        const sInventoryNumbers = pack?.data?.filter((item) => item.shipmentNumber.startsWith("S"));
        if (zaInventoryNumbers.length > 0) {
            let numberAsString = zaInventoryNumbers[0].Waybill.substr(1);
            let numberAsInt = parseInt(numberAsString, 10); // Convert the string to a number with base 10
            let incrementedNumber = numberAsInt + 1;
            let incrementedNumberAsString = incrementedNumber.toString().padStart(numberAsString.length, "0");
            lastZA = "Z" + incrementedNumberAsString;
        } else {
            lastZA = "Z000100";
        }

        if (zsInventoryNumbers.length > 0) {
            let numberAsString = zsInventoryNumbers[0].Waybill.substr(2);
            let numberAsInt = parseInt(numberAsString, 10); // Convert the string to a number with base 10
            let incrementedNumber = numberAsInt;
            let incrementedNumberAsString = incrementedNumber.toString().padStart(numberAsString.length, "0");
            lastZS = "ZS" + incrementedNumberAsString;
        } else {
            lastZS = "ZS000100";
        }

        if (fInventoryNumbers.length > 0) {
            let numberAsString = fInventoryNumbers[0].flightNumber.substr(1);
            let numberAsInt = parseInt(numberAsString); // Convert the string to a number with base 10
            let incrementedNumber = numberAsInt;
            lastF = "F" + incrementedNumber;
        } else {
            lastF = "F100";
        }

        if (sInventoryNumbers.length > 0) {
            let numberAsString = sInventoryNumbers[0].shipmentNumber.substr(1);
            let numberAsInt = parseInt(numberAsString); // Convert the string to a number with base 10
            let incrementedNumber = numberAsInt + 1;
            lastS = "S" + incrementedNumber;
        } else {
            lastS = "S100";
        }

        setZA(lastZA);
        setZS(lastZS);
        setF(lastF);
        setS(lastS);
        const temp = res?.data?.map((item) => {
            return {
                label: `${item?.firstName} ${item?.lastName}`,
                value: item?._id,
            };
        });
        setTotalClients(res?.data);
        setPackages(pack?.data);
        setClients(temp);
        setCouriers(result?.data);
    };

    useEffect(() => {
        getData();
    }, []);

    const handleConsignemnt = (selectedOption) => {
        setSelectedConsignment(selectedOption);
        const filtered = totalClients?.filter((item) => item._id === selectedOption.value);
        setSelectedDelivery(filtered[0]?.deliveryInfo);
        setConsigneeType(filtered[0]?.role);
        setSelectedCouriers(filtered[0]?.courierBase);
        formik.values.consigneeName = selectedOption.label;
    };

    const handleImagesUpload = async (file) => {
        let name = file.name;
        let filename = name.replace(/\.[^/.]+$/, "") + "-" + Math.floor(Math.random() * 10000000 + 1);
        const storageRef = ref(storage, filename);
        await uploadBytes(storageRef, file).then((snapshot) => {});
        await getDownloadURL(storageRef).then(async (url) => {
            setImages([...images, url]);
        });
    };

    const handleRemove = (index) => {
        let temp = images;
        temp.splice(index, 1);
        setImages([...temp]);
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-12" style={{ display: "flex", flexDirection: "column" }}>
                        <label htmlFor="unit" className={classNames({ "p-error": isFormFieldValid("unit") }, "Label__Text")}>
                            Inventory Type
                        </label>
                        <select
                            style={{ height: "40px", border: "1px solid #cecece", borderRadius: "7px" }}
                            value={selectedInventory}
                            onChange={(e) => {
                                setSelectedInventory(e.target.value);
                                if (e.target.value === "ZA") {
                                    setSlectedShipment("air");
                                } else {
                                    setSlectedShipment("shipment");
                                }
                            }}
                        >
                            <option disabled selected>
                                Please select a type
                            </option>
                            <option value="ZA">ZA</option>
                            <option value="ZS">ZS</option>
                        </select>
                        {getFormErrorMessage("unit")}
                    </div>
                    {selectedInventory && (
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="Waybill" className={classNames({ "p-error": isFormFieldValid("Waybill") }, "Label__Text")}>
                                    Way Bill
                                </label>
                                <InputText disabled id="Waybill" name="Waybill" value={ZA} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("Waybill") }, "Input__Round")} />

                                {getFormErrorMessage("Waybill")}
                            </div>
                        </div>
                    )}
                    {selectedInventory && <div className="p-field col-12 md:col-12">{ZA && <Barcode value={ZA} height={50} />}</div>}

                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="description" className={classNames({ "p-error": isFormFieldValid("description") }, "Label__Text")}>
                                Description
                            </label>
                            <InputText id="description" name="description" value={formik.values.description} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("description") }, "Input__Round")} />

                            {getFormErrorMessage("description")}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-6">
                        <div className="p-field">
                            <label htmlFor="name" className={classNames({ "p-error": isFormFieldValid("name") }, "Label__Text")}>
                                Bill To
                            </label>
                            <Select options={clients} value={selectedConsignment} onChange={handleConsignemnt}></Select>

                            {getFormErrorMessage("name")}
                        </div>
                    </div>

                    <div className="p-field col-12 md:col-6">
                        <div className="p-field">
                            <label htmlFor="merchant" className={classNames({ "p-error": isFormFieldValid("merchant") }, "Label__Text")}>
                                Merchant Name
                            </label>
                            <InputText type="text" id="merchant" name="merchant" value={formik.values.merchant} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("merchant") }, "Input__RoundFile")} />

                            {getFormErrorMessage("merchant")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6">
                        <div className="p-field">
                            <label htmlFor="weight" className={classNames({ "p-error": isFormFieldValid("weight") }, "Label__Text")}>
                                Weight of package
                            </label>
                            <InputText type="text" id="weight" name="weight" value={formik.values.weight} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("weight") }, "Input__RoundFile")} />

                            {getFormErrorMessage("weight")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-6">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="unit" className={classNames({ "p-error": isFormFieldValid("unit") }, "Label__Text")}>
                                Unit
                            </label>
                            <select style={{ height: "40px", border: "1px solid #cecece", borderRadius: "7px" }} value={selectedUnit} onChange={(e) => setSelectedUnit(e.target.value)}>
                                <option disabled selected>
                                    Please select a unit
                                </option>
                                <option value="lb">Lb</option>
                                <option value="cu">Cu ft</option>
                                <option value="kg">Kg</option>
                            </select>
                            {getFormErrorMessage("unit")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="numberOfPieces" className={classNames({ "p-error": isFormFieldValid("numberOfPieces") }, "Label__Text")}>
                                Number of pieces
                            </label>
                            <InputText id="numberOfPieces" name="numberOfPieces" value={formik.values.numberOfPieces} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("numberOfPieces") }, "Input__Round")} />

                            {getFormErrorMessage("numberOfPieces")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="numberOfPieces" className={classNames({ "p-error": isFormFieldValid("numberOfPieces") }, "Label__Text")}>
                                Delivery Information
                            </label>
                            <select style={{ height: "40px", border: "1px solid #cecece", borderRadius: "7px" }} value={selectedDelivery} onChange={(e) => setSelectedDelivery(e.target.value)}>
                                <option disabled selected>
                                    Please select delivery Information
                                </option>
                                {DeliveryInfos?.map((client) => {
                                    return <option value={client?.name}>{client?.name}</option>;
                                })}
                            </select>

                            {getFormErrorMessage("numberOfPieces")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="numberOfPieces" className={classNames({ "p-error": isFormFieldValid("numberOfPieces") }, "Label__Text")}>
                                Consignee type
                            </label>
                            <select style={{ marginTop: "10px", height: "40px", border: "1px solid #cecece", borderRadius: "7px" }} value={consigneeType} onChange={(e) => setConsigneeType(e.target.value)}>
                                <option disabled selected>
                                    Please select a consignee type
                                </option>
                                <option value="client">Client</option>
                                <option value="courier">Courier</option>
                                <option value="courier">Partner</option>
                                <option value="admin">Admin</option>
                                <option value="corporate">Corporate</option>
                                <option value="reseller">Reseller</option>
                            </select>
                            {getFormErrorMessage("numberOfPieces")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="consigneeName" className={classNames({ "p-error": isFormFieldValid("consigneeName") }, "Label__Text")}>
                                Consignee Name
                            </label>
                            <InputText type="text" id="consigneeName" name="consigneeName" value={formik.values.consigneeName} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__RoundFile")} />

                            {getFormErrorMessage("consigneeName")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="billTo" className={classNames({ "p-error": isFormFieldValid("billTo") }, "Label__Text")}>
                                Name of The Package
                            </label>
                            <InputText type="text" id="billTo" name="billTo" value={formik.values.billTo} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__RoundFile")} />

                            {getFormErrorMessage("billTo")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="trackingNumber" className={classNames({ "p-error": isFormFieldValid("trackingNumber") }, "Label__Text")}>
                                Tracking Number
                            </label>
                            <InputText type="text" id="trackingNumber" name="trackingNumber" value={formik.values.trackingNumber} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__RoundFile")} />

                            {getFormErrorMessage("trackingNumber")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="courierName" className={classNames({ "p-error": isFormFieldValid("courierName") }, "Label__Text")}>
                                Courier Name
                            </label>
                            <select style={{ marginTop: "10px", height: "40px", border: "1px solid #cecece", borderRadius: "7px" }} value={selectedCouriers} onChange={(e) => setSelectedCouriers(e.target.value)}>
                                <option disabled selected>
                                    Please select a courier
                                </option>
                                {couriers?.map((client) => {
                                    return <option value={client?._id}>{client?.courier?.businessName}</option>;
                                })}
                            </select>

                            {getFormErrorMessage("courierName")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="overSizedPackage" className={classNames({ "p-error": isFormFieldValid("overSizedPackage") }, "Label__Text")}>
                                Oversized Package?
                            </label>
                            <select style={{ marginTop: "10px", height: "40px", border: "1px solid #cecece", borderRadius: "7px" }} value={over} onChange={(e) => setOver(e.target.value)}>
                                <option disabled selected>
                                    Please select a package condition
                                </option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </select>

                            {getFormErrorMessage("overSizedPackage")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="price" className={classNames({ "p-error": isFormFieldValid("price") }, "Label__Text")}>
                                Price
                            </label>
                            <InputText id="price" name="price" value={formik.values.price} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("price") }, "Input__Round")} />

                            {getFormErrorMessage("price")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="notes" className={classNames({ "p-error": isFormFieldValid("notes") }, "Label__Text")}>
                                Notes
                            </label>
                            <textarea style={{ height: "100px", border: "1px solid #cecece", borderRadius: "6px" }} id="notes" name="notes" value={formik.values.notes} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("notes") }, "Input__Round")} />

                            {getFormErrorMessage("notes")}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field">
                            <label htmlFor="notes" className={classNames({ "p-error": isFormFieldValid("notes") }, "Label__Text")}>
                                Notes Images
                            </label>
                            <input type="file" style={{ border: "1px solid #cecece", borderRadius: "6px" }} onChange={(e) => handleImagesUpload(e.target.files[0])} className={classNames({ "p-invalid": isFormFieldValid("notes") }, "Input__Round")} />

                            {getFormErrorMessage("notes")}
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap", columnGap: "20px", marginLeft: "10px", marginRight: "10px" }}>
                        {images?.map((item,index) => {
                            return (
                                <div style={{position:"relative"}}>
                                    <p style={{position:'absolute',marginLeft:"90px",color:'red',fontSize:'20px',cursor:"pointer"}} onClick={()=>handleRemove(index)}>x</p>
                                    <img src={item} style={{ width: "100px", height: "100px", objectFit: "cover" }}></img>
                                </div>
                            );
                        })}
                    </div>
                    {selectedShipment && (
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="flightNumber" className={classNames({ "p-error": isFormFieldValid("flightNumber") }, "Label__Text")}>
                                    {selectedShipment === "air" ? "Flight Number" : "Shipment Number"}
                                </label>
                                <InputText id="flightNumber" name="flightNumber" value={shipmentNumber} onChange={(e) => setShipmentNumber(e.target.value)} className={classNames({ "p-invalid": isFormFieldValid("flightNumber") }, "Input__Round")} />

                                {getFormErrorMessage("flightNumber")}
                            </div>
                        </div>
                    )}
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="PackageType" className={classNames({ "p-error": isFormFieldValid("PackageType") }, "Label__Text")}>
                                Package Type
                            </label>
                            <select style={{ marginTop: "10px", height: "40px", border: "1px solid #cecece", borderRadius: "7px" }} value={selectedPackType} onChange={(e) => setSelectedPAckType(e.target.value)}>
                                <option disabled selected>
                                    Please select a package Type
                                </option>
                                {PackageTypes?.map((client) => {
                                    return <option value={client}>{client}</option>;
                                })}
                            </select>

                            {getFormErrorMessage("PackageType")}
                        </div>
                    </div>
                </div>
                <div className="Down__Btn">
                    <Button label="Create" className="Btn__Dark" />
                </div>
            </form>
        </>
    );
};

export default AddPackageDialog;
