import React from "react";
import { useEffect } from "react";
import { handlePostRequest } from "../services/PostTemplate";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Verify() {
    const dispatch = useDispatch();
    const history = useHistory();
    const verifyEmail = async () => {
        const data = {
            email: localStorage.getItem("email"),
        };
        const res = dispatch(handlePostRequest(data, "/verifyEmail"));
        if (res !== "error") {
            localStorage.removeItem("email");
        }
    };

    useEffect(() => {
        verifyEmail();
    }, []);
    return (
        <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <img src="https://res.cloudinary.com/dwxqg9so3/image/upload/v1689288133/zazio-logo-white_mgbnuy.jpg" style={{ width: "20%", height: "20%", objectFit: "cover" }}></img>
            <p style={{ fontSize: "20px", marginTop: "40px", fontWeight: "bold" }}>Dear User, Your Email has been confirmed.</p>
            <p style={{ fontSize: "18px", marginTop: "0px" }}>Please click below to login to your account.</p>
            <button style={{ backgroundColor: "black", color: "white", border: "none", marginTop: "40px", padding: "10px 100px", fontSize: "20px", borderRadius: "20px", cursor: "pointer" }} onClick={() => history.push("/login")}>
                Login
            </button>
        </div>
    );
}

export default Verify;
