import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

export const AppTopbar = (props) => {
    const name = localStorage.getItem("user");
    const role = localStorage.getItem("role");
    const history = useHistory();
    const handleSignout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("role");
        history.push("/login");
    };
    return (
        <>
            <Helmet>
                <link id="theme-link" rel="stylesheet" href="/assets/themes/lara-light-indigo/theme.css" />
            </Helmet>
            <div className="layout-topbar">
                {window.innerWidth > 768 && (
                    <Link to="/" className="layout-topbar-logo">
                        <img
                            src={
                                props.layoutColorMode === "light" ? "https://res.cloudinary.com/dwxqg9so3/image/upload/v1692496183/WhatsApp_Image_2023-08-18_at_6.45.10_AM_qg5n56.jpg" : "https://res.cloudinary.com/dwxqg9so3/image/upload/v1692496183/WhatsApp_Image_2023-08-18_at_6.45.10_AM_qg5n56.jpg"
                            }
                            style={{ height: "70px", width: "150px", objectFit: "cover" }}
                            alt="logo"
                        />
                        {/* <p>Zazio.co</p> */}
                    </Link>
                )}

                <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                    <i className="pi pi-ellipsis-v" />
                </button>
                <div className="search_div"></div>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", { "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive })}>
                    {window.innerWidth < 768 && (
                        <>
                            <div className="dropdwon__header_main">
                                {role === "admin" && (
                                    <Link to="/" className="p-4">
                                        <i className="pi pi-chart-pie" />
                                        <span className="layout-topbar-item-text" style={{ marginLeft: "10px" }}>
                                            Dashboard
                                        </span>
                                    </Link>
                                )}

                                <Link to="/packages" className="p-4">
                                    <i className="pi pi-briefcase" />
                                    <span className="layout-topbar-item-text" style={{ marginLeft: "10px" }}>
                                        Packages
                                    </span>
                                </Link>

                                {role === "admin" && (
                                    <Link to="/customers" className="p-4">
                                        <i className="pi pi-fw pi-user" />
                                        <span className="layout-topbar-item-text" style={{ marginLeft: "10px" }}>
                                            Customers
                                        </span>
                                    </Link>
                                )}

                                {role === "admin" && (
                                    <Link to="/labels" className="p-4">
                                        <i className="pi pi-fw pi-tags" />
                                        <span className="layout-topbar-item-text" style={{ marginLeft: "10px" }}>
                                            Labels
                                        </span>
                                    </Link>
                                )}

                                {role === "admin" && (
                                    <Link to="/deliveryReport" className="p-4">
                                        <i className="pi pi-fw pi-chart-line" />
                                        <span className="layout-topbar-item-text" style={{ marginLeft: "10px" }}>
                                            Delivery Reports
                                        </span>
                                    </Link>
                                )}

                                {role === "admin" && (
                                    <Link to="/scan" className="p-4">
                                        <i className="pi pi-fw pi-stop-circle" />
                                        <span className="layout-topbar-item-text" style={{ marginLeft: "10px" }}>
                                            Scan
                                        </span>
                                    </Link>
                                )}

                                {role === "admin" ? (
                                    <Link to="/invoices" className="p-4">
                                        <i className="pi pi-fw pi-wallet" />
                                        <span className="layout-topbar-item-text" style={{ marginLeft: "10px" }}>
                                            Invoices
                                        </span>
                                    </Link>
                                ) : (
                                    <Link to="/Userinvoices" className="p-4">
                                        <i className="pi pi-fw pi-wallet" />
                                        <span className="layout-topbar-item-text" style={{ marginLeft: "10px" }}>
                                            Invoices
                                        </span>
                                    </Link>
                                )}
                                 {role === "admin" && (
                                    <Link to="/advance" className="p-4">
                                        <i className="pi pi-fw pi-stop-circle" />
                                        <span className="layout-topbar-item-text" style={{ marginLeft: "10px" }}>
                                            Advance Features
                                        </span>
                                    </Link>
                                )}
                                 {role === "admin" && (
                                    <Link to="/flights" className="p-4">
                                        <i className="pi pi-fw pi-stop-circle" />
                                        <span className="layout-topbar-item-text" style={{ marginLeft: "10px" }}>
                                           Flights
                                        </span>
                                    </Link>
                                )}
                            </div>
                        </>
                    )}
                </ul>
                <div className="user_info">
                    <img src="https://res.cloudinary.com/dwxqg9so3/image/upload/v1692496183/WhatsApp_Image_2023-08-18_at_6.45.10_AM_qg5n56.jpg" alt="profile_img" style={{ height: "40px", width: "40px", objectFit: "cover", borderRadius: "50%", border: "1px solid black" }} />
                    <div className="p_div">
                        <p className="p1">{name}</p>
                        <p className="p2">{role}</p>
                    </div>
                </div>
                <div style={{ marginLeft: "30px", cursor: "pointer" }} onClick={handleSignout}>
                    <i className="pi pi-sign-out"></i>
                </div>
            </div>
        </>
    );
};
