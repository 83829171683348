export const status = ["pending payment", "preparing for flight", "flight departed", "clear custom", "out for delivery", "delivered", "warehouse rejected", "transfer per customer or courier request", "customer rejected", "detained by customer", "item damaged"];
export const PackageTypes = ["flammable", "Hazardous", "perishable", "nonperishable"];
export const clientType = ["courier", "business", "individual", "employee", "partner"];

export const importStatus = ["Delivered", "Departed Jamaica", "Landed in Miami", "Schedule Pickup/Delivery at https://zazio.co/schedule", "Released from US Customs (Miami Airport)", "Payment is Required for Release of package"];
export const exportAirStatus = [
    "Received at US Warehouse",
    "Preparing For Flight",
    "Departed Miami for Kingston",
    "Flight is Delayed",
    "Landed at Kingston Airport",
    "delayed by jamaica customs",
    "Held By Jamaica Customs for Duty Payment",
    "Cleared Jamaica Customs",
    "Payment is Required for Release",
];
export const exportSeaStatus = ["Received at US Warehouse", "Preparing For Sea Shipment", "Departed Miami for Kingston", "Arrived at Kingston Wharves", "delayed by jamaica customs", "Held By Jamaica Customs for Duty Payment", "Cleared Jamaica Customs", "Payment is Required for Release"];
export const deliveryStatus = [
    "out for delivery",
    "Our Driver will call the number on file",
    "Re-routed",
    "Delivery is Rescheduled",
    "Delivered",
    "Ready For Pickup - CeLink Jamaica Ltd (Trafalgar Road - New Kingston)",
    "Ready For Pickup - iRIE Kidz (Barbican Center, Kingston)",
    "Picked Up",
    "Pickup at Zazio Ja Ltd HQ (Ocho Rios)",
    "Delivered (Ocho Rios)",
    "Package sent via Knutsford to your requested pickup location.",
    "picked up (Knutsford Islandwide)",
    "Ready For Pickup - Auction Direct (Ironshore, Montego Bay)",
    "picked up (Montego Bay)",
];

export const statusArray =[
    "Departed Jamaica",
    "Arrived in Miami",
    "Released by Customs",
    "Payment is Required for Release of Package",
    "Schedule a Delivery/Pickup at http://zazio.co/schedule",
    "Received at US Warehouse",
    "Preparing Package for Shipment",
    "Currently at Airport Awaiting Flight",
    "Currently at Port Awaiting Shipment",
    "Flight is Delayed",
    "Departed Miami",
    "Arrived in Jamaica",
    "Detained by JA Customs for Duty Payment",
    "Out for Delivery",
    "Package is Re-routed",
    "Delivery is Rescheduled",
    "Delivered",
    "Picked Up",
    "Available for Pick-Up",
    "Package sent via Knutsford Express to your requested location",
    "Package sent via TARA to your requested location",
    "Ready for Pickup - CeLink (New Kingston)",
    "Ready for Pickup - iRie Kidz (Barbican)",
    "Ready for Pickup - ZAZIO HQ (Ocho Rios)",
    "Ready for Pickup - Auction Direct (Montego Bay)",
    "Ready for Pickup - Kingston Wharves",
    "Ready for Pickup – Montego Bay Freeport",
    "Gate Pass Ready for Shipment Pickup",
    "Container not yet stripped",
    "Delayed by Customs",
    "Dropped at Knutsford for delivery"
  ];
