import React, { useEffect, useMemo, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { handleGetRequest } from "../../services/GetTemplate";
import moment from "moment";
import Axios from "axios";
import { DEV } from "../../services/constants";
import { useLocation } from "react-router-dom";


import { Tooltip } from "react-tippy";

function Logs() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get("type");
    const [selectedRow, setselectedRow] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [brands, setBrands] = useState([]);
    const [skip, setSkip] = useState(0);

    const breadItems = [{ label: "Home", url: "/" }, { label: "Logs" }];

    const home = { icon: "pi pi-home", url: "/" };
    const history = useHistory();


    const getBrands = async () => {
        const res = await handleGetRequest(`/allLogs`);

        setBrands(res?.data);
    };
    useMemo(() => {
        getBrands();
    }, [skip]);

    const handleActionButton = (e, rowdata) => {
        e.preventDefault();
        history.push(`customer/${rowdata?._id}`);
    };

    const dateTemplate = (rowdata) => {
        return (
            <div>
                <p>
                    {moment(rowdata?.createdAt).format("DD-MM-YYYY")} &nbsp; | &nbsp; {moment(rowdata?.createdOnTime).format("hh:mm a")}
                </p>
            </div>
        );
    };

    const [values, setValues] = useState({
        message: "",
        user: "",
        method: "",
        type: "",
    });

    const temporary = ["message", "user", "method", "type"];

    const handleApplyFilter = async (value, names) => {
        const temp = values;
        // temporary.forEach((item) => {
        //     if (item !== names) {
        //         temp[item] = "";
        //     }
        // });
        // setValues(temp);
        temp[names] = value;
        setValues({ ...values, [names]: value });
        const result = await Axios.get(DEV + "/searchLogs", {
            params: {
                ...temp,
            },
        });
        setBrands(result?.data?.data);
    };

    const handleFilter = (name) => {
        return <input style={{ width: "100%", height: "37px", borderRadius: "5px", border: "none" }} value={values[name]} onChange={(e) => handleApplyFilter(e.target.value, name)}></input>;
    };

    return (
        <>
            <div className="Page__Header">
                <div>
                    <h2>Logs</h2>
                   
                </div>
                <div className="Top__Btn">
                    {/* <Tooltip
                        // options
                        title="Add new customer"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-plus" iconPos="right" onClick={() => setShowDialog(true)} className="Btn__Blue" />
                    </Tooltip>
                    <Tooltip
                        // options
                        title="Delete selected customers"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-trash" iconPos="right" onClick={handledDelete} className="Btn__DarkDelete" style={{ width: "240px" }} />
                    </Tooltip> */}
                </div>
            </div>
           
                    <div className="card ">
                       
                        <DataTable
                            filterDisplay="row"
                            paginator
                            rows={100}
                            rowsPerPageOptions={[100, 250, 500]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={brands}
                            selection={selectedRow}
                            onSelectionChange={(e) => setselectedRow(e.value)}
                            sortMode="multiple"
                        >
                            <Column selectionMode="multiple" style={{ width: "3em" }} />
                            <Column filter field="message" header="Message" filterElement={() => handleFilter("message")} sortable />
                            <Column filter field="user" header="Made by" filterElement={() => handleFilter("user")} sortable />
                            <Column filter field="method" header="Method" filterElement={() => handleFilter("method")} sortable />
                            <Column filter field="type" header="Type" filterElement={() => handleFilter("type")} sortable />
                            <Column body={dateTemplate} header="CreatedAt" />

                        </DataTable>
                       
                    </div>
                
        </>
    );
}

export default Logs;
