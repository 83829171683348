import React, { useEffect, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { useHistory, useParams } from "react-router-dom";
import { handleGetRequest } from "../../services/GetTemplate";
import { clientType, PackageTypes, status, importStatus, exportAirStatus, exportSeaStatus, deliveryStatus, statusArray } from "../../assets/data/data";
import { handlePutRequest } from "../../services/PutTemplate";
import { toast } from "react-toastify";
import Barcode from "react-barcode";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";


function PackageDetail({ data }) {
    const breadItems = [{ label: "Home" }, { label: "package" }];
    const home = { icon: "pi pi-home", url: "/" };
    const [brandData, setBrandData] = useState();
    const [clients, setClients] = useState([]);
    const [selectClients, setSelectedClients] = useState();
    const [couriers, setCouriers] = useState([]);
    const [selectedCouriers, setSelectedCouriers] = useState();
    const [selectedImportStatus, setSelectedImportStatus] = useState();
    const [selectedPackType, setSelectedPAckType] = useState();
    const [selectedExportSeaStatus, setSelectedExportSeaStatus] = useState();
    const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState();
    const [selectedExportAirStatus, setSelectedExportAirStatus] = useState();
    const [selectedUnit, setSelectedUnit] = useState();
    const [selectedShipment, setSlectedShipment] = useState();
    const [consigneeType, setConsigneeType] = useState();
    const [image, setImage] = useState();
    const [over, setOver] = useState();
    const history = useHistory();
    const { id } = useParams();
    const [role, setrole] = useState("");
    const [delivery, setDelivery] = useState();


    const getDetails = async () => {
        const res = await handleGetRequest(`/package/${data?.Waybill}`);
        const rese = await handleGetRequest("/userRole?role=client");
        const result = await handleGetRequest("/userRole?role=courier");
        setClients(rese?.data);
        setCouriers(result?.data);
        const keyData = res?.data;
        setBrandData(keyData);
        setSelectedClients(keyData?.name?._id);
        setSelectedImportStatus(keyData?.import_status);
        setSelectedExportSeaStatus(keyData?.export_sea_status);
        setSelectedDeliveryStatus(keyData?.delivery_status);
        setSelectedExportAirStatus(keyData?.export_air_status);
        setSelectedPAckType(keyData?.packageType);
        setSelectedUnit(keyData?.weight?.unit);
        setSlectedShipment(keyData?.shipmentMethod);
        setConsigneeType(keyData?.consigneeType);
        setSelectedCouriers(keyData?.courierName?._id);
        setDelivery(keyData?.deliveryInfo);
    };
    useEffect(() => {
        getDetails();
    }, [data?.Waybill]);

    console.log(brandData);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            Waybill: brandData?.Waybill ?? "",
            oversizePackage: "",
            name: "",
            consigneeName: brandData?.consigneeName ?? "",
            merchant: brandData?.merchant ?? "",
            description: brandData?.description ?? "",
            weight: brandData?.weight?.number ?? "",
            flightNumber: brandData?.flightNumber ?? "",
            status: "",
            courierName: "",
            shipmentMethod: "",
            packageType: "",
            unit: "",
            price: brandData?.price ?? "",
            shipmentNumber: brandData?.shipmentNumber ?? "",
            numberOfPieces: brandData?.numberOfPieces ?? "",
            consigneeType: brandData?.consigneeType ?? "",
            invoiceImage: "",
            import_status: "",
            export_sea_status: "",
            delivery_status: "",
            export_air_status: "",
            billTo: brandData?.billTo ?? "",
            trackingNumber: brandData?.trackingNumber ?? "",
            deliverInfo: brandData?.deliveryInfo ?? "",
            notes: brandData?.notes ?? "",
        },

        onSubmit: async (data) => {
            data.oversizePackage = over === "true" ? true : false;
            data.consigneeName = data?.consigneeName;
            data.number = data.weight;
            data.unit = selectedUnit;
            data.courierName = selectedCouriers;
            data.shipmentMethod = selectedShipment;
            data.packageType = selectedPackType;
            data.consigneeType = consigneeType;
            data.name = selectClients;
            data.deliverInfo = delivery;
            if (image) {
                data.invoiceImage = image;
            }
            const result = await handlePutRequest(data, "/editPackage");
            if (result?.success) {
                getDetails();
                toast.success("Package edited");
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleCancel = () => {
        history.push("/customers");
    };

    useEffect(() => {
        const role = localStorage.getItem("role");
        setrole(role);
    }, []);

    const uploadImage = async (file) => {
        let name = file.name;
        let filename = name.replace(/\.[^/.]+$/, "") + "-" + Math.floor(Math.random() * 10000000 + 1);
        const storageRef = ref(storage, filename);
        await uploadBytes(storageRef, file).then((snapshot) => {});
        await getDownloadURL(storageRef).then(async (url) => {
            setImage(url);
            const data = {
                inventoryNumber: data?.Waybill,
                invoiceImage: url,
            };
            const result = await handlePutRequest(data, "/editPackage");
            if (result?.success) {
                getDetails();
                toast.success("Package edited");
            }
        });
    };

    const handleImportStatus = async (e) => {
        setSelectedImportStatus(e);

        const data = {
            status: e,
            id: data?.Waybill,
            name: brandData?.name?.firstName + " " + brandData?.name?.lastName,
            deliveryAddress: brandData?.name?.client?.deliveryAddress,
            email: brandData?.name?.email,
        };
        const res = await handlePutRequest(data, "/changeimportstatus");
        if (res?.success) {
            getDetails();
            toast.success("Package edited");
        }
    };

    const handleExportAirStatus = async (e) => {
        setSelectedImportStatus(e);
        const data = {
            status: e,
            id: data?.Waybill,
            name: brandData?.name?.firstName + " " + brandData?.name?.lastName,
            deliveryAddress: brandData?.name?.client?.deliveryAddress,
            email: brandData?.name?.email,
        };
        const res = await handlePutRequest(data, "/changeexportairstatus");
        if (res?.success) {
            getDetails();
            toast.success("Package edited");
        }
    };

    const handleExportSeaStatus = async (e) => {
        setSelectedImportStatus(e);
        const data = {
            status: e,
            id: data?.Waybill,
            name: brandData?.name?.firstName + " " + brandData?.name?.lastName,
            deliveryAddress: brandData?.name?.client?.deliveryAddress,
            email: brandData?.name?.email,
        };
        const res = await handlePutRequest(data, "/changeexportseastatus");
        if (res?.success) {
            getDetails();
            toast.success("Package edited");
        }
    };

    const handledeliveryStatus = async (e) => {
        setSelectedImportStatus(e);
        const data = {
            status: e,
            id: data?.Waybill,
            name: brandData?.name?.firstName + " " + brandData?.name?.lastName,
            deliveryAddress: brandData?.name?.client?.deliveryAddress,
            email: brandData?.name?.email,
        };
        const res = await handlePutRequest(data, "/packageStatusChange");
        if (res?.success) {
            getDetails();
            toast.success("Package edited");
        }
    };
    return (
        <>
            <div className="customer_header__">
                <div className="left___">
                    <h2>{brandData?.title}</h2>
                    <BreadCrumb model={breadItems} home={home} />
                </div>
            </div>
            {role === "client" && (
                <div>
                    <div style={{ marginTop: "10px" }}>
                        <label htmlFor="invoiceImage" className={classNames({ "p-error": isFormFieldValid("invoiceImage") }, "Label__Text")}>
                            Upload invoice Image
                        </label>
                        <InputText id="invoiceImage" type="file" name="invoiceImage" onChange={(e) => uploadImage(e.target.files[0])} className={classNames({ "p-invalid": isFormFieldValid("invoiceImage") }, "Input__Round")} />

                        {getFormErrorMessage("invoiceImage")}
                    </div>
                    {image && (
                        <div>
                            <img src={image} style={{ width: "30%", objectFit: "cover", marginTop: "20px", height: "50%" }}></img>
                        </div>
                    )}
                </div>
            )}
            <div className="customer_details_section">
                <div className="right_section">
                    <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                        <div className="form__">
                            <div className="form_left">
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="Waybill" className={classNames({ "p-error": isFormFieldValid("Waybill") }, "Label__Text")}>
                                        Way Bill
                                    </label>
                                    <InputText disabled id="Waybill" name="Waybill" value={formik.values.Waybill} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("Waybill") }, "Input__Round")} />

                                    {getFormErrorMessage("Waybill")}
                                </div>
                                {window.innerWidth > 768 && <div className="p-field col-12 md:col-12">{formik.values.Waybill && <Barcode value={formik.values.Waybill} height={50} />}</div>}
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="description" className={classNames({ "p-error": isFormFieldValid("description") }, "Label__Text")}>
                                        Description
                                    </label>
                                    <InputText id="description" disabled name="description" value={formik.values.description} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("description") }, "Input__Round")} />

                                    {getFormErrorMessage("description")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="name" className={classNames({ "p-error": isFormFieldValid("name") }, "Label__Text")}>
                                        Bill To
                                    </label>
                                    <select style={{ marginTop: "10px", height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} disabled value={selectClients} onChange={(e) => setSelectedClients(e.target.value)}>
                                        <option disabled selected>
                                            Please select a Name
                                        </option>
                                        {clients?.map((client) => {
                                            return (
                                                <option value={client?._id}>
                                                    {client?.firstName} {client?.lastName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {getFormErrorMessage("name")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="merchant" className={classNames({ "p-error": isFormFieldValid("merchant") }, "Label__Text")}>
                                        Merchant Name
                                    </label>
                                    <InputText type="text" id="merchant" disabled name="merchant" value={formik.values.merchant} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("merchant") }, "Input__RoundFile")} />

                                    {getFormErrorMessage("merchant")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="billTo" className={classNames({ "p-error": isFormFieldValid("billTo") }, "Label__Text")}>
                                        Package Name
                                    </label>
                                    <InputText type="text" id="billTo" disabled name="billTo" value={formik.values.billTo} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("billTo") }, "Input__RoundFile")} />

                                    {getFormErrorMessage("merchant")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="trackingNumber" className={classNames({ "p-error": isFormFieldValid("trackingNumber") }, "Label__Text")}>
                                        Tracking Number
                                    </label>
                                    <InputText type="text" id="trackingNumber" disabled name="trackingNumber" value={formik.values.trackingNumber} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("trackingNumber") }, "Input__RoundFile")} />

                                    {getFormErrorMessage("merchant")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="shipmentMethod" className={classNames({ "p-error": isFormFieldValid("shipmentMethod") }, "Label__Text")}>
                                        Shipment Method
                                    </label>
                                    <select style={{ marginTop: "10px", height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} disabled value={selectedShipment} onChange={(e) => setSlectedShipment(e.target.value)}>
                                        <option disabled selected>
                                            Please select a shipment Method
                                        </option>
                                        <option value="shipment">shipment</option>
                                        <option value="air">Air</option>
                                    </select>

                                    {getFormErrorMessage("shipmentMethod")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="overSizedPackage" className={classNames({ "p-error": isFormFieldValid("overSizedPackage") }, "Label__Text")}>
                                        Oversized Package?
                                    </label>
                                    <select style={{ marginTop: "10px", height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} disabled value={over === true ? true : false} onChange={(e) => setOver(e.target.value)}>
                                        <option disabled selected>
                                            Please select a package condition
                                        </option>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>

                                    {getFormErrorMessage("overSizedPackage")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="numberOfPieces" className={classNames({ "p-error": isFormFieldValid("numberOfPieces") }, "Label__Text")}>
                                        Delivery Information
                                    </label>
                                    <select disabled style={{ height: "40px", border: "1px solid #cecece", borderRadius: "7px" }} value={delivery} onChange={(e) => setDelivery(e.target.value)}>
                                        <option disabled selected>
                                            Please select delivery Information
                                        </option>
                                        <option value="KNUT OCHi">KNUT OCHi</option>
                                        <option value="KNUT DRAX">KNUT DRAX</option>
                                        <option value="KNUT FALM">KNUT FALM</option>
                                        <option value="KNUT ANGE">KNUT ANGE</option>
                                        <option value="KNUT JUNC">KNUT JUNC</option>
                                        <option value="KNUT MAND">KNUT MAND</option>
                                        <option value="KNUT PORTL">KNUT PORTL</option>
                                        <option value="KNUT NEGR">KNUT NEGR</option>
                                        <option value="KNUT MBJ">KNUT MBJ</option>
                                        <option value="KNUT PIER1">KNUT PIER1</option>
                                        <option value="CELINK">CELINK</option>
                                        <option value="IRIE KIDS">IRIE KIDS</option>
                                        <option value="ZAZIO OCHI">ZAZIO OCHI</option>
                                        <option value="HOME">HOME</option>
                                        <option value="WORK">WORK</option>
                                        <option value="KNUT MAY">KNUT MAY</option>
                                    </select>

                                    {getFormErrorMessage("numberOfPieces")}
                                </div>
                            </div>
                            <div className="form_right">
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="weight" className={classNames({ "p-error": isFormFieldValid("weight") }, "Label__Text")}>
                                        Weight of package
                                    </label>
                                    <InputText disabled type="text" id="weight" name="weight" value={formik.values.weight} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("weight") }, "Input__RoundFile")} />

                                    {getFormErrorMessage("weight")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="unit" className={classNames({ "p-error": isFormFieldValid("unit") }, "Label__Text")}>
                                        Unit
                                    </label>
                                    <select style={{ height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} disabled value={selectedUnit} onChange={(e) => setSelectedUnit(e.target.value)}>
                                        <option disabled selected>
                                            Please select a unit
                                        </option>
                                        <option value="lb">Lb</option>
                                        <option value="cu">Cu ft</option>
                                        <option value="kg">Kg</option>
                                    </select>
                                    {getFormErrorMessage("unit")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="consigneeName" disabled className={classNames({ "p-error": isFormFieldValid("consigneeName") }, "Label__Text")}>
                                        Consignee Name
                                    </label>
                                    <InputText type="text" disabled id="consigneeName" name="consigneeName" value={formik.values.consigneeName} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__RoundFile")} />

                                    {getFormErrorMessage("consigneeName")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="status" className={classNames({ "p-error": isFormFieldValid("status") }, "Label__Text")}>
                                        Package Status
                                    </label>
                                    <select disabled style={{ marginTop: "10px", height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} value={selectedDeliveryStatus} onChange={(e) => handledeliveryStatus(e.target.value)}>
                                        <option disabled selected>
                                            Please select a status
                                        </option>
                                        {statusArray?.map((client) => {
                                            return <option value={client}>{client}</option>;
                                        })}
                                    </select>

                                    {getFormErrorMessage("status")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="courierName" className={classNames({ "p-error": isFormFieldValid("courierName") }, "Label__Text")}>
                                        Courier Name
                                    </label>
                                    <select style={{ marginTop: "10px", height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} disabled value={selectedCouriers} onChange={(e) => setSelectedCouriers(e.target.value)}>
                                        <option disabled selected>
                                            Please select a courier
                                        </option>
                                        {couriers?.map((client) => {
                                            return <option value={client?._id}>{client?.courier?.businessName}</option>;
                                        })}
                                    </select>

                                    {getFormErrorMessage("courierName")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="PackageType" className={classNames({ "p-error": isFormFieldValid("PackageType") }, "Label__Text")}>
                                        Package Type
                                    </label>
                                    <select disabled style={{ marginTop: "10px", height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} value={selectedPackType} onChange={(e) => setSelectedPAckType(e.target.value)}>
                                        <option disabled selected>
                                            Please select a package Type
                                        </option>
                                        {PackageTypes?.map((client) => {
                                            return <option value={client}>{client}</option>;
                                        })}
                                    </select>

                                    {getFormErrorMessage("PackageType")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="numberOfPieces" className={classNames({ "p-error": isFormFieldValid("numberOfPieces") }, "Label__Text")}>
                                        Number of pieces
                                    </label>
                                    <InputText id="numberOfPieces" name="numberOfPieces" value={formik.values.numberOfPieces} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("numberOfPieces") }, "Input__Round")} disabled />

                                    {getFormErrorMessage("numberOfPieces")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="price" className={classNames({ "p-error": isFormFieldValid("price") }, "Label__Text")}>
                                        Price
                                    </label>
                                    <InputText disabled id="price" name="price" value={formik.values.price} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("price") }, "Input__Round")} />

                                    {getFormErrorMessage("price")}
                                </div>
                                {selectedShipment === "shipment" ? (
                                    <div style={{ marginTop: "10px" }}>
                                        <label htmlFor="shipmentNumber" className={classNames({ "p-error": isFormFieldValid("shipmentNumber") }, "Label__Text")}>
                                            Shipment number
                                        </label>
                                        <InputText id="shipmentNumber" name="shipmentNumber" value={formik.values.shipmentNumber} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("shipmentNumber") }, "Input__Round")} disabled />

                                        {getFormErrorMessage("shipmentNumber")}
                                    </div>
                                ) : (
                                    <div style={{ marginTop: "10px" }}>
                                        <label htmlFor="flightNumber" className={classNames({ "p-error": isFormFieldValid("flightNumber") }, "Label__Text")}>
                                            Flight Number
                                        </label>
                                        <InputText id="flightNumber" name="flightNumber" value={formik.values.flightNumber} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("flightNumber") }, "Input__Round")} disabled />

                                        {getFormErrorMessage("flightNumber")}
                                    </div>
                                )}
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="price" className={classNames({ "p-error": isFormFieldValid("price") }, "Label__Text")}>
                                        Notes
                                    </label>
                                    <textarea
                                        style={{ height: "100px", border: "1px solid #cecece", borderRadius: "6px" }}
                                        disabled
                                        id="price"
                                        name="price"
                                        value={formik.values.notes}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": isFormFieldValid("price") }, "Input__Round")}
                                    />

                                    {getFormErrorMessage("price")}
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop:"-20px"}}>
                            <label htmlFor="price" className={classNames({ "p-error": isFormFieldValid("price") }, "Label__Text")}>
                                Notes Images
                            </label>
                            <div style={{ display: "flex", columnGap: "20px", justifyContent: "space-around",marginTop:"10px" }}>
                                {brandData?.notesImages?.map((image) => {
                                    return <img src={image} style={{ width: "45%", height: "300px", objectFit: "cover" }} />;
                                })}
                            </div>
                        </div>

                        {/* {role !== "client" && (
                    <div className="Down__Btn">
                        <Button label="Cancel" className="Btn__Transparent" onClick={handleCancel} />
                        <Button label="Update" className="Btn__Dark" type="submit" />
                    </div>
                )} */}
                    </form>
                </div>
            </div>
        </>
    );
}

export default PackageDetail;
