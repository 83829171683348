import React, { useEffect, useMemo, useState } from "react";
import { handleGetRequest } from "../services/GetTemplate";
import { useHistory, useParams } from "react-router-dom";

function Outstanding({ onHideOutStanding, onSuccessOutstanding }) {
    const [brands, setBrands] = useState([]);
    const [userId, setuserId] = useState("");
    const [totalAmount, setTotalAmount] = useState(0);
    const [user, setUser] = useState();

    useEffect(() => {
        const userId = localStorage.getItem("userId");
        const user = localStorage.getItem("user");
        setUser(user);
        setuserId(userId);
    }, []);

    const getBrands = async () => {
        if (userId) {
            const res = await handleGetRequest(`/clientInvoices/${userId}`);
            const unPaidInvoices = res?.data?.filter((item) => item.paymentStatus === "pending");
            if (unPaidInvoices.length === 0) {
                onHideOutStanding();
            }
            const totalAmount = unPaidInvoices?.reduce((acc, item) => acc + item.invoiceAmount, 0);
            setTotalAmount(totalAmount);
            setBrands(unPaidInvoices);
        }
    };

    const history = useHistory();
    const handlePay=()=>{
        const aggregated = {
            MonthlyFee: 0,
            TotalAmount: 0,
            Waybills: "",
            WebsiteFee: 0,
            dangerousItemFee: 0,
            deliveryFee: 0,
            customDuties: 0,
            declarationFee: 0,
            discountedAmount: 0,
            loyaltyDiscount: 0,
            oversizeFee: 0,
            packagingFee: 0,
            pickupFee: 0,
            splitFee: 0,
            weight: 0,
            packages: [],
            clientId: brands[0].clientId,
            clientName: brands[0].clientName,
            email: brands[0].email,
            phone: brands[0].phone,
            customerType: brands[0].customerType,
            date: brands[0].date,
            descriptions: "",
            shipmentNumber: "",
            invoiceAmount: 0,
            paidAmount: 0,
            paymentStatus: brands[0].paymentStatus,
            itemsPrice: 0,
            // Add other necessary fields
        };
        const tosa = brands.forEach((item) => {
            aggregated.MonthlyFee += item.MonthlyFee;
            aggregated.TotalAmount += item.TotalAmount;
            aggregated.WebsiteFee += item.WebsiteFee;
            aggregated.dangerousItemFee += item.dangerousItemFee ?? 0;
            aggregated.deliveryFee += item.deliveryFee;
            aggregated.customDuties += item.customDuties;
            aggregated.declarationFee += item.declarationFee;
            aggregated.discountedAmount += item.discountedAmount;
            aggregated.loyaltyDiscount += item.loyaltyDiscount;
            aggregated.oversizeFee += item.oversizeFee;
            aggregated.packagingFee += item.packagingFee;
            aggregated.pickupFee += item.pickupFee;
            aggregated.splitFee += item.splitFee;
            aggregated.weight += item.weight;
            aggregated.packages = aggregated.packages.concat(item.packages);
            aggregated.descriptions += aggregated.descriptions ? `,${item.descriptions}` : item.descriptions;
            aggregated.shipmentNumber += aggregated.shipmentNumber ? `,${item.shipmentNumber}` : item.shipmentNumber;
            aggregated.Waybills += aggregated.Waybills ? `,${item.Waybills}` : item.Waybills;
            aggregated.invoiceAmount += item.invoiceAmount;
            aggregated.paidAmount += item.paidAmount??0;
            aggregated.itemsPrice += item.itemsPrice??0;
        });
        const sosa = brands.map((item) => {
            return {
                _id: item._id,
                paidAmount: item.invoiceAmount,
            };
        });
        localStorage.setItem("Zazioaggregated", JSON.stringify(aggregated));
        localStorage.setItem("Zaziopaid", JSON.stringify(sosa));
        localStorage.setItem("PaymentUser", "user");
        onHideOutStanding();
        history.push("/bulkInvoicesUser");
    }

    useEffect(() => {
        getBrands();
    }, [userId]);

    const getFirstName = (fullName) => {
        if (!fullName) return '';
        return fullName.split(' ')[0];
      };

    return (
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
            <p style={{ textAlign: "center" }}>Hi {getFirstName(user)}! Thank you for choosing ZAZIO, where we move freight forward.</p>
            <p style={{ textAlign: "center" }}>Please see your total outstanding balance below.</p>
            <div style={{ width: "50%", height: "100px", display: "flex", backgroundColor: "blue", color: "white", flexDirection: "column", borderRadius: "10px", justifyContent: "center", alignItems: "center" }}>
                <p style={{ textAlign: "center", fontSize: "22px" }}>${totalAmount}</p>
                <p style={{ textAlign: "center", fontSize: "18px" }}>Outstanding Balance</p>
            </div>
            <div style={{ marginTop: "40px", display: "flex", columnGap: "20px" }}>
                <button style={{ padding: "10px 50px", backgroundColor: "green", color: "white", border: "none", borderRadius: "6px" }} onClick={handlePay}>Pay Now</button>
                <button style={{ padding: "10px 50px", backgroundColor: "red", color: "white", border: "none", borderRadius: "6px" }} onClick={() => onHideOutStanding()}>
                    Pay Later
                </button>
            </div>
        </div>
    );
}

export default Outstanding;
