import React, { useEffect, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { useHistory, useParams } from "react-router-dom";
import { handleGetRequest } from "../../services/GetTemplate";
import "./label.scss";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Font, Image, PDFDownloadLink } from "@react-pdf/renderer";
import BarcodeFont from "../../assets/fonts/BarcodeFont.ttf";
import JsBarcode from "jsbarcode";

Font.register({
    family: "BarcodeFont",
    src: BarcodeFont,
});

function Label() {
    const componentRef = useRef();
    const breadItems = [{ label: "Home" }, { label: "Label" }];
    const home = { icon: "pi pi-home", url: "/" };
    const [brandData, setBrandData] = useState();

    const history = useHistory();

    const { id } = useParams();

    const getDetails = async () => {
        const res = await handleGetRequest(`/label/${id}`);
        setBrandData(res?.data);
    };
    useEffect(() => {
        getDetails();
    }, [id]);

    const handleCancel = () => {
        history.push("/labels");
    };
    const customPageSize = {
        width: 295, // Width in points
        height: 216, // Height in points
    };

    const styles = StyleSheet.create({
        page: {
            padding: 5,
            // height: "100%",
            margin: 0,
            height: "100%",
        },
        delivery: {
            fontSize: 13,
            marginLeft: 25,
        },
        name: {
            fontSize: 16,
            fontWeight: 600,
        },
        name2: {
            fontSize: 14,
            fontWeight: 600,
        },
    });

    const styless = StyleSheet.create({
        page: {
            flexDirection: "row",
            backgroundColor: "#E4E4E4",
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
    });

    const generateBarcodeElement = (barcodeValue) => {
        let canvas;
        canvas = document.createElement("canvas");
        JsBarcode(canvas, barcodeValue, { format: "CODE128", displayValue: false });
        const barcode = canvas.toDataURL();

        return <Image src={barcode} alt={barcodeValue} style={{ width: "200px", height: "70px" }} />;
    };

    const Capitalize = (str) => {
        let s = str;
        if (s) {
            s = str.toUpperCase();
        }
        return s;
    };

    const content = (
        <Document title={brandData?.flightNumber}>
            <Page size={customPageSize} style={styles.page}>
                <View style={{ height: "40px", borderBottom: "3px solid black", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "0px 15px" }}>
                    <Text style={{ textTransform: "capitalize" }}>{Capitalize(brandData?.courierName)}</Text>
                    <Text style={{ fontWeight: "normal" }}>{brandData?.weight}</Text>
                </View>
                <View style={{ padding: "10px" }}>
                    <Text style={styles.name}>{Capitalize(brandData?.consigneeName)}</Text>
                    <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "10px" }}>
                        <View style={{ width: "55%", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "20px", flexDirection: "column" }}>
                            {generateBarcodeElement(brandData?.trackingNumber)}
                            <Text style={{ fontSize: 12, marginTop: "0px" }}>{brandData?.trackingNumber}</Text>
                        </View>
                        <View style={{ display: "flex", flexDirection: "column", width: "40%", marginLeft: "10px", marginTop: "10px" }}>
                            <Text style={styles.delivery}>{brandData?.shipmentMethod === "air" ? brandData?.flightNumber : brandData?.shipmentNumber}</Text>

                            <Text style={styles.delivery}>{brandData?.deliveryInfo}</Text>
                        </View>
                    </View>
                    <View style={{ display: "flex", justifyContent: "space-between", alignItems: "center", border: "1px solid black", flexDirection: "row", padding: "5px", marginTop: "5px" }}>
                        <Text style={styles.name2}>DESC : {Capitalize(brandData?.description)}</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );

    return (
        <>
            <div className="customer_header__">
                <div className="left___">
                    <h2>{brandData?.title}</h2>
                    <BreadCrumb model={breadItems} home={home} />
                </div>
            </div>
            <div className="customer_header__">
                <PDFDownloadLink document={content} fileName={`${brandData?.flightNumber}.pdf`}>
                    {({ blob, url, loading, error }) => (loading ? "Loading document..." : <button style={{ marginTop: "20px", marginBottom: "20px", padding: "10px 50px", border: "none", backgroundColor: "green", color: "white", cursor: "pointer" }}>Download PDF</button>)}
                </PDFDownloadLink>
            </div>
            <div>
                <PDFViewer width={800} height={1000}>
                    <Document>
                        <Page size={customPageSize} style={styles.page}>
                        <View style={{ height: "40px", borderBottom: "3px solid black", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "0px 15px" }}>
                                <Text style={{ textTransform: "capitalize" }}>{Capitalize(brandData?.courierName)}</Text>
                                <Text style={{ fontWeight: "normal" }}>{brandData?.weight}</Text>
                            </View>
                            <View style={{ padding: "10px" }}>
                                <Text style={styles.name}>{Capitalize(brandData?.consigneeName)}</Text>
                                <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "10px" }}>
                                    <View style={{ width: "55%", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "20px", flexDirection: "column" }}>
                                        {generateBarcodeElement(brandData?.trackingNumber)}
                                        <Text style={{ fontSize: 12, marginTop: "0px" }}>{brandData?.trackingNumber}</Text>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "column", width: "40%", marginLeft: "10px", marginTop: "10px" }}>
                                        <Text style={styles.delivery}>{brandData?.shipmentMethod === "air" ? brandData?.flightNumber : brandData?.shipmentNumber}</Text>

                                        <Text style={styles.delivery}>{brandData?.deliveryInfo}</Text>
                                    </View>
                                </View>
                                <View style={{ display: "flex", justifyContent: "space-between", alignItems: "center", border: "1px solid black", flexDirection: "row", padding: "5px", marginTop: "5px" }}>
                                    <Text style={styles.name2}>DESC : {Capitalize(brandData?.description)}</Text>
                                </View>
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            </div>
        </>
    );
}

export default Label;
