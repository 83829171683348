import React, { useEffect, useState } from "react";
import { handleGetRequest } from "../services/GetTemplate";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../services/PostTemplate";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function AmazonPayButton({ amount, handlePaymentSuccess }) {
    const [signature, setSignature] = useState(null);
    const query = useQuery();
    const sessionId = query.get("amazonCheckoutSessionId");
    const [session, setSession] = useState(null);
    // console.log(signature);

    const environment = "live"; // live or sandbox

    const config = {
        live: {
            checkoutReviewReturnUrl: `https://portal.zazio.co/payment`,
            checkoutReviewReturnUrl1: `https://portal.zazio.co/bulkPayment`,
            storeId: "amzn1.application-oa2-client.beb0fc5f2305418191dc2d333e1bfc6d",
            publicKey: "LIVE-AFRZNXA2E5CNQPTPD53UY2GK",
            merchantId: "A2FJCAPS61F0UL",
        },
        sandbox: {
            checkoutReviewReturnUrl: `http://localhost:3000/payment`,
            checkoutReviewReturnUrl1: `http://localhost:3000/bulkPayment`,
            storeId: "amzn1.application-oa2-client.a4582d27e8ac43ec9dfcab38d9e91e7d",
            publicKey: "SANDBOX-AELLAOMCBQJQAX2JYMWLLKIS",
            merchantId: "A2FJCAPS61F0UL",
        },
    };

    // useEffect(() => {
    //     if (!sessionId) {
    //         localStorage.setItem("chargeAmount", amount);
    //     }
    // }, [amount]);

    // Create a checkout session on component mount
    const currentURL = window.location.href;
    const urlPart = currentURL.split("/").pop().split("?")[0];
    useEffect(() => {
        const createCheckoutSession = async () => {
            try {
                if (urlPart === "bulkPayment") {
                    const url = `/generateSignature?page=bulkPayment`;
                    const sign = await handleGetRequest(url);
                    setSignature(sign.data.signature);
                } else {
                    const url = `/generateSignature`;
                    const sign = await handleGetRequest(url);
                    setSignature(sign.data.signature);
                }
            } catch (error) {
                toast.error("error in");
                console.error("Error creating checkout session:", error);
            }
        };

        createCheckoutSession();
    }, []);

    const dispatch = useDispatch();
    const history = useHistory();

    const handleChargePermission = async (id) => {
        const res = await handleGetRequest(`/chargePermission/${id}`);
        if (res?.data?.statusDetails.state === "Captured") {
            toast.success("Success!!! Thank you for choosing Zazio. Have a wonderful rest of your day! #WeMoveFreightForward");
            const am = localStorage.getItem("chargeAmount");
            handlePaymentSuccess(id, am);
        } else {
            toast.error("We appreciate your effort to make a payment. Please check your card information for errors, or try again in ten minutes.");
            localStorage.removeItem("chargeAmount");
            localStorage.removeItem("sessionId");
        }
    };

    const handleGEtSession = async () => {
        const ses = localStorage.getItem("sessionId");
        if (ses) {
            const am = localStorage.getItem("chargeAmount");
            const dat = {
                checkoutSessionId: sessionId,
                amount: am,
            };
            const res = await dispatch(handlePostRequest(dat, `/completeSession`, false, false));
            if (res === "error") {
                toast.error("We appreciate your effort to make a payment. Please check your card information for errors, or try again in ten minutes.");
                localStorage.removeItem("chargeAmount");
                localStorage.removeItem("sessionId");
                history.push("/payment");
            } else {
                const permissionId = res.data.chargeId;
                await handleChargePermission(permissionId);
            }
            localStorage.removeItem("sessionId");
        } else {
            const am = localStorage.getItem("chargeAmount");
            const dat = {
                checkoutSessionId: sessionId,
                amount: am,
                page: urlPart,
            };
            const res = await dispatch(handlePostRequest(dat, `/updateSession`, false, false));
            if (res === "error") {
                toast.error("We appreciate your effort to make a payment. Please check your card information for errors, or try again in ten minutes.");
                localStorage.removeItem("chargeAmount");
                history.push("/payment");
            } else {
                setSession(res.data.webCheckoutDetails.amazonPayRedirectUrl);
                localStorage.setItem("sessionId", sessionId);
                localStorage.setItem("buyer", res?.data?.buyer?.name);
            }
        }
    };

    useEffect(() => {
        if (sessionId) {
            handleGEtSession();
        }
    }, [sessionId]);

    useEffect(() => {
        if (session) {
            window.location.href = session;
        }
    }, [session]);

    // console.log(config[environment].checkoutReviewReturnUrl);

    const payload = {
        webCheckoutDetails: {
            checkoutReviewReturnUrl: urlPart === "bulkPayment" ? config[environment].checkoutReviewReturnUrl1 : config[environment].checkoutReviewReturnUrl,
        },
        storeId: config[environment].storeId,
        scopes: ["name", "email", "phoneNumber", "billingAddress"],
    };

    useEffect(() => {
        if (signature) {
            window.amazon.Pay.renderButton("#amazonPayButton", {
                merchantId: config[environment].merchantId,
                checkoutLanguage: "en_US",
                ledgerCurrency: "USD",
                productType: "PayOnly",
                placement: "Checkout",
                buttonColor: "Gold",
                createCheckoutSessionConfig: {
                    signature: signature,
                    publicKeyId: config[environment].publicKey,
                    payloadJSON: JSON.stringify(payload),
                    algorithm: "AMZN-PAY-RSASSA-PSS-V2",
                },

                onAuthorize: async (authorization) => {
                    try {
                        console.log(authorization);
                    } catch (error) {
                        console.error("Error during authorization:", error);
                    }
                },
            });
        }
    }, [signature]);
    return <div id="amazonPayButton">AmazonPayButton</div>;
}

export default AmazonPayButton;
