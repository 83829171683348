import React, { useEffect, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { useHistory, useParams } from "react-router-dom";
import { handleGetRequest } from "../../services/GetTemplate";
import { clientType, PackageTypes, status, importStatus, exportAirStatus, exportSeaStatus, deliveryStatus, statusArray } from "../../assets/data/data";
import { handlePutRequest } from "../../services/PutTemplate";
import { toast } from "react-toastify";
import Barcode from "react-barcode";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import Select from "react-select";

function Package() {
    const breadItems = [{ label: "Home" }, { label: "package" }];
    const home = { icon: "pi pi-home", url: "/" };
    const [brandData, setBrandData] = useState();
    const [clients, setClients] = useState([]);
    const [selectClients, setSelectedClients] = useState();
    const [couriers, setCouriers] = useState([]);
    const [selectedCouriers, setSelectedCouriers] = useState();
    const [selectedImportStatus, setSelectedImportStatus] = useState();
    const [selectedPackType, setSelectedPAckType] = useState();
    const [selectedDeliveryStatus, setSelectedDeliveryStatus] = useState();
    const [selectedUnit, setSelectedUnit] = useState();
    const [selectedShipment, setSlectedShipment] = useState();
    const [consigneeType, setConsigneeType] = useState(null);
    const [image, setImage] = useState();
    const [over, setOver] = useState();
    const history = useHistory();
    const { id } = useParams();
    const [role, setrole] = useState("");
    const [delivery, setDelivery] = useState();
    const [images, setImages] = useState([]);
    const [DeliveryInfos, setDeliveryInfos] = useState([]);
    const [packageStatus, setPackageStatus] = useState([]);




    const getDetails = async () => {
        const res = await handleGetRequest(`/package/${id}`);
        const rese = await handleGetRequest("/allUsers");
        const result = await handleGetRequest("/userRole?role=courier");
        const res2 = await handleGetRequest(`/getAllDeliveryInfo`);
        const resa = await handleGetRequest(`/getAllPackageStatus`);
        setPackageStatus(resa?.data);
        setDeliveryInfos(res2?.data);
        let df=[];
        let dt;
        dt=rese?.data?.forEach((val) => {
            df.push({
                label: val?.firstName + " " + val?.lastName,
                value: val?._id,
            })
            return null
        })
        setClients(df);
        setCouriers(result?.data);
        const keyData = res?.data;
        setBrandData(keyData);
        setSelectedClients({
            label: keyData?.name?.firstName + " " + keyData?.name?.lastName,
            value: keyData?.name?._id,
        });
        setSelectedDeliveryStatus(keyData?.packageStatus);
        setSelectedPAckType(keyData?.packageType);
        setSelectedUnit(keyData?.weight?.unit);
        setSlectedShipment(keyData?.shipmentMethod);
        setConsigneeType(keyData?.consigneeType);
        setSelectedCouriers(keyData?.courierName?._id);
        setDelivery(keyData?.deliveryInfo);
        setImages(keyData?.notesImages);
    };
    useEffect(() => {
        getDetails();
    }, [id]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            Waybill: brandData?.Waybill ?? "",
            oversizePackage: "",
            name: "",
            consigneeName: brandData?.consigneeName ?? "",
            merchant: brandData?.merchant ?? "",
            description: brandData?.description ?? "",
            weight: brandData?.weight?.number ?? "",
            flightNumber: brandData?.flightNumber ?? "",
            status: "",
            courierName: "",
            shipmentMethod: "",
            packageType: "",
            unit: "",
            price: brandData?.price ?? "",
            shipmentNumber: brandData?.shipmentNumber ?? "",
            numberOfPieces: brandData?.numberOfPieces ?? "",
            consigneeType: brandData?.consigneeType ?? "",
            invoiceImage: "",
            import_status: "",
            export_sea_status: "",
            delivery_status: "",
            export_air_status: "",
            billTo: brandData?.billTo ?? "",
            trackingNumber: brandData?.trackingNumber ?? "",
            deliveryInfo: brandData?.deliveryInfo ?? "",
            notes: brandData?.notes ?? "",
            nameOfUser: "",
            courierNameOfUser: "",
            notesImages:[],
        },

        onSubmit: async (data) => {
            data.oversizePackage = over === "true" ? true : false;
            data.consigneeName = data?.consigneeName;
            data.number = data.weight;
            data.unit = selectedUnit;
            data.courierName = selectedCouriers;
            const temp1 = couriers?.filter((val) => val?._id === selectedCouriers);
            data.courierNameOfUser = temp1[0]?.courier?.businessName;
            data.shipmentMethod = selectedShipment;
            data.packageType = selectedPackType;
            data.consigneeType = consigneeType;
            data.name = selectClients?.value;
            const temp = clients?.filter((val) => val?._id === selectClients?.value);
            data.nameOfUser = temp[0]?.firstName + " " + temp[0]?.lastName;
            data.deliveryInfo = delivery;
            data.notesImages = images;
            if (image) {
                data.invoiceImage = image;
            }
            const result = await handlePutRequest(data, "/editPackage");
            if (result?.success) {
                getDetails();
                toast.success("Package edited");
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleCancel = () => {
        history.push("/customers");
    };

    useEffect(() => {
        const role = localStorage.getItem("role");
        setrole(role);
    }, []);

    const uploadImage = async (file) => {
        let name = file.name;
        let filename = name.replace(/\.[^/.]+$/, "") + "-" + Math.floor(Math.random() * 10000000 + 1);
        const storageRef = ref(storage, filename);
        await uploadBytes(storageRef, file).then((snapshot) => {});
        await getDownloadURL(storageRef).then(async (url) => {
            setImage(url);
            const data = {
                inventoryNumber: id,
                invoiceImage: url,
            };
            const result = await handlePutRequest(data, "/editPackage");
            if (result?.success) {
                getDetails();
                toast.success("Package edited");
            }
        });
    };

    const handledeliveryStatus = async (e) => {
        setSelectedImportStatus(e);
        if (brandData?.consigneeType === "client") {
            const data = {
                status: e,
                id,
                name: brandData?.name?.firstName + " " + brandData?.name?.lastName,
                deliveryAddress: brandData?.name?.client?.deliveryAddress,
                email: brandData?.name?.email,
            };
            const res = await handlePutRequest(data, "/packageStatusChange");
            if (res?.success) {
                getDetails();
                toast.success("Package edited");
            }
        } else {
            const data = {
                status: e,
                id,
                name: brandData?.courierName?.courier?.businessName,
                email: brandData?.courierName?.email,
            };
            const res = await handlePutRequest(data, "/packageStatusChange");
            if (res?.success) {
                getDetails();
                toast.success("Package edited");
            }
        }
    };

    const handleRemove = (index) => {
        let temp = images;
        temp.splice(index, 1);
        setImages([...temp]);
    }

    console.log(brandData);

    return (
        <>
            <div className="customer_header__">
                <div className="left___">
                    <h2>{brandData?.title}</h2>
                    <BreadCrumb model={breadItems} home={home} />
                </div>
            </div>
            {role === "client" && (
                <div>
                    <div style={{ marginTop: "10px" }}>
                        <label htmlFor="invoiceImage" className={classNames({ "p-error": isFormFieldValid("invoiceImage") }, "Label__Text")}>
                            Upload invoice Image
                        </label>
                        <InputText id="invoiceImage" type="file" name="invoiceImage" onChange={(e) => uploadImage(e.target.files[0])} className={classNames({ "p-invalid": isFormFieldValid("invoiceImage") }, "Input__Round")} />

                        {getFormErrorMessage("invoiceImage")}
                    </div>
                    {image && (
                        <div>
                            <img src={image} style={{ width: "30%", objectFit: "cover", marginTop: "20px", height: "50%" }}></img>
                        </div>
                    )}
                </div>
            )}
            <div className="customer_details_section">
                <div className="right_section">
                    <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                        <div className="form__">
                            <div className="form_left">
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="Waybill" className={classNames({ "p-error": isFormFieldValid("Waybill") }, "Label__Text")}>
                                        Way Bill
                                    </label>
                                    <InputText disabled id="Waybill" name="Waybill" value={formik.values.Waybill} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("Waybill") }, "Input__Round")} />

                                    {getFormErrorMessage("Waybill")}
                                </div>
                                {window.innerWidth > 768 && <div className="p-field col-12 md:col-12">{formik.values.Waybill && <Barcode value={formik.values.Waybill} height={50} />}</div>}
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="description" className={classNames({ "p-error": isFormFieldValid("description") }, "Label__Text")}>
                                        Description
                                    </label>
                                    <InputText
                                        id="description"
                                        disabled={role === "admin" || role === "clerk" ? false : true}
                                        name="description"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": isFormFieldValid("description") }, "Input__Round")}
                                    />

                                    {getFormErrorMessage("description")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="name" className={classNames({ "p-error": isFormFieldValid("name") }, "Label__Text")}>
                                        Bill To
                                    </label>
                                    <Select options={clients} value={selectClients} onChange={setSelectedClients}></Select>
                                    {getFormErrorMessage("name")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="merchant" className={classNames({ "p-error": isFormFieldValid("merchant") }, "Label__Text")}>
                                        Merchant Name
                                    </label>
                                    <InputText
                                        type="text"
                                        id="merchant"
                                        disabled={role === "admin" || role === "clerk" ? false : true}
                                        name="merchant"
                                        value={formik.values.merchant}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": isFormFieldValid("merchant") }, "Input__RoundFile")}
                                    />

                                    {getFormErrorMessage("merchant")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="billTo" className={classNames({ "p-error": isFormFieldValid("billTo") }, "Label__Text")}>
                                        Package Name
                                    </label>
                                    <InputText type="text" id="billTo" disabled={role === "admin" || role === "clerk" ? false : true} name="billTo" value={formik.values.billTo} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("billTo") }, "Input__RoundFile")} />

                                    {getFormErrorMessage("merchant")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="trackingNumber" className={classNames({ "p-error": isFormFieldValid("trackingNumber") }, "Label__Text")}>
                                        Tracking Number
                                    </label>
                                    <InputText
                                        type="text"
                                        id="trackingNumber"
                                        disabled={role === "admin" || role === "clerk" ? false : true}
                                        name="trackingNumber"
                                        value={formik.values.trackingNumber}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": isFormFieldValid("trackingNumber") }, "Input__RoundFile")}
                                    />

                                    {getFormErrorMessage("merchant")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="shipmentMethod" className={classNames({ "p-error": isFormFieldValid("shipmentMethod") }, "Label__Text")}>
                                        Shipment Method
                                    </label>
                                    <select style={{ marginTop: "10px", height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} disabled={role === "admin" || role === "clerk" ? false : true} value={selectedShipment} onChange={(e) => setSlectedShipment(e.target.value)}>
                                        <option disabled selected>
                                            Please select a shipment Method
                                        </option>
                                        <option value="shipment">shipment</option>
                                        <option value="air">Air</option>
                                    </select>

                                    {getFormErrorMessage("shipmentMethod")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="shipmentMethod" className={classNames({ "p-error": isFormFieldValid("shipmentMethod") }, "Label__Text")}>
                                        Consignee Type
                                    </label>
                                    <select style={{ marginTop: "10px", height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} disabled={role === "admin" || role === "clerk" ? false : true} value={consigneeType} onChange={(e) => setConsigneeType(e.target.value)}>
                                        <option disabled selected>
                                            Please select a consignee Type
                                        </option>
                                        <option value="client">Client</option>
                                        <option value="courier">Courier</option>
                                        <option value="corporate">Corporate</option>
                                        <option value="reseller">Reseller</option>
                                    </select>

                                    {getFormErrorMessage("shipmentMethod")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="overSizedPackage" className={classNames({ "p-error": isFormFieldValid("overSizedPackage") }, "Label__Text")}>
                                        Oversized Package?
                                    </label>
                                    <select style={{ marginTop: "10px", height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} disabled={role === "admin" || role === "clerk" ? false : true} value={over === true ? true : false} onChange={(e) => setOver(e.target.value)}>
                                        <option disabled selected>
                                            Please select a package condition
                                        </option>
                                        <option value={true}>Yes</option>
                                        <option value={false}>No</option>
                                    </select>

                                    {getFormErrorMessage("overSizedPackage")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="numberOfPieces" className={classNames({ "p-error": isFormFieldValid("numberOfPieces") }, "Label__Text")}>
                                        Delivery Information
                                    </label>
                                    <select style={{ height: "40px", border: "1px solid #cecece", borderRadius: "7px" }} value={delivery} onChange={(e) => setDelivery(e.target.value)}>
                                        <option disabled selected>
                                            Please select delivery Information
                                        </option>
                                        {DeliveryInfos?.map((client) => {
                                            return(
                                                <option value={client?.name}>
                                                    {client?.name}
                                                </option>
                                            )
                                        })}
                                    </select>

                                    {getFormErrorMessage("numberOfPieces")}
                                </div>
                            </div>
                            <div className="form_right">
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="weight" className={classNames({ "p-error": isFormFieldValid("weight") }, "Label__Text")}>
                                        Weight of package
                                    </label>
                                    <InputText disabled={role === "admin" || role === "clerk" ? false : true} type="text" id="weight" name="weight" value={formik.values.weight} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("weight") }, "Input__RoundFile")} />

                                    {getFormErrorMessage("weight")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="unit" className={classNames({ "p-error": isFormFieldValid("unit") }, "Label__Text")}>
                                        Unit
                                    </label>
                                    <select style={{ height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} disabled={role === "admin" || role === "clerk" ? false : true} value={selectedUnit} onChange={(e) => setSelectedUnit(e.target.value)}>
                                        <option disabled selected>
                                            Please select a unit
                                        </option>
                                        <option value="lb">Lb</option>
                                        <option value="cu">Cu ft</option>
                                        <option value="kg">Kg</option>
                                    </select>
                                    {getFormErrorMessage("unit")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="consigneeName" disabled={role === "admin" || role === "clerk" ? false : true} className={classNames({ "p-error": isFormFieldValid("consigneeName") }, "Label__Text")}>
                                        Consignee Name
                                    </label>
                                    <InputText
                                        type="text"
                                        disabled={role === "admin" || role === "clerk" ? false : true}
                                        id="consigneeName"
                                        name="consigneeName"
                                        value={formik.values.consigneeName}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__RoundFile")}
                                    />

                                    {getFormErrorMessage("consigneeName")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="status" className={classNames({ "p-error": isFormFieldValid("status") }, "Label__Text")}>
                                        Package Status
                                    </label>
                                    <select style={{ marginTop: "10px", height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} value={selectedDeliveryStatus} onChange={(e) => handledeliveryStatus(e.target.value)}>
                                        <option disabled selected>
                                            Please select a status
                                        </option>
                                        {packageStatus?.map((client) => {
                                            return <option value={client?.name}>{client?.name}</option>;
                                        })}
                                    </select>

                                    {getFormErrorMessage("status")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="courierName" className={classNames({ "p-error": isFormFieldValid("courierName") }, "Label__Text")}>
                                        Courier Name
                                    </label>
                                    <select style={{ marginTop: "10px", height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} disabled={role === "admin" || role === "clerk" ? false : true} value={selectedCouriers} onChange={(e) => setSelectedCouriers(e.target.value)}>
                                        <option disabled selected>
                                            Please select a courier
                                        </option>
                                        {couriers?.map((client) => {
                                            return <option value={client?._id}>{client?.courier?.businessName}</option>;
                                        })}
                                    </select>

                                    {getFormErrorMessage("courierName")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="PackageType" className={classNames({ "p-error": isFormFieldValid("PackageType") }, "Label__Text")}>
                                        Package Type
                                    </label>
                                    <select style={{ marginTop: "10px", height: "34px", border: "1px solid #cecece", borderRadius: "7px" }} disabled={role === "admin" || role === "clerk" ? false : true} value={selectedPackType} onChange={(e) => setSelectedPAckType(e.target.value)}>
                                        <option disabled selected>
                                            Please select a package Type
                                        </option>
                                        {PackageTypes?.map((client) => {
                                            return <option value={client}>{client}</option>;
                                        })}
                                    </select>

                                    {getFormErrorMessage("PackageType")}
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="numberOfPieces" className={classNames({ "p-error": isFormFieldValid("numberOfPieces") }, "Label__Text")}>
                                        Number of pieces
                                    </label>
                                    <InputText
                                        disabled={role === "admin" || role === "clerk" ? false : true}
                                        id="numberOfPieces"
                                        name="numberOfPieces"
                                        value={formik.values.numberOfPieces}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": isFormFieldValid("numberOfPieces") }, "Input__Round")}
                                    />

                                    {getFormErrorMessage("numberOfPieces")}
                                </div>
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="price" className={classNames({ "p-error": isFormFieldValid("price") }, "Label__Text")}>
                                        Price
                                    </label>
                                    <InputText disabled={role === "admin" || role === "clerk" ? false : true} id="price" name="price" value={formik.values.price} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("price") }, "Input__Round")} />

                                    {getFormErrorMessage("price")}
                                </div>
                                {selectedShipment === "shipment" ? (
                                    <div style={{ marginTop: "10px" }}>
                                        <label htmlFor="shipmentNumber" className={classNames({ "p-error": isFormFieldValid("shipmentNumber") }, "Label__Text")}>
                                            Shipment number
                                        </label>
                                        <InputText
                                            disabled={role === "admin" || role === "clerk" ? false : true}
                                            id="shipmentNumber"
                                            name="shipmentNumber"
                                            value={formik.values.shipmentNumber}
                                            onChange={formik.handleChange}
                                            className={classNames({ "p-invalid": isFormFieldValid("shipmentNumber") }, "Input__Round")}
                                        />

                                        {getFormErrorMessage("shipmentNumber")}
                                    </div>
                                ) : (
                                    <div style={{ marginTop: "10px" }}>
                                        <label htmlFor="flightNumber" className={classNames({ "p-error": isFormFieldValid("flightNumber") }, "Label__Text")}>
                                            Flight Number
                                        </label>
                                        <InputText
                                            disabled={role === "admin" || role === "clerk" ? false : true}
                                            id="flightNumber"
                                            name="flightNumber"
                                            value={formik.values.flightNumber}
                                            onChange={formik.handleChange}
                                            className={classNames({ "p-invalid": isFormFieldValid("flightNumber") }, "Input__Round")}
                                        />

                                        {getFormErrorMessage("flightNumber")}
                                    </div>
                                )}
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="notes" className={classNames({ "p-error": isFormFieldValid("notes") }, "Label__Text")}>
                                        Notes
                                    </label>
                                    <textarea
                                        style={{ height: "100px", border: "1px solid #cecece", borderRadius: "6px" }}
                                        disabled={role === "admin" || role === "clerk" ? false : true}
                                        id="notes"
                                        name="notes"
                                        value={formik.values.notes}
                                        onChange={formik.handleChange}
                                        className={classNames({ "p-invalid": isFormFieldValid("notes") }, "Input__Round")}
                                    />

                                    {getFormErrorMessage("notes")}
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: "-20px", marginBottom: "20px" }}>
                            <label htmlFor="price" className={classNames({ "p-error": isFormFieldValid("price") }, "Label__Text")}>
                                Notes Images
                            </label>
                            <div style={{ display: "flex", columnGap: "20px", justifyContent: "space-around", marginTop: "10px" }}>
                                {images?.map((image,index) => {
                                    return (
                                        <div style={{ position: "relative",width:"45%" }}>
                                            <p style={{ position: "absolute", marginLeft: "97%", color: "red", fontSize: "20px", cursor: "pointer" }} onClick={() => handleRemove(index)}>
                                                x
                                            </p>
                                            <img src={image} style={{ width: "100%", height: "300px", objectFit: "cover" }} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {role !== "client" && (
                            <div className="Down__Btn">
                                <Button label="Cancel" className="Btn__Transparent" onClick={handleCancel} />
                                <Button label="Update" className="Btn__Dark" type="submit" />
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
}

export default Package;
