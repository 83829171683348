import React, { useEffect, useMemo, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { toast } from "react-toastify";
import Axios from "axios";
import { DEV } from "../../services/constants";
import { Dialog } from "primereact/dialog";
import SofStop from "../../modals/SofStop";

function PendingPackage() {
    const [selectedRow, setselectedRow] = useState([]);
    const [brands, setBrands] = useState([]);
    const [skip, setSkip] = useState(0);
    const [role, setrole] = useState("");
    const dispatch = useDispatch();
    const [shipment, setShipment] = useState([]);
    const [shipmentNumber, setShipmentNumber] = useState("");
    const [forXL, setForXL] = useState([]);
    const [searchedBrands, setSearchedBrands] = useState([]);

    const breadItems = [{ label: "Home", url: "/" }, { label: "Packages" }];

    const home = { icon: "pi pi-home", url: "/" };
    const history = useHistory();

    useEffect(() => {
        if (selectedRow.length > 0) {
            const temp = selectedRow.map((val) => {
                const data = {
                    id: val?.Waybill,
                    name: val?.name?.firstName + " " + val?.name?.lastName,
                    deliveryAddress: val?.name?.client?.deliveryAddress,
                    email: val?.name?.email,
                };
                return data;
            });
            setShipment(temp);
        }
    }, [selectedRow]);

    const getBrands = async () => {
        let res;
        res = await handleGetRequest(`/pendingPackages`);
        setBrands(res?.data);
        setSearchedBrands(res?.data);
    };
    useMemo(() => {
        if (role) {
            getBrands();
        }
    }, [skip, role]);

    useEffect(() => {
        const role = localStorage.getItem("role");
        const userId = localStorage.getItem("userId");
        setrole(role);
    }, []);

    const [del, setDel] = useState();

    const handles = async () => {
        if (del) {
            const data = {
                id: [del?.Waybill],
            };
            const res = await dispatch(handlePostRequest(data, "/deletePackage", true, true));
            console.log(res);
            if (res?.success) {
                setDel();
                getBrands();
                toast.success("packages deleted");
                window.location.reload();
            }
        }
    };

    const lbToKg = (pounds) => {
        // 1 lb is approximately equal to 0.45359237 kg
        const kilograms = pounds * 0.45359237;
        return kilograms;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div style={{ display: "flex" }}>
                <Button icon="pi pi-ellipsis-v" className="p-button-rounded mr-2 Elipse_Icon" onClick={(e) => handleActionButton(e, rowData)} aria-controls="popup_menu" aria-haspopup />
            </div>
        );
    };
    const handleActionButton = (e, rowdata) => {
        e.preventDefault();
        history.push(`package/${rowdata?.Waybill}`);
    };

    const handledDelete = async () => {
        if (selectedRow?.length > 0) {
            setShowSoftStop(true);
        } else {
            toast.error("Please select atleast one package");
        }
    };

    const [values, setValues] = useState({
        Waybill: "",
        name: "",
        status: "",
        flightNumber: "",
        merchant: "",
        date: "",
        consigneeName: "",
        trackingNumber: "",
        billTo: "",
        deliveryInfo: "",
        bill: "",
        weight: "",
        packageStatus: "",
    });

    const temporary = ["Waybill", "consigneeName", "status", "merchant", "flightNumber", "date", "trackingNumber", "billTo", "deliveryInfo", "bill", "weight", "packageStatus"];

    const handleApplyFilter = async (value, names) => {
        if (names === "flightNumber") {
            setShipmentNumber(value);
        }
        const temp = values;
        temporary.forEach((item) => {
            if (item !== names) {
                temp[item] = "";
            }
        });
        setValues(temp);
        setValues({ ...values, [names]: value });
        const result = await Axios.get(DEV + "/searchPendingPackages", {
            params: {
                [names]: value,
            },
        });
        setSearchedBrands(result?.data?.data);
    };

    const handleFilter = (name) => {
        return <input style={{ width: "100%", height: "37px", borderRadius: "5px", border: "none" }} value={values[name]} onChange={(e) => handleApplyFilter(e.target.value, name)}></input>;
    };

    const nameTemplate = (rowdata) => {
        return (
            <p>
                {rowdata?.name?.firstName} {rowdata?.name?.lastName}
            </p>
        );
    };
    const CnameTemplate = (rowdata) => {
        return <p>{rowdata?.courierName?.courier?.businessName}</p>;
    };
    const weightTemplate = (rowdata) => {
        return (
            <p>
                {rowdata?.weight.number} {rowdata?.weight.unit}
            </p>
        );
    };

    const shipmentTemplate = (rowdata) => {
        return <p>{rowdata?.shipmentMethod === "air" ? rowdata?.flightNumber : rowdata?.shipmentNumber}</p>;
    };

    useEffect(() => {
        const dat = brands;
        const temp = dat.map((val) => {
            return {
                Waybill: val?.Waybill,
                CONSIGNEE: val?.consigneeName,
                SHIPPER: val?.merchant,
                DESCRIPTION: val?.description,
                WT: lbToKg(val?.weight?.number),
                PCS: val?.numberOfPieces,
            };
        });
        setForXL(temp);
    }, [brands]);

    // for uploading the image

    const handleApplyFilters = async (value, names) => {
        if (names === "flightNumber") {
            setShipmentNumber(value);
        }
        const temp = values;
        temporary.forEach((item) => {
            if (item !== names) {
                temp[item] = "";
            }
        });
        setValues(temp);
        setValues({ ...values, [names]: value });
        if (names === "bill") {
            const result = brands.filter((val) => {
                const regex = new RegExp(value, "i");
                const name = val?.name?.firstName + " " + val?.name?.lastName;
                return regex.test(name);
            });
            setSearchedBrands(result);
        } else if (names === "weight") {
            const result = brands.filter((val) => {
                const weight = val?.weight?.number;
                return weight == value;
            });
            if (result.length === 0) {
                setSearchedBrands(brands);
            } else {
                setSearchedBrands(result);
            }
        } else {
            setSearchedBrands(brands);
        }
    };

    const handleClientSearch = (name) => {
        return <input style={{ width: "100%", height: "37px", borderRadius: "5px", border: "none" }} value={values[name]} onChange={(e) => handleApplyFilters(e.target.value, name)}></input>;
    };

    const [showSoftStop, setShowSoftStop] = useState(false);

    const onHideSoftStop = () => {
        setShowSoftStop(false);
    };

    const onSuccessSoftStop = async () => {
        setShowSoftStop(false);
        handles();
        if (selectedRow?.length > 0) {
            const selectedIds = selectedRow.map((val) => {
                return val?.Waybill;
            });
            const data = {
                id: selectedIds,
            };
            const res = await dispatch(handlePostRequest(data, "/deletePendingPackage", true, true));
            if (res?.success) {
                getBrands();
                toast.success("packages deleted");
                window.location.reload();
            }
        }
    };


    const hanldeRecover=async()=>{
        if (selectedRow?.length > 0) {
            const selectedIds = selectedRow.map((val) => {
                return val?.Waybill;
            });
            const data = {
                id: selectedIds,
            };
            const res = await dispatch(handlePostRequest(data, "/recoverPackages", true, true));
            if (res?.success) {
                getBrands();
                toast.success("packages recovered");
                window.location.reload();
            }
        }
        else{
            toast.error("Please select atleast one package");
        }
    }
    return (
        <>
            <Dialog visible={showSoftStop} style={{ width: "650px" }} onHide={() => setShowSoftStop(false)}>
                <SofStop onHideSoftStop={onHideSoftStop} onSuccessSoftStop={onSuccessSoftStop} type="package"></SofStop>
            </Dialog>

            <div className="Page__Header">
                <div>
                    <h2>Packages</h2>
                    <BreadCrumb model={breadItems} home={home} />
                </div>
                {role == "admin" && (
                    <div className="Top__Btn">
                        <Button icon="pi pi-trash" iconPos="right" onClick={handledDelete} className="Btn__DarkDelete" style={{ width: "240px" }} />
                        <Button icon="pi pi-sync" iconPos="right" onClick={hanldeRecover} className="Btn__Blue" style={{ width: "240px" }} />
                    </div>
                )}
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            filterDisplay="row"
                            paginator
                            rows={100}
                            rowsPerPageOptions={[100, 250, 500]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={searchedBrands}
                            selection={selectedRow}
                            onSelectionChange={(e) => setselectedRow(e.value)}
                            sortMode="multiple"
                        >
                            <Column selectionMode="multiple" style={{ width: "3em" }} />
                            <Column filter field="Waybill" header="Way Bill" filterElement={() => handleFilter("Waybill")} sortable sortField="Waybill" />
                            <Column filter field="billTo" header="Package Name" filterElement={() => handleFilter("billTo")} sortable />
                            {role !== "client" && <Column filter body={nameTemplate} header="Bill To" filterElement={() => handleClientSearch("bill")} />}
                            {role === "admin" && <Column filter field="consigneeName" header="Consignee Name" filterElement={() => handleFilter("consigneeName")} sortable />}
                            <Column filter body={shipmentTemplate} header="Shipment Number" filterElement={() => handleFilter("flightNumber")} sortable sortField="flightNumber" />
                            {role !== "client" && <Column filter body={CnameTemplate} header="Courier Name" filterElement={() => handleFilter("courierName")} />}
                            {role === "admin" && <Column filter field="merchant" header="Merchant" filterElement={() => handleFilter("merchant")} />}
                            <Column filter field="trackingNumber" header="Tracking Number" filterElement={() => handleFilter("trackingNumber")} />
                            <Column filter field="deliveryInfo" header="Delivery Information" filterElement={() => handleFilter("deliveryInfo")} />
                            {role === "admin" && <Column filter field="packageStatus" header="Package Status" filterElement={() => handleFilter("packageStatus")} />}
                            <Column filter body={weightTemplate} header="Weight of package" filterElement={() => handleClientSearch("weight")} />

                            <Column filter header="Created On" field="date" filterElement={() => handleFilter("date")} />
                            {role === "admin" && <Column header="Action" body={actionBodyTemplate} />}
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PendingPackage;
