import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { handleGetRequest } from "../../services/GetTemplate";
import moment from "moment";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { toast } from "react-toastify";
import Axios from "axios";
import { DEV } from "../../services/constants";
import "./deliveries.scss";
import SignatureCanvas from "react-signature-canvas";
import ReactToPrint from "react-to-print";
import ReactToPdf from "react-to-pdf";


function GenerateInvoice() {
    const history = useHistory();
    const signref = useRef();
    const [selectedRow, setselectedRow] = useState([]);
    const [finalQuantity, setFinalQuantity] = useState(0);
    const [shipmentNumber, setShipmentNumber] = useState();
    const [brands, setBrands] = useState([]);
    const [totalBrands, setTotalBrands] = useState();
    const dispatch = useDispatch();
    const [next, setNext] = useState(0);
    const [image, setImage] = useState("");
    const [clientsInvoice, setClientsInvoice] = useState([]);
    const [courierInvoice, setCourierInvoice] = useState([]);
    const [searchType, setSearchType] = useState(null);
    const [client, setClient] = useState();
    const [courier, setCourier] = useState();
    const [filtered, setFiltered] = useState([]);
    const [filteredTotal, setFilteredTotal] = useState();
    const [clients, setClients] = useState([]);
    const [couriers, setCouriers] = useState([]);


    const getBrands = async () => {
        const res = await handleGetRequest("/allPackages");
        setTotalBrands(res?.data);
    };
    useMemo(() => {
        getBrands();
    }, []);

    const habndleTirm = () => {
        signref.current.clear();
    };

    const getDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        setFinalQuantity(selectedRow.length);
    }, [selectedRow]);

    const handleFilter = () => {
        let final;
        if (shipmentNumber.startsWith("F")) {
            const filtered = totalBrands?.filter((val) => {
                return val?.flightNumber === shipmentNumber;
            });
            final = filtered;
        } else if (shipmentNumber.startsWith("S")) {
            const filtered = totalBrands?.filter((val) => {
                return val?.shipmentNumber === shipmentNumber;
            });
            final = filtered;
        }
        if (final?.length > 0) {
            CalculateInvoice(final);
        }
    };

    const getIds = () => {
        const clientPackages = [];
        const courierPackages = [];
        const clients = clientsInvoice?.map((val) => {
            const c = val?.packages?.map((pack) => {
                clientPackages.push({
                    package: pack?._id,
                });
            });
        });
        return [...clientPackages];
    };

    useEffect(() => {
        if (shipmentNumber) {
            handleFilter();
        }
    }, [shipmentNumber]);

    const handleSubmit = async () => {
        setImage(signref.current.getTrimmedCanvas().toDataURL("image/png"));
        const data = {
            invoiceNumber: Math.floor(Math.random() * 1000000000),
            invoiceDate: moment().format("DD-MM-YYYY"),
            packages: getIds(),
            signature: signref.current.getTrimmedCanvas().toDataURL("image/png"),
            totalAmount: client ? filteredTotal : finalQuantity,
            shipmentNumber: shipmentNumber,
            date: moment().format("DD-MM-YYYY"),
        };
        const result = await dispatch(handlePostRequest(data, "/addInvoice", true, true));
        if (result !== "error") {
            setNext(1);
        }
        console.log(data);
    };

    const claclulateTotalPrice = () => {
        const total1 = clientsInvoice?.reduce((acc, val) => {
            return acc + parseFloat(calculateClientPrice(val?.weight));
        }, 0);
        const total2 = courierInvoice?.reduce((acc, val) => {
            return acc + parseFloat(calculateCourierPrice(val?.weight));
        }, 0);
        return total1 + total2;
    };

    const calculateClientPrice = (weight) => {
        const firstPoundPrice = 8.5; // Price for the first pound
        const remainingPoundsPrice = 3; // Price for the remaining pounds

        if (weight <= 1) {
            return firstPoundPrice.toFixed(2); // If weight is 1 pound or less, return the price for the first pound
        } else {
            const remainingWeight = weight - 1; // Calculate the weight of the remaining pounds
            const remainingPrice = remainingWeight * remainingPoundsPrice; // Calculate the price for the remaining pounds
            let fP = firstPoundPrice + remainingPrice;
            return fP.toFixed(2); // Return the total price
        }
    };

    const calculateCourierPrice = (weight) => {
        return weight * 2.66;
    };

    const CalculateInvoice = (brands) => {
        const groupedPackages = brands.reduce((groups, pkg) => {
            const { clientId, clientName, ...packageData } = pkg;

            const existingGroup = groups.find((group) => group?.clientName === pkg?.name?.firstName + " " + pkg?.name?.lastName);
            if (existingGroup) {
                existingGroup.packages.push(packageData);
                existingGroup.weight += pkg?.weight?.number;
                existingGroup.description += ", " + pkg?.description;
            } else {
                groups.push({
                    clientId: pkg?.name?._id,
                    clientName: pkg?.name?.firstName + " " + pkg?.name?.lastName,
                    type: "client",
                    packages: [packageData],
                    weight: pkg?.weight?.number,
                    description: pkg?.description,
                });
            }

            return groups;
        }, []);
        const clients = groupedPackages;

        const total1 = clients?.reduce((acc, val) => {
            return acc + parseFloat(calculateClientPrice(val?.weight));
        }, 0);

        setFinalQuantity(total1);
        setClientsInvoice(clients);
    };

    const [count, setCount] = useState(0);

    const filterPackages = (packages, keyword) => {
        return packages.filter((pack) => {
            const courierName = pack.courierName.courier.businessName.toString();
            const regex = new RegExp(keyword, "i"); // Case-insensitive regex match
            return regex.test(courierName);
        });
    };

    const filteredWeigth = (packages, keyword) => {
        const pec = packages.filter((pack) => {
            const courierName = pack.courierName.courier.businessName.toString();
            const regex = new RegExp(keyword, "i"); // Case-insensitive regex match
            return regex.test(courierName);
        });
        const wei = pec?.reduce((acc, val) => {
            return acc + val?.weight?.number;
        }, 0);
        return wei;
    };

    useEffect(() => {
        if (client) {
            const searchRegex = new RegExp(client, "i");
            if (searchType === "client") {
                const filtered = clientsInvoice?.filter((val) => {
                    if (searchRegex.test(val?.clientName)) {
                        return val;
                    }
                });
                setFiltered(filtered);
                const total1 = filtered?.reduce((acc, val) => {
                    return acc + parseFloat(calculateClientPrice(val?.weight));
                }, 0);
                setFilteredTotal(total1);
            } else if (searchType === "courier") {
                const filtered = clientsInvoice
                    ?.map((val) => ({
                        ...val,
                        weight: filteredWeigth(val?.packages, client),
                        packages: filterPackages(val?.packages, client),
                    }))
                    .filter((val) => val?.packages?.length > 0);
                setFiltered(filtered);
                const total1 = filtered?.reduce((acc, val) => {
                    return acc + parseFloat(calculateClientPrice(val?.weight));
                }, 0);
                setFilteredTotal(total1);
            }
            setCount(count + 1);
        }
    }, [client]);

    console.log(clientsInvoice);

    const componentRef = useRef();
    return next === 0 ? (
        <div style={{width:"100%",overflowX:"hidden"}}>
            <div className="Page__Header"></div>
            <div className="deliveries_top">
                <div>
                    <label>Shipment Number</label>
                    <input placeholder="shipment number" value={shipmentNumber} onChange={(e) => setShipmentNumber(e.target.value)}></input>
                </div>
                <div>
                    <label>Search Type</label>
                    <select onChange={(e) => setSearchType(e.target.value)} value={searchType}>
                        <option selected disabled>
                            Search Type
                        </option>
                        <option value="client">Client</option>
                        <option value="courier">Courier</option>
                    </select>
                </div>
                {searchType === "client" && (
                    <div>
                        <label>Client Name</label>
                        <input placeholder="Client Name" value={client} onChange={(e) => setClient(e.target.value)}></input>
                    </div>
                )}
                {searchType === "courier" && (
                    <div>
                        <label>Courier Name</label>
                        <input placeholder="courier Name" value={client} onChange={(e) => setClient(e.target.value)}></input>
                    </div>
                )}
                <div>
                    <label>Date</label>
                    <p> {getDate()}</p>
                </div>
            </div>
            <div className="invoice_innerss">
                {client
                    ? filtered?.map((client, index) => {
                          return (
                              <>
                                  <p className="innr_titleee">
                                      {client?.clientName} ({client?.type})
                                  </p>
                                  <div>
                                      {client?.packages?.map((pack) => {
                                          return (
                                              <div className="packages_divvv">
                                                  <p>{pack?.Waybill}</p>
                                                  <p>{`${pack?.billTo}`}</p>
                                                  <p>{`${pack?.courierName?.courier?.businessName} `}</p>
                                                  <p>
                                                      {pack?.weight?.number} {pack?.weight?.unit}
                                                  </p>
                                                  <p>{pack?.description}</p>
                                              </div>
                                          );
                                      })}
                                  </div>
                                  <hr />
                                  <div className="bottom_ittsss">
                                      <div>
                                          <p>Total Weight: {client?.weight} Lb</p>
                                          <p>Total price: ${calculateClientPrice(client?.weight)}</p>
                                      </div>
                                  </div>
                              </>
                          );
                      })
                    : clientsInvoice?.map((client, index) => {
                          return (
                              <>
                                  <p className="innr_titleee">
                                      {client?.clientName} ({client?.type})
                                  </p>
                                  <div style={{ width: "100%", overflowX: "auto" }}>
                                      <table align="start" className="InvoiceTable">
                                          {client?.packages?.map((pack) => {
                                              return (
                                                  <tbody>
                                                      <tr>
                                                          <td>{pack?.Waybill}</td>
                                                          <td>{`${pack?.billTo}`}</td>
                                                          <td>{`${pack?.courierName?.courier?.businessName} `}</td>
                                                          <td>{pack?.description}</td>
                                                          <td>
                                                              {pack?.weight?.number} {pack?.weight?.unit}
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              );
                                          })}
                                      </table>
                                  </div>
                                  <hr />
                                  <div className="bottom_ittsss">
                                      <div>
                                          <p>Total Weight: {client?.weight} Lb</p>
                                          <p>Total price: ${calculateClientPrice(client?.weight)}</p>
                                      </div>
                                  </div>
                              </>
                          );
                      })}
                <hr />
                <div className="bottom_ittsss">
                    <p>Total Price: ${client ? filteredTotal : finalQuantity}</p>
                </div>
            </div>
            <div className="deliveries_bottom">
                <div>
                    <label>Final Amount</label>
                    <input value={client ? filteredTotal : finalQuantity}></input>
                </div>

                <div>
                    <label>Signature</label>
                    <div>
                        <SignatureCanvas penColor="black" canvasProps={{ width: 500, height: 200, className: "sigCanvas" }} ref={signref} />
                    </div>
                </div>
                <button onClick={habndleTirm}>Clear signature</button>

                {/* <button className="submit__button" onClick={handleSubmit}>
                    Submit
                </button> */}
            </div>
        </div>
    ) : (
        <>
            <div className="Headerit">
                <ReactToPrint trigger={() => <Button label="Print" iconPos="right" className="Btn__DarkAdd" style={{ width: "240px" }} />} content={() => componentRef.current} />
            </div>
            <div ref={componentRef}>
                <div className="deliveries_top">
                    <div>
                        <label>Vendor name</label>
                        <input value="Zazio.co" disabled></input>
                    </div>
                    <div>
                        <label>Date</label>
                        <p> {getDate()}</p>
                    </div>
                    <div>
                        <label>Qty</label>
                        <input></input>
                    </div>
                </div>
                <div className="invoice_innerss">
                    {client
                        ? filtered?.map((client, index) => {
                              return (
                                  <>
                                      <p className="innr_titleee">
                                          {client?.clientName} ({client?.type})
                                      </p>
                                      <div>
                                          {client?.packages?.map((pack) => {
                                              return (
                                                  <div className="packages_divvv">
                                                      <p>{pack?.Waybill}</p>
                                                      <p>{`${pack?.billTo}`}</p>
                                                      <p>{`${pack?.courierName?.courier?.businessName} `}</p>
                                                      <p>
                                                          {pack?.weight?.number} {pack?.weight?.unit}
                                                      </p>
                                                  </div>
                                              );
                                          })}
                                      </div>
                                      <hr />
                                      <div className="bottom_ittsss">
                                          <div>
                                              <p>Total Weight: {client?.weight} Lb</p>
                                              <p>Total price: ${calculateClientPrice(client?.weight)}</p>
                                          </div>
                                      </div>
                                  </>
                              );
                          })
                        : clientsInvoice?.map((client, index) => {
                              return (
                                  <>
                                      <p className="innr_titleee">
                                          {client?.clientName} ({client?.type})
                                      </p>
                                      <div>
                                          {client?.packages?.map((pack) => {
                                              return (
                                                  <div className="packages_divvv">
                                                      <p>{pack?.Waybill}</p>
                                                      <p>{`${pack?.billTo}`}</p>
                                                      <p>{`${pack?.courierName?.courier?.businessName} `}</p>
                                                      <p>
                                                          {pack?.weight?.number} {pack?.weight?.unit}
                                                      </p>
                                                  </div>
                                              );
                                          })}
                                      </div>
                                      <hr />
                                      <div className="bottom_ittsss">
                                          <div>
                                              <p>Total Weight: {client?.weight} Lb</p>
                                              <p>Total price: ${calculateClientPrice(client?.weight)}</p>
                                          </div>
                                      </div>
                                  </>
                              );
                          })}
                    <hr />
                    <div className="bottom_ittsss">
                        <p>Total Price: ${client ? filteredTotal : finalQuantity}</p>
                    </div>
                    <div className="deliveries_bottom2">
                        <div>
                            <img src={image}></img>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GenerateInvoice;
