import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { handleGetRequest } from "../../services/GetTemplate";
import moment from "moment";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { toast } from "react-toastify";
import Axios from "axios";
import { DEV } from "../../services/constants";
import "./reports.scss";
import SignatureCanvas from "react-signature-canvas";
import ReactToPrint from "react-to-print";
import ReactToPdf from "react-to-pdf";

function Report() {
    const history = useHistory();
    const signref = useRef();
    const [selectedRow, setselectedRow] = useState([]);
    const [finalQuantity, setFinalQuantity] = useState(0);
    const [shipmentNumber, setShipmentNumber] = useState();
    const [brands, setBrands] = useState([]);
    const [totalBrands, setTotalBrands] = useState();
    const dispatch = useDispatch();
    const [next, setNext] = useState(0);
    const [image, setImage] = useState("");
    const [clientsInvoice, setClientsInvoice] = useState([]);
    const [courierInvoice, setCourierInvoice] = useState([]);
    const [searchType, setSearchType] = useState(null);
    const [client, setClient] = useState();
    const [courier, setCourier] = useState();
    const [filtered, setFiltered] = useState([]);
    const [filteredTotal, setFilteredTotal] = useState();
    const [clients, setClients] = useState([]);
    const [couriers, setCouriers] = useState([]);

    const { status } = useParams();

    const getBrands = async () => {
        if (status === "unpaid") {
            const result = await Axios.get(DEV + "/searchInvoice", {
                params: {
                    reviewStatus: "reviewed",
                    paymentStatus: "pending",
                },
            });
            setTotalBrands(result?.data?.data);
        } else if (status === "paid") {
            const result = await Axios.get(DEV + "/searchPaidInvoice", {
                params: {
                    reviewStatus: "reviewed",
                    paymentStatus: "paid",
                },
            });
            setTotalBrands(result?.data?.data);
        }
        
    };
    useMemo(() => {
        getBrands();
    }, []);
    console.log(totalBrands);

    const habndleTirm = () => {
        signref.current.clear();
    };

    const getDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${day}/${month}/${year}`;
    };

    useEffect(() => {
        setFinalQuantity(selectedRow.length);
    }, [selectedRow]);

    const handleFilter = () => {
        let final;
        if (shipmentNumber.startsWith("F")) {
            const filtered = totalBrands?.filter((val) => {
                return val?.shipmentNumber === shipmentNumber;
            });
            final = filtered;
            console.log(final);
        } else if (shipmentNumber.startsWith("S")) {
            const filtered = totalBrands?.filter((val) => {
                return val?.shipmentNumber === shipmentNumber;
            });
            final = filtered;
        }
        if (final?.length > 0) {
            CalculateInvoice(final);
        }
    };

    useEffect(() => {
        if (shipmentNumber) {
            handleFilter();
        }
    }, [shipmentNumber]);

    const calculateClientPrice = (weight) => {
        const firstPoundPrice = 8.5; // Price for the first pound
        const remainingPoundsPrice = 3; // Price for the remaining pounds

        if (weight <= 1) {
            return firstPoundPrice.toFixed(2); // If weight is 1 pound or less, return the price for the first pound
        } else {
            const remainingWeight = weight - 1; // Calculate the weight of the remaining pounds
            const remainingPrice = remainingWeight * remainingPoundsPrice; // Calculate the price for the remaining pounds
            let fP = firstPoundPrice + remainingPrice;
            return fP.toFixed(2); // Return the total price
        }
    };

    const CalculateInvoice = (brands) => {
        const groupedPackages = brands.reduce((groups, pkg) => {
            const { clientId, clientName, ...packageData } = pkg;

            const existingGroup = groups.find((group) => group?.clientName === pkg?.clientName);
            if (existingGroup) {
                existingGroup.packages.push(packageData);
                if (pkg?.paymentStatus === "paid") {
                    existingGroup.totalAmount += pkg?.paidAmount;
                    existingGroup.descriptions += ", " + pkg?.descriptions;
                    existingGroup.Waybills += ", " + pkg?.Waybills;
                    existingGroup.weight += pkg?.weight;
                } else if (pkg?.paymentStatus === "pending") {
                    existingGroup.descriptions += ", " + pkg?.descriptions;
                    existingGroup.Waybills += ", " + pkg?.Waybill;
                    existingGroup.totalAmount += pkg?.invoiceAmount;
                    existingGroup.weight += pkg?.weight;
                }
            } else {
                groups.push({
                    clientId: pkg?.clientId,
                    clientName: pkg?.clientName,
                    type: pkg?.customerType,
                    packages: [packageData],
                    descriptions: pkg?.descriptions,
                    Waybills: pkg?.Waybills,
                    totalAmount: pkg?.paymentStatus === "paid" ? pkg?.paidAmount : pkg?.invoiceAmount,
                    weight: pkg?.weight,
                    date: pkg?.date,
                    datePaid: pkg?.datePaid,
                });
            }

            return groups;
        }, []);
        const clients = groupedPackages;

        const total1 = clients?.reduce((acc, val) => {
            return acc + val?.totalAmount;
        }, 0);

        setFinalQuantity(total1);
        setClientsInvoice(clients);
    };

    const [count, setCount] = useState(0);

    useEffect(() => {
        if (client) {
            const searchRegex = new RegExp(client, "i");

            const filtered = clientsInvoice?.filter((val) => {
                if (searchRegex.test(val?.clientName)) {
                    return val;
                }
            });
            setFiltered(filtered);
            const total1 = filtered?.reduce((acc, val) => {
                return acc + val?.totalAmount;
            }, 0);
            setFilteredTotal(total1);

            setCount(count + 1);
        }
    }, [client]);

    console.log(clientsInvoice);

    const componentRef = useRef();
    return next === 0 ? (
        <div style={{ width: "100%", overflowX: "hidden" }}>
            <div className="Page__Header"></div>
            <div className="deliveries_top">
                <div>
                    <label>Shipment Number</label>
                    <input placeholder="shipment number" value={shipmentNumber} onChange={(e) => setShipmentNumber(e.target.value)}></input>
                </div>
                
                
                    <div>
                        <label>Customer Name</label>
                        <input placeholder="Client Name" value={client} onChange={(e) => setClient(e.target.value)}></input>
                    </div>
                
                
                <div>
                    <label>Date</label>
                    <p> {getDate()}</p>
                </div>
            </div>
            <div className="invoice_innerss">
                {client
                    ? filtered?.map((client, index) => {
                          return (
                              <>
                                  <p className="innr_titleee">
                                      {client?.clientName} ({client?.type})
                                  </p>
                                  <div style={{ width: "100%", overflowX: "auto" }}>
                                      <table align="center" className="InvoiceTable">
                                          <tbody style={{ marginBottom: "20px" }}>
                                              <tr align="start">
                                                  <td
                                                      style={{
                                                          fontWeight: "bold",
                                                      }}
                                                  >
                                                      Client Name
                                                  </td>
                                                  <td
                                                      style={{
                                                          fontWeight: "bold",
                                                      }}
                                                  >
                                                      Total Amount
                                                  </td>
                                                  <td
                                                      style={{
                                                          fontWeight: "bold",
                                                      }}
                                                  >
                                                      Issue Date
                                                  </td>
                                                  {status === "paid" && (
                                                      <td
                                                          style={{
                                                              fontWeight: "bold",
                                                          }}
                                                      >
                                                          Date Paid
                                                      </td>
                                                  )}
                                                  <td
                                                      style={{
                                                          fontWeight: "bold",
                                                      }}
                                                  >
                                                      Weight
                                                  </td>
                                              </tr>
                                          </tbody>
                                          {client?.packages?.map((pack) => {
                                              return (
                                                  <tbody style={{ marginTop: "10px" }}>
                                                      <tr>
                                                          <td>{client?.clientName}</td>
                                                          <td>{`$${status === "paid" ? pack?.paidAmount : pack?.invoiceAmount} `}</td>
                                                          <td>{pack?.date}</td>
                                                          <td>{pack?.datePaid}</td>
                                                          <td>{`${pack?.weight} Lb`}</td>
                                                      </tr>
                                                  </tbody>
                                              );
                                          })}
                                      </table>
                                  </div>
                                  <hr />
                                  <div className="bottom_ittsss">
                                      <div>
                                          <p>Total Weight: {client?.weight} Lb</p>
                                          <p>Total price: ${client?.totalAmount}</p>
                                      </div>
                                  </div>
                              </>
                          );
                      })
                    : clientsInvoice?.map((client, index) => {
                          return (
                              <>
                                  <p className="innr_titleee">
                                      {client?.clientName} ({client?.type})
                                  </p>
                                  <div style={{ width: "100%", overflowX: "auto" }}>
                                      <table align="center" className="InvoiceTable">
                                          <tbody style={{ marginBottom: "20px" }}>
                                              <tr align="start">
                                                  <td
                                                      style={{
                                                          fontWeight: "bold",
                                                      }}
                                                  >
                                                      Client Name
                                                  </td>
                                                  <td
                                                      style={{
                                                          fontWeight: "bold",
                                                      }}
                                                  >
                                                      Total Amount
                                                  </td>
                                                  <td
                                                      style={{
                                                          fontWeight: "bold",
                                                      }}
                                                  >
                                                      Issue Date
                                                  </td>
                                                  {status === "paid" && (
                                                      <td
                                                          style={{
                                                              fontWeight: "bold",
                                                          }}
                                                      >
                                                          Date Paid
                                                      </td>
                                                  )}
                                                  <td
                                                      style={{
                                                          fontWeight: "bold",
                                                      }}
                                                  >
                                                      Weight
                                                  </td>
                                              </tr>
                                          </tbody>
                                          {client?.packages?.map((pack) => {
                                              return (
                                                  <tbody style={{ marginTop: "10px" }}>
                                                      <tr>
                                                          <td>{client?.clientName}</td>
                                                          <td>{`$${status === "paid" ? pack?.paidAmount : pack?.invoiceAmount} `}</td>
                                                          <td>{pack?.date}</td>
                                                         {status==="paid" &&  <td>{pack?.datePaid}</td>}
                                                          <td>{`${pack?.weight} Lb`}</td>
                                                      </tr>
                                                  </tbody>
                                              );
                                          })}
                                      </table>
                                  </div>
                                  <hr />
                                  <div className="bottom_ittsss">
                                      <div>
                                          <p>Total Weight: {client?.weight} Lb</p>
                                          <p>Waybills: {client?.Waybills}</p>
                                          <p>Descriptions: {client?.descriptions}</p>
                                          <p>Total price: ${client?.totalAmount}</p>
                                      </div>
                                  </div>
                              </>
                          );
                      })}
                <hr />
                <div className="bottom_ittsss">
                    <p>Total Price: ${client ? filteredTotal : (finalQuantity).toFixed(2)}</p>
                </div>
            </div>
            {/* <div className="deliveries_bottom">
                <div>
                    <label>Final Amount</label>
                    <input value={client ? filteredTotal : finalQuantity}></input>
                </div>

                <div>
                    <label>Signature</label>
                    <div>
                        <SignatureCanvas penColor="black" canvasProps={{ width: 500, height: 200, className: "sigCanvas" }} ref={signref} />
                    </div>
                </div>
                <button onClick={habndleTirm}>Clear signature</button>

                <button className="submit__button" onClick={handleSubmit}>
                    Submit
                </button>
            </div> */}
        </div>
    ) : (
        <>
            {/* <div className="Headerit">
                <ReactToPrint trigger={() => <Button label="Print" iconPos="right" className="Btn__DarkAdd" style={{ width: "240px" }} />} content={() => componentRef.current} />
            </div>
            <div ref={componentRef}>
                <div className="deliveries_top">
                    <div>
                        <label>Vendor name</label>
                        <input value="Zazio.co" disabled></input>
                    </div>
                    <div>
                        <label>Date</label>
                        <p> {getDate()}</p>
                    </div>
                    <div>
                        <label>Qty</label>
                        <input></input>
                    </div>
                </div>
                <div className="invoice_innerss">
                    {client
                        ? filtered?.map((client, index) => {
                              return (
                                  <>
                                      <p className="innr_titleee">
                                          {client?.clientName} ({client?.type})
                                      </p>
                                      <div>
                                          {client?.packages?.map((pack) => {
                                              return (
                                                  <div className="packages_divvv">
                                                      <p>{pack?.Waybill}</p>
                                                      <p>{`${pack?.billTo}`}</p>
                                                      <p>{`${pack?.courierName?.courier?.businessName} `}</p>
                                                      <p>
                                                          {pack?.weight?.number} {pack?.weight?.unit}
                                                      </p>
                                                  </div>
                                              );
                                          })}
                                      </div>
                                      <hr />
                                      <div className="bottom_ittsss">
                                          <div>
                                              <p>Total Weight: {client?.weight} Lb</p>
                                              <p>Total price: ${calculateClientPrice(client?.weight)}</p>
                                          </div>
                                      </div>
                                  </>
                              );
                          })
                        : clientsInvoice?.map((client, index) => {
                              return (
                                  <>
                                      <p className="innr_titleee">
                                          {client?.clientName} ({client?.type})
                                      </p>
                                      <div>
                                          {client?.packages?.map((pack) => {
                                              return (
                                                  <div className="packages_divvv">
                                                      <p>{pack?.Waybill}</p>
                                                      <p>{`${pack?.billTo}`}</p>
                                                      <p>{`${pack?.courierName?.courier?.businessName} `}</p>
                                                      <p>
                                                          {pack?.weight?.number} {pack?.weight?.unit}
                                                      </p>
                                                  </div>
                                              );
                                          })}
                                      </div>
                                      <hr />
                                      <div className="bottom_ittsss">
                                          <div>
                                              <p>Total Weight: {client?.weight} Lb</p>
                                              <p>Total price: ${calculateClientPrice(client?.weight)}</p>
                                          </div>
                                      </div>
                                  </>
                              );
                          })}
                    <hr />
                    <div className="bottom_ittsss">
                        <p>Total Price: ${client ? filteredTotal : finalQuantity}</p>
                    </div>
                    <div className="deliveries_bottom2">
                        <div>
                            <img src={image}></img>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Report;
