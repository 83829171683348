import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useHistory, useLocation } from "react-router-dom";
import { handleGetRequest } from "../../services/GetTemplate";
import "./invoice.scss";
import { Tooltip } from "react-tippy";
import { handlePutRequest } from "../../services/PutTemplate";
import { toast } from "react-toastify";
import Water from "../common/WaterMark";
import generatePDF, { Margin } from "react-to-pdf";
import { Dialog } from "primereact/dialog";
import PaymentModel from "./PaymentModel";
import SoftStops from "../../modals/SoftStops";

function Invoice() {
    const targetRef = useRef();
    const history = useHistory();
    const [brands, setBrands] = useState();
    const [packages, setPackages] = useState([]);
    const [oversize, setOversize] = useState(0);
    const [dangerous, setDangerous] = useState(0);
    const [custom, setCustom] = useState(0);
    const [total, setTotal] = useState(0);
    const [loyalty, setLoyalty] = useState(0);
    const [pickup, setPickup] = useState(0);
    const [packaging, setPackaging] = useState(0);
    const [declaration, setDeclaration] = useState(0);
    const [fullTotal, setFullTotal] = useState(0);
    const [paid, setPaid] = useState(false);
    const [show, setShow] = useState(true);
    const [notpaid, setNotPaid] = useState(false);
    const [notes, setNotes] = useState();
    const [SubTotal, setSubTotal] = useState(0);
    const [discountedAmount, setDiscountedAmount] = useState(0);
    const [monthly, setMonthly] = useState(0);
    const [website, setWebsite] = useState(0);
    const [delivery, setDelivery] = useState(0);
    const [price, setPrice] = useState(0);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
    const getBrands = async () => {
        const res = await handleGetRequest("/specificInvoice/" + id);
        setBrands(res?.data);
        setPackages(res?.data?.packages);
        setOversize(Math.round(res?.data?.oversizeFee * 100) / 100);
        setDangerous(res?.data?.dangerousItemFee ? Math.round(res?.data?.dangerousItemFee * 100) / 100 : 0);
        setCustom(Math.round(res?.data?.customDuties * 100) / 100);
        setTotal(Math.round(res?.data?.invoiceAmount * 100) / 100);
        setFullTotal(Math.round(res?.data?.invoiceAmount * 100) / 100);
        setLoyalty(Math.round(res?.data?.loyaltyDiscount * 100) / 100);
        setPickup(Math.round(res?.data?.pickupFee * 100) / 100);
        setPackaging(Math.round(res?.data?.packagingFee * 100) / 100);
        setDeclaration(Math.round(res?.data?.declarationFee * 100) / 100);
        setNotes(res?.data?.notes);
        setSubTotal(Math.round(res?.data?.TotalAmount * 100) / 100);
        setDiscountedAmount(Math.round(res?.data?.discountedAmount * 100) / 100);
        setMonthly(res?.data?.MonthlyFee);
        setWebsite(res?.data?.WebsiteFee);
        setDelivery(Math.round(res?.data?.deliveryFee * 100) / 100);
        setPrice(res?.data?.itemsPrice);
        if (res?.data?.paymentStatus === "paid") {
            setPaid(true);
            setShow(false);
        }
    };
    useMemo(() => {
        getBrands();
    }, [id]);
    const componentRef = useRef();

    console.log(brands);

    const handlePackages = (name, value, index) => {
        const pack = packages;
        let val = parseFloat(value);
        pack[index][name] = val;
        pack[index].total = pack[index].price * pack[index].weight;
        setPackages(pack);
        const total = pack.reduce((a, b) => a + b.total, 0) + oversize + dangerous + custom + pickup + packaging + declaration + delivery + price;
        let final = Math.round(total * 100) / 100;
        setTotal(final);
    };

    const handleoverSize = (e) => {
        setOversize(parseFloat(e));
        const total = packages.reduce((a, b) => a + b.total, 0) + parseFloat(e) + dangerous + custom + pickup + packaging + declaration + delivery + price;
        if (brands?.customerType === "partner" || brands?.customerType === "admin") {
            const subTotal = packages.reduce((a, b) => a + b.total, 0) + parseFloat(e) + dangerous + custom + pickup + packaging + declaration + delivery + price;
            const total = subTotal - discountedAmount;
            let final = Math.round(total * 100) / 100;
            let final1 = Math.round(subTotal * 100) / 100;
            setSubTotal(final1);
            setTotal(final);
        } else {
            let final = Math.round(total * 100) / 100;
            setTotal(final);
        }
    };

    const handledangerous = (e) => {
        setDangerous(parseFloat(e));
        const total = packages.reduce((a, b) => a + b.total, 0) + oversize + parseFloat(e) + custom + pickup + packaging + declaration + delivery + price;
        if (brands?.customerType === "partner" || brands?.customerType === "admin") {
            const subTotal = packages.reduce((a, b) => a + b.total, 0) + oversize + parseFloat(e) + custom + pickup + packaging + declaration + delivery + price;
            const total = subTotal - discountedAmount;
            let final = Math.round(total * 100) / 100;
            let final1 = Math.round(subTotal * 100) / 100;
            setSubTotal(final1);
            setTotal(final);
        } else {
            let final = Math.round(total * 100) / 100;
            setTotal(final);
        }
    };

    const handlecustom = (e) => {
        setCustom(parseFloat(e));
        const total = packages.reduce((a, b) => a + b.total, 0) + oversize + dangerous + parseFloat(e) + pickup + packaging + declaration + delivery + price;
        if (brands?.customerType === "partner" || brands?.customerType === "admin") {
            const subTotal = packages.reduce((a, b) => a + b.total, 0) + oversize + dangerous + parseFloat(e) + pickup + packaging + declaration + delivery + price;
            const total = subTotal - discountedAmount;
            let final = Math.round(total * 100) / 100;
            let final1 = Math.round(subTotal * 100) / 100;
            setSubTotal(final1);
            setTotal(final);
        } else {
            let final = Math.round(total * 100) / 100;
            setTotal(final);
        }
    };

    const handleLoyalty = (percentage) => {
        setLoyalty(parseFloat(percentage));
        const total = packages.reduce((a, b) => a + b.total, 0) + oversize + dangerous + custom + pickup + packaging + declaration + delivery + price;
        const newTotal = total - total * (percentage / 100);
        let final = Math.round(newTotal * 100) / 100;
        setTotal(final);
    };

    const handlePickup = (e) => {
        setPickup(parseFloat(e));
        const total = packages.reduce((a, b) => a + b.total, 0) + oversize + dangerous + custom + parseFloat(e) + packaging + declaration + delivery + price;
        if (brands?.customerType === "partner" || brands?.customerType === "admin") {
            const subTotal = packages.reduce((a, b) => a + b.total, 0) + oversize + dangerous + custom + parseFloat(e) + packaging + declaration + delivery + price;
            const total = subTotal - discountedAmount;
            let final = Math.round(total * 100) / 100;
            let final1 = Math.round(subTotal * 100) / 100;
            setSubTotal(final1);
            setTotal(final);
        } else {
            let final = Math.round(total * 100) / 100;
            setTotal(final);
        }
    };

    const handlePackaging = (e) => {
        setPackaging(parseFloat(e));
        const total = packages.reduce((a, b) => a + b.total, 0) + oversize + dangerous + custom + parseFloat(e) + pickup + declaration + delivery + price;
        if (brands?.customerType === "partner" || brands?.customerType === "admin") {
            const subTotal = packages.reduce((a, b) => a + b.total, 0) + oversize + dangerous + custom + parseFloat(e) + pickup + declaration + delivery + price;
            const total = subTotal - discountedAmount;
            let final = Math.round(total * 100) / 100;
            let final1 = Math.round(subTotal * 100) / 100;
            setSubTotal(final1);
            setTotal(final);
        } else {
            let final = Math.round(total * 100) / 100;
            setTotal(final);
        }
    };

    const handleDelivery = (e) => {
        setDelivery(parseFloat(e));
        const total = packages.reduce((a, b) => a + b.total, 0) + oversize + dangerous + custom + parseFloat(e) + pickup + packaging + declaration + price;
        if (brands?.customerType === "partner" || brands?.customerType === "admin") {
            const subTotal = packages.reduce((a, b) => a + b.total, 0) + oversize + dangerous + custom + parseFloat(e) + pickup + packaging + declaration + price;
            const total = subTotal - discountedAmount;
            let final = Math.round(total * 100) / 100;
            let final1 = Math.round(subTotal * 100) / 100;
            setSubTotal(final1);
            setTotal(final);
        } else {
            let final = Math.round(total * 100) / 100;
            setTotal(final);
        }
    };

    const handleprice = (e) => {
        setPrice(parseFloat(e));
        const total = packages.reduce((a, b) => a + b.total, 0) + oversize + dangerous + custom + parseFloat(e) + pickup + packaging + declaration + delivery;
        if (brands?.customerType === "partner" || brands?.customerType === "admin") {
            const subTotal = packages.reduce((a, b) => a + b.total, 0) + oversize + dangerous + custom + parseFloat(e) + pickup + packaging + declaration + delivery;
            const total = subTotal - discountedAmount;
            let final = Math.round(total * 100) / 100;
            let final1 = Math.round(subTotal * 100) / 100;
            setSubTotal(final1);
            setTotal(final);
        } else {
            let final = Math.round(total * 100) / 100;
            setTotal(final);
        }
    };

    const handleDeclaration = (e) => {
        setDeclaration(parseFloat(e));
        if (e) {
            const total = packages.reduce((a, b) => a + b.total, 0) + oversize + dangerous + custom + parseFloat(e) + pickup + packaging + delivery + price;
            if (brands?.customerType === "partner" || brands?.customerType === "admin") {
                const subTotal = packages.reduce((a, b) => a + b.total, 0) + oversize + dangerous + custom + parseFloat(e) + pickup + packaging + delivery + price;

                const total = subTotal - discountedAmount;

                let final = Math.round(total * 100) / 100;
                let final1 = Math.round(subTotal * 100) / 100;
                setSubTotal(final1);
                setTotal(final);
            } else {
                let final = Math.round(total * 100) / 100;
                setTotal(final);
            }
        }
    };

    const handleMonthly = (e) => {
        setMonthly(parseFloat(e));
        const total = parseFloat(e) + website;
        let final = Math.round(total * 100) / 100;
        setTotal(final);
    };

    const handleWebsite = (e) => {
        setWebsite(parseFloat(e));
        const total = monthly + parseFloat(e);
        let final = Math.round(total * 100) / 100;
        setTotal(final);
    };

    const handleEdit = async () => {
        const invoice = brands;
        invoice.packages = packages;
        invoice.oversizeFee = oversize;
        invoice.dangerousItemFee = dangerous;
        invoice.customDuties = custom;
        invoice.invoiceAmount = total;
        invoice.loyaltyDiscount = loyalty;
        invoice.pickupFee = pickup;
        invoice.packagingFee = packaging;
        invoice.declarationFee = declaration;
        invoice.notes = notes;
        invoice.TotalAmount = SubTotal;
        invoice.discountedAmount = discountedAmount;
        invoice.MonthlyFee = monthly;
        invoice.WebsiteFee = website;
        invoice.itemsPrice = price;
        invoice.deliveryFee = delivery;
        console.log(invoice);
        const res = await handlePutRequest(invoice, "/updateInvoice");
        if (res?.success) {
            toast.success("invoice edited");
            window.location.reload();
        }
    };

    const handleMarkedPaid = async () => {
        const data = {
            id: brands?._id,
            paymentStatus: "paid",
        };
        const res = await handlePutRequest(data, "/markInvoicePaid");
        if (res?.success) {
            toast.success("invoice paid");
            window.location.reload();
        }
    };

    const handleMarkedReviewed = async () => {
        const data = {
            id: brands?._id,
            reviewStatus: "reviewed",
        };
        const res = await handlePutRequest(data, "/changeReviewStatus");
        if (res?.success) {
            toast.success("invoice marked as reviewed");
            window.location.reload();
        }
    };

    const handlePdf = () => {
        if (brands?.paymentStatus === "paid") {
            generatePDF(targetRef, {
                filename: `Receipt(${brands?.invoiceNo}).pdf`,
                page: {
                    format: "A3",
                    margin: {
                        top: 10,
                        right: 30,
                        bottom: 10,
                        left: 30,
                    },
                },
            });
        } else {
            setShow(false);
            setNotPaid(true);
        }
    };

    useEffect(() => {
        if (show === false && notpaid === true) {
            generatePDF(targetRef, {
                filename: "Invoice.pdf",
                page: {
                    format: "A3",
                    margin: {
                        top: 10,
                        right: 30,
                        bottom: 10,
                        left: 30,
                    },
                },
            });
            setShow(true);
            setNotPaid(false);
        }
    }, [show]);

    const [showDialog, setShowDialog] = useState(false);

    const onHideTyreBrandsDialog = () => {
        setShowDialog(false);
    };

    const onsuccess = () => {
        onHideTyreBrandsDialog();
        toast.success("Payment Made.");
        window.location.reload();
    };

    const handlePayment = () => {
        localStorage.setItem("invoiceId", brands?._id);
        localStorage.setItem("PaymentUser", "admin");
        history.push(`/payment`);
    };

    const handleLoyalityDiscount = () => {
        if (show === false && brands?.invoiceType !== "Monthly" && loyalty !== 0) {
            if (brands?.customerType !== "partner") {
                if (brands?.customerType !== "admin") {
                    return (
                        <tr>
                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Loyalty Discount</td>
                            <td></td>
                            <td></td>
                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>{loyalty}%</td>
                        </tr>
                    );
                }
            }
        } else if (show === true && brands?.invoiceType !== "Monthly") {
            if (brands?.customerType !== "partner") {
                if (brands?.customerType !== "admin") {
                    return (
                        <tr>
                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Loyalty Discount</td>
                            <td></td>
                            <td></td>
                            <td>
                                <select style={{ width: "50px", height: "30px", paddingLeft: "5px", borderRadius: "6px", marginLeft: "12px" }} value={loyalty} onChange={(e) => handleLoyalty(e.target.value)}>
                                    <option value={0}>0%</option>
                                    <option value={5}>5%</option>
                                    <option value={10}>10%</option>
                                    <option value={15}>15%</option>
                                </select>
                            </td>
                        </tr>
                    );
                }
            }
        }
    };

    const [showSoftStop, setShowSoftStop] = useState(false);
    const [showSoftStop1, setShowSoftStop1] = useState(false);
    const [showSoftStop2, setShowSoftStop2] = useState(false);
    const [showSoftStop3, setShowSoftStop3] = useState(false);

    const openEdit = () => {
        setShowSoftStop(true);
    };

    const onHideSoftStop = () => {
        setShowSoftStop(false);
    };

    const onSuccessSoftStop = async () => {
        handleEdit();
    };

    const openPayment = () => {
        setShowSoftStop1(true);
    };

    const onHideSoftStop1 = () => {
        setShowSoftStop1(false);
    };

    const onSuccessSoftStop1 = async () => {
        handlePayment();
    };

    const openMarkedPaid = () => {
        setShowSoftStop2(true);
    };

    const onHideSoftStop2 = () => {
        setShowSoftStop2(false);
    };

    const onSuccessSoftStop2 = async () => {
        handleMarkedPaid();
    };

    const openMarkedReviewed = () => {
        setShowSoftStop3(true);
    };

    const onHideSoftStop3 = () => {
        setShowSoftStop3(false);
    };

    const onSuccessSoftStop3 = async () => {
        handleMarkedReviewed();
    };

    console.log(brands);

    return (
        <>
            <Dialog visible={showDialog} header="Pay Invoice" style={{ width: "650px" }} onHide={() => setShowDialog(false)}>
                <PaymentModel onHideTyreBrandsDialog={onHideTyreBrandsDialog} onsuccess={onsuccess} />
            </Dialog>
            <Dialog visible={showSoftStop} style={{ width: "650px" }} onHide={() => setShowSoftStop(false)}>
                <SoftStops onHideSoftStop={onHideSoftStop} onSuccessSoftStop={onSuccessSoftStop} message="Are you sure you want to edit this invoice?"></SoftStops>
            </Dialog>
            <Dialog visible={showSoftStop1} style={{ width: "650px" }} onHide={() => setShowSoftStop1(false)}>
                <SoftStops onHideSoftStop={onHideSoftStop1} onSuccessSoftStop={onSuccessSoftStop1} message="Are you sure you want to pay for this invoice?"></SoftStops>
            </Dialog>
            <Dialog visible={showSoftStop2} style={{ width: "650px" }} onHide={() => setShowSoftStop2(false)}>
                <SoftStops onHideSoftStop={onHideSoftStop2} onSuccessSoftStop={onSuccessSoftStop2} message="Are you sure you want to mark this invoice as paid?"></SoftStops>
            </Dialog>
            <Dialog visible={showSoftStop3} style={{ width: "650px" }} onHide={() => setShowSoftStop3(false)}>
                <SoftStops onHideSoftStop={onHideSoftStop3} onSuccessSoftStop={onSuccessSoftStop3} message="Are you sure you want to mark this invoice as reviewed?"></SoftStops>
            </Dialog>
            <div className="Headerit">
                <Tooltip
                    // options
                    title="Download Invoice as PDF"
                    position="bottom"
                    trigger="mouseenter"
                    arrow={true}
                >
                    <Button icon="pi pi-file-pdf" iconPos="right" className="Btn__DarkAdd" onClick={handlePdf} />
                </Tooltip>
                {!paid && (
                    <Tooltip
                        // options
                        title="Edit Invoice"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-pencil" onClick={openEdit}></Button>
                    </Tooltip>
                )}
                {!paid && (
                    <Tooltip
                        // options
                        title="Pay Invoice"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-wallet" onClick={() => openPayment()}></Button>
                    </Tooltip>
                )}
                {!paid && (
                    <Tooltip
                        // options
                        title="Mark as paid"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-check" onClick={openMarkedPaid}></Button>
                    </Tooltip>
                )}
                {!paid && (
                    <Tooltip
                        // options
                        title="Mark as reviewed"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-search-plus" onClick={openMarkedReviewed}></Button>
                    </Tooltip>
                )}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
                <div ref={targetRef} style={{ position: "relative", width: `${window?.innerWidth < 768 ? "100%" : "900px"}` }}>
                    {paid && <Water />}
                    <div style={{ width: "100%", marginTop: "20px", padding: `${window?.innerWidth < 768 ? "20px 0px" : "20px 50px"}` }}>
                        <div className="invoice_top">
                            <img src={"https://res.cloudinary.com/dwxqg9so3/image/upload/v1692496183/WhatsApp_Image_2023-08-18_at_6.45.10_AM_qg5n56.jpg"} style={{ height: "180px", width: "220px", objectFit: "cover" }} alt="logo" />
                            <div style={{ width: "100%", marginTop: "25px", display: "flex", padding: "5px", columnGap: `${window?.innerWidth < 768 ? "50px" : "130px"}`, flexWrap: "wrap" }}>
                                <div className="billing_section_1">
                                    <p style={{ fontSize: `${window?.innerWidth < 768 ? "16px" : "18px"}`, fontWeight: "600", color: "black" }}>Client Info</p>
                                    <div style={{ marginTop: "5px" }}>
                                        <span style={{ fontSize: `${window?.innerWidth < 768 ? "12px" : "14px"}`, fontWeight: "500", color: "black" }}>{brands?.clientName}</span>
                                    </div>
                                    <div style={{ marginTop: "5px" }}>
                                        <span style={{ fontSize: `${window?.innerWidth < 768 ? "12px" : "14px"}`, fontWeight: "500", color: "black", paddingTop: "10px", width: `${window?.innerWidth < 768 ? "40px" : "130px"}` }}>{brands?.email}</span>
                                    </div>
                                    <div style={{ marginTop: "5px" }}>
                                        <span style={{ fontSize: `${window?.innerWidth < 768 ? "12px" : "14px"}`, fontWeight: "500", color: "black", paddingTop: "10px" }}>{brands?.phone}</span>
                                    </div>
                                    <br />
                                </div>
                                <div className="billing_section_1">
                                    <p style={{ fontSize: `${window?.innerWidth < 768 ? "16px" : "18px"}`, fontWeight: "600", color: "black" }}>Shipping Address</p>
                                    <div style={{ width: "205px" }}>
                                        <p style={{ fontSize: `${window?.innerWidth < 768 ? "12px" : "14px"}`, fontWeight: "500", color: "black" }}>1150 Lee Wagener Blvd</p>
                                        <p style={{ fontSize: `${window?.innerWidth < 768 ? "12px" : "14px"}`, fontWeight: "500", color: "black", marginTop: "-15px" }}>Ste 203 ZAZIO</p>
                                        <p style={{ fontSize: `${window?.innerWidth < 768 ? "12px" : "14px"}`, fontWeight: "500", color: "black", marginTop: "-15px" }}>Fort Lauderdale</p>
                                        <p style={{ fontSize: `${window?.innerWidth < 768 ? "12px" : "14px"}`, fontWeight: "500", color: "black", marginTop: "-15px" }}>FL 33315</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p style={{ fontSize: `${window?.innerWidth < 768 ? "20px" : "24px"}`, fontWeight: "bold", color: "black", fontFamily: "Arial", marginTop: "50px" }}>{paid ? "Receipt" : "Invoice"}</p>
                        <div style={{ width: "100%", borderTop: "4px solid black", borderBottom: "4px solid black" }}>
                            <div style={{ height: "40px", borderBottom: "4px solid rgb(196, 194, 194)", display: "flex", alignItems: "center", columnGap: `${window?.innerWidth < 768 ? "70px" : "100px"}` }}>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500", paddingTop: "14px", width: "140px" }}>Order Date</p>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500" }}>{brands?.date}</p>
                            </div>
                            <div style={{ height: "40px", borderBottom: "4px solid rgb(196, 194, 194)", display: "flex", alignItems: "center", columnGap: `${window?.innerWidth < 768 ? "70px" : "100px"}` }}>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500", paddingTop: "14px", width: "140px" }}>Payment Method</p>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500" }}>Card {brands?.datePaid ? `at ${brands?.datePaid}` : null}</p>
                            </div>
                            <div style={{ height: "40px", borderBottom: "4px solid rgb(196, 194, 194)", display: "flex", alignItems: "center", columnGap: `${window?.innerWidth < 768 ? "70px" : "100px"}` }}>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500", paddingTop: "14px", width: "140px" }}>Total {brands?.type==="sea"?"Size":"Weight"}</p>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500" }}>{brands?.weight} {brands?.type==="sea"?"Cu ft":"Lbs"}</p>
                            </div>
                            <div style={{ height: "40px", borderBottom: "4px solid rgb(196, 194, 194)", display: "flex", alignItems: "center", columnGap: `${window?.innerWidth < 768 ? "70px" : "100px"}` }}>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500", paddingTop: "14px", width: "140px" }}>Contents</p>
                                <p style={{ fontSize: `${window?.innerWidth < 768 ? "14px" : "16px"}`, color: "black", fontWeight: "500" }}>{brands?.descriptions}</p>
                            </div>

                            <div style={{ marginTop: "60px" }}>
                                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                    <thead style={{ textAlign: "left", color: "black" }}>
                                        <tr>
                                            <th>Product</th>
                                            <th>{brands?.type==="sea"?"Size":"Weight"}</th>
                                            <th>Price</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody className="inside_table_body">
                                        {brands?.invoiceType !== "Monthly" &&
                                            packages?.map((item, index) => {
                                                let temp;
                                                let prev = packages[index - 1]?.product;
                                                if (brands?.customerType === "client") {
                                                    if (brands?.size === "sea") {
                                                        temp = "Total Size of Cargo";
                                                    } else {
                                                        if (item?.product === prev) {
                                                            temp = "Additional Pounds";
                                                        } else {
                                                            temp = "1st Pound of Cargo";
                                                        }
                                                    }
                                                } else {
                                                    if (brands?.type === "sea") {
                                                        temp = "Total Size of Cargo";
                                                    } else {
                                                        temp = "Total Pounds of Cargo";
                                                    }
                                                }
                                                return (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>{temp}</td>
                                                        {show && (
                                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>
                                                                <input type="number" value={item?.weight} onChange={(e) => handlePackages("weight", e.target.value, index)} style={{ width: "50px", height: "30px", paddingLeft: "5px", borderRadius: "6px" }}></input> {item?.type==="sea"?"cu":"lb"}
                                                            </td>
                                                        )}
                                                        {!show && <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>{item?.weight} {item?.type==="sea"?"cu":"lb"}</td>}
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${item?.price}</td>

                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${(item?.total).toFixed(2)}</td>
                                                    </tr>
                                                );
                                            })}

                                        {!show && brands?.invoiceType !== "Monthly" && price === 0 && oversize == 0 && packaging == 0 && pickup === 0 && delivery === 0 && declaration === 0 && custom === 0 && loyalty === 0 && dangerous === 0 ? null : (
                                            <tr style={{}}>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "600" }}>Add-ons</td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                            </tr>
                                        )}

                                        <>
                                            <>
                                                {/* cost of goods */}
                                                {!show && brands?.invoiceType !== "Monthly" && price !== 0 && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Cost of Goods</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${price}</td>
                                                    </tr>
                                                )}
                                                {show && brands?.invoiceType !== "Monthly" && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Cost of Goods</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>
                                                            $ <input value={price} type="number" onChange={(e) => handleprice(e.target.value)} style={{ width: "50px", height: "30px", paddingLeft: "5px", borderRadius: "6px" }}></input>
                                                        </td>
                                                    </tr>
                                                )}

                                                {/* oversize package  */}
                                                {!show && brands?.invoiceType !== "Monthly" && oversize !== 0 && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>{brands?.type==="sea"?"Handling":"Oversize Fee"}</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${oversize}</td>
                                                    </tr>
                                                )}
                                                {show && brands?.invoiceType !== "Monthly" && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>{brands?.type==="sea"?"Handling":"Oversize Fee"}</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>
                                                            $ <input value={oversize} type="number" onChange={(e) => handleoverSize(e.target.value)} style={{ width: "50px", height: "30px", paddingLeft: "5px", borderRadius: "6px" }}></input>
                                                        </td>
                                                    </tr>
                                                )}

                                                {/* packaging Fee */}
                                                {!show && brands?.invoiceType !== "Monthly" && packaging !== 0 && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Packaging</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${packaging}</td>
                                                    </tr>
                                                )}
                                                {show && brands?.invoiceType !== "Monthly" && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Packaging</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>
                                                            <select style={{ width: "50px", height: "30px", paddingLeft: "5px", borderRadius: "6px", marginLeft: "12px" }} value={packaging} onChange={(e) => handlePackaging(e.target.value)}>
                                                                <option value={0}>$0</option>
                                                                <option value={20}>$20</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                )}

                                                {/* pickup fee */}
                                                {!show && brands?.invoiceType !== "Monthly" && pickup !== 0 && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Pickup</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${pickup}</td>
                                                    </tr>
                                                )}
                                                {show && brands?.invoiceType !== "Monthly" && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Pickup</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>
                                                            <select style={{ width: "50px", height: "30px", paddingLeft: "5px", borderRadius: "6px", marginLeft: "12px" }} value={pickup} onChange={(e) => handlePickup(e.target.value)}>
                                                                <option value={0}>$0</option>
                                                                <option value={15}>$15</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                )}

                                                {/* delivery fee */}
                                                {!show && brands?.invoiceType !== "Monthly" && delivery !== 0 && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Delivery</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${delivery}</td>
                                                    </tr>
                                                )}
                                                {show && brands?.invoiceType !== "Monthly" && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Delivery</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>
                                                            $ <input value={delivery} type="number" onChange={(e) => handleDelivery(e.target.value)} style={{ width: "50px", height: "30px", paddingLeft: "5px", borderRadius: "6px" }}></input>
                                                        </td>
                                                    </tr>
                                                )}

                                                {/* declaration fee */}
                                                {!show && brands?.invoiceType !== "Monthly" && declaration !== 0 && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>{brands?.type==="sea"?"Pre-Clearance":"Declaration Fee"}</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${declaration}</td>
                                                    </tr>
                                                )}
                                                {show && brands?.invoiceType !== "Monthly" && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>{brands?.type==="sea"?"Pre-Clearance":"Declaration Fee"}</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>
                                                            $ <input value={declaration} type="number" onChange={(e) => handleDeclaration(e.target.value)} style={{ width: "50px", height: "30px", paddingLeft: "5px", borderRadius: "6px" }}></input>
                                                        </td>
                                                    </tr>
                                                )}

                                                {/* custom duties */}
                                                {!show && brands?.invoiceType !== "Monthly" && custom !== 0 && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Custom Duties</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${custom}</td>
                                                    </tr>
                                                )}
                                                {show && brands?.invoiceType !== "Monthly" && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Custom Duties</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>
                                                            $ <input value={custom} type="number" onChange={(e) => handlecustom(e.target.value)} style={{ width: "50px", height: "30px", paddingLeft: "5px", borderRadius: "6px" }}></input>
                                                        </td>
                                                    </tr>
                                                )}

                                                {/* dangerous item fee */}
                                                {dangerous !== 0 && brands?.invoiceType !== "Monthly" && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500", textAlign: "right" }}>Dangerous Item Fee</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>
                                                            $ <input value={dangerous} type="number" onChange={(e) => handledangerous(e.target.value)} style={{ width: "50px", height: "30px", paddingLeft: "5px", borderRadius: "6px" }}></input>
                                                        </td>
                                                    </tr>
                                                )}

                                                {/* loyality discount */}
                                                {handleLoyalityDiscount()}

                                                {/* courier monthly */}
                                                {brands?.invoiceType === "Monthly" && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Monthly Fee</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        {show && (
                                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>
                                                                $ <input value={monthly} type="number" onChange={(e) => handleMonthly(e.target.value)} style={{ width: "50px", height: "30px", paddingLeft: "5px", borderRadius: "6px" }}></input>
                                                            </td>
                                                        )}
                                                        {!show && <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${monthly}</td>}
                                                    </tr>
                                                )}

                                                {/* courier website usage */}
                                                {!show && brands?.invoiceType === "Monthly" && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Website usage Fee</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${website}</td>
                                                    </tr>
                                                )}
                                                {show && brands?.invoiceType === "Monthly" && (
                                                    <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Website usage Fee</td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                        <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>
                                                            $ <input value={website} type="number" onChange={(e) => handleWebsite(e.target.value)} style={{ width: "50px", height: "30px", paddingLeft: "5px", borderRadius: "6px" }}></input>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        </>
                                        {!show && brands?.invoiceType !== "Monthly" && price === 0 && oversize == 0 && packaging == 0 && pickup === 0 && delivery === 0 && declaration === 0 && custom === 0 && loyalty === 0 && dangerous === 0 ? null : (
                                            <tr style={{ borderBottom: "4px solid black" }}>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "600" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                            </tr>
                                        )}

                                        {/* subTotal */}
                                        <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Sub-Total</td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${brands?.customerType === "partner" || brands?.customerType === "admin" ? SubTotal : total}</td>
                                        </tr>
                                        {brands?.customerType === "partner" ||
                                            (brands?.customerType === "admin" && (
                                                <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>{brands?.customerType === "partner" ? "Credit Discount" : "Admin Discount"}</td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>- ${(brands?.discountedAmount).toFixed(2)}</td>
                                                </tr>
                                            ))}

                                        {/* Tax */}
                                        <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Tax</td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                            <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>$0</td>
                                        </tr>

                                        {/* Total */}
                                        {brands?.paymentStatus === "pending" ? (
                                            <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Total</td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${total}</td>
                                            </tr>
                                        ) : null}
                                        {brands?.paymentStatus === "partially paid" ? (
                                            <>
                                                <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Paid Amount</td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${(brands?.paidAmount).toFixed(2)}</td>
                                                </tr>

                                                <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Remaining Amount</td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                    <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${(brands?.invoiceAmount).toFixed(2)}</td>
                                                </tr>
                                            </>
                                        ) : null}
                                        {brands?.paymentStatus === "paid" ? (
                                            <tr style={{ borderBottom: "4px solid rgb(196, 194, 194)" }}>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>Total</td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}></td>
                                                <td style={{ padding: "15px 0px", fontSize: `${window?.innerWidth < 768 ? "11px" : "16px"}`, color: "black", fontWeight: "500" }}>${(brands?.paidAmount).toFixed(2)}</td>
                                            </tr>
                                        ) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div>
                            <p style={{ fontSize: "18px", marginTop: "50px", fontFamily: "Arial", color: "black", fontWeight: "bold" }}>Customer Note</p>
                            <p style={{ color: "black", marginTop: "-10px" }}>THANK YOU for choosing ZAZIO as your shipping partner! Please see your order updates below.</p>
                            <ul>
                                <li style={{ fontSize: "16px", color: "black", lineHeight: "24px" }}>Status: Preparing Package for Shipment</li>
                                <li style={{ fontSize: "16px", color: "black", lineHeight: "24px" }}>Shipment: {brands?.shipmentNumber}</li>
                                <li style={{ fontSize: "16px", color: "black", lineHeight: "24px" }}>Package: {brands?.Waybills}</li>
                                {!show && notes && <li style={{ fontSize: "16px", color: "black", lineHeight: "24px" }}>Notes: {notes}</li>}
                                {show && (
                                    <div style={{ display: "flex", columnGap: "10px", marginTop: "10px" }}>
                                        <p>Notes:</p>
                                        <textarea value={notes} onChange={(e) => setNotes(e.target.value)} style={{ width: "70%", height: "100px", borderRadius: "6px", border: "1px solid rgb(196, 194, 194)", padding: "10px", fontSize: "16px", color: "black", fontWeight: "500" }}></textarea>
                                    </div>
                                )}
                            </ul>
                            <p style={{ color: "black" }}> PLEASE USE 00000 for the ZIP Code when using a Jamaican Debit/Credit Card) to make payment.</p>
                            {/* <a href="https://portal.zazio.co">Your Payment Link: https://portal.zazio.co</a> */}
                            <img
                                src="https://res.cloudinary.com/dwxqg9so3/image/upload/v1696375833/WhatsApp_Image_2023-09-25_at_03.12.56_463150e6_ua2zka.jpg"
                                style={{
                                    width: "200px",
                                    height: "auto",
                                    objectFit: "contain",
                                    cursor: "pointer",
                                }}
                                onClick={() => handlePayment()}
                            ></img>
                            <p style={{ color: "black", marginTop: "50px", fontSize: "12px" }}>
                                <span style={{ fontWeight: "bold" }}>ZAZIO INC</span> is a registered corporation in Florida, United States
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Invoice;
