import React, { useEffect, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { useHistory, useParams } from "react-router-dom";
import { handleGetRequest } from "../../services/GetTemplate";
import { handlePutRequest } from "../../services/PutTemplate";
import { toast } from "react-toastify";


function DeliveryInfo() {
    const breadItems = [{ label: "Home" }, { label: "customer" }];
    const home = { icon: "pi pi-home", url: "/" };
    const [brandData, setBrandData] = useState();
    const history = useHistory();
    const { id } = useParams();

    const getDetails = async () => {
        const res = await handleGetRequest(`/specificDeliveryInfo/${id}`);
        const keyData = res?.data;
        setBrandData(keyData);
        Object.keys(keyData).forEach((key) => {
            if (formik.initialValues.hasOwnProperty(key)) {
                formik.setFieldValue(key, keyData[key]);
            }
        });
    };
    useEffect(() => {
        getDetails();
    }, [id]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
        },
        onSubmit: async (data) => {
            data._id = id;
            const result = await handlePutRequest(data, "/updateDeliveryInfo");
            if (result?.success) {
                getDetails();
                toast.success("Delivery Info Edited");
            }
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleCancel = () => {
        history.push("/DeliveryInfos");
    };

    return (
        <>
            <div className="customer_header__">
                <div className="left___">
                    <h2>{brandData?.title}</h2>
                    <BreadCrumb model={breadItems} home={home} />
                </div>
                
            </div>
            <div className="customer_details_section">
                <div className="right_section">
                    <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                        <div className="form__">
                            <div className="form_left">
                                <div style={{ marginTop: "10px" }}>
                                    <label htmlFor="name" className={classNames({ "p-error": isFormFieldValid("name") }, "Label__Text")}>
                                         Name
                                    </label>
                                    <InputText id="name" name="name" value={formik.values.name} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("name") }, "Input__Round")} />

                                    {getFormErrorMessage("name")}
                                </div>
                                
                            </div>
                           
                        </div>

                        <div className="Down__Btn">
                            <Button label="Cancel" className="Btn__Transparent" onClick={handleCancel} />
                            <Button label="Update" className="Btn__Dark" type="submit" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default DeliveryInfo;
