import React from "react";

function SoftStops({onHideSoftStop, onSuccessSoftStop, message}) {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <i className="pi pi-question" style={{ fontSize: "40px", color: "blue" }}></i>
            <p style={{ marginTop: "20px", fontSize: "20px", fontFamily: "Arial" }}>{message}</p>
            <div style={{ display: "flex", columnGap: "20px", marginTop: "20px" }}>
                <button style={{ padding: "10px 40px", border: "none", backgroundColor: "gray", color: "white", borderRadius: "20px", cursor: "pointer" }} onClick={onHideSoftStop}>
                    No, Cancel
                </button>
                <button style={{ padding: "10px 40px", border: "none", backgroundColor: "red", color: "white", borderRadius: "20px", cursor: "pointer" }} onClick={onSuccessSoftStop}>
                    Yes, I am sure
                </button>
            </div>
        </div>
    );
}

export default SoftStops;
