import React, { useEffect, useMemo, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useHistory } from "react-router-dom";
import { handlePutRequest } from "../../services/PutTemplate";
import { toast } from "react-toastify";


function Scan() {
    const [role, setrole] = useState("");
    const [userId, setuserId] = useState("");
    const breadItems = [{ label: "Home", url: "/" }, { label: "Scan" }];
    const home = { icon: "pi pi-home", url: "/" };
    const [data, setData] = useState("Not Found");
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const role = localStorage.getItem("role");
        const userId = localStorage.getItem("userId");
        setuserId(userId);
        setrole(role);
    }, []);

    const handleScan = async () => {
        if (role !== "airportStaff") {
            history.push("/package/" + data);
        } else if (role === "airportStaff") {
            console.log("yes");
            setLoading(true);
            const dat = {
                inventoryNumber: data,
                status: "out for delivery",
            };
            const result = await handlePutRequest(dat, "/editPackage");
            if (result?.success) {
                toast.success("Package status updated successfully");
                setLoading(false);
            }
            setLoading(false);
        }
    };
    console.log(data);

    useEffect(() => {
        if (data !== "Not Found") {
            handleScan();
        }
    }, [data]);
    return (
        <>
            <div className="Page__Header">
                <div>
                    <h2>Scan</h2>
                    <BreadCrumb model={breadItems} home={home} />
                </div>
            </div>
            <p style={{ marginTop: "20px" }}>Place the barcode in front of the camera to scan</p>
            {!loading && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <BarcodeScannerComponent
                        width={300}
                        height={500}
                        onUpdate={(err, result) => {
                            if (result) setData(result.text);
                            else setData("Not Found");
                        }}
                    />
                </div>
            )}
        </>
    );
}

export default Scan;
